
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVehiclesList } from "../../../state/redux/actions/index";
import { colourStyles, handlePostApi } from "../../../services/functions";
import { CustomViewModal, Input, DropDown, showNotification } from "../../Utils/Inputs";
import { Container, Row, Col } from "react-bootstrap";

const vehicleTypes = [
  { label: "CYCLE or TWO-WHEELER ", value: "CYCLE or TWO-WHEELER " },
  { label: "LPMV", value: "LPMV" },
  { label: "HPMV", value: "HPMV" },
];
const dummy = [
  { label: "Volume ", value: "Volume" },
  { label: "Quantity ", value: "Quantity" },
];

const defaultVehiclePayload = {
  vehicleNo: "",
  vehicleType: "select",
  capacityInKg: "",
  capacityInNumber: "",
  length: "",
  width: "",
  height: "",
  params: "Quantity",
  freightRate: "",
  quantity: "",
};

const defaultVehicleError = {
  vehicleNo: "",
  vehicleType: "",
  capacityInKg: "",
  capacityInNumber: "",
  length: "",
  width: "",
  height: "",
  freightRate: "",
  params: "",
};

const AddVehicle = ({ show, onClose, sharedData }) => {
  const dispatch = useDispatch();
  const listing = useSelector((state) => state.getVehicleRed.vehicleListing);

  const [vehiclePayload, setVehiclePayload] = useState({
    vehicleNo: "",
    vehicleType: "select",
    capacityInKg: "",
    capacityInNumber: "",
    length: "",
    width: "",
    height: "",
    freightRate: "",
    params: "Quantity",
  });

  const [error, setError] = useState({
    vehicleNo: "",
    vehicleType: "",
    capacityInKg: "",
    capacityInNumber: "",
    length: "",
    width: "",
    height: "",
    freightRate: "",
    params: "",
  });

  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    setVehiclePayload((prev) => {
      const updatedPayload = { ...prev, [e.target.name]: e.target.value };

      // Check if params is "Volume" and calculate capacity
      if (updatedPayload.params === "Volume") {
        const length = parseFloat(updatedPayload.length) || 0;
        const width = parseFloat(updatedPayload.width) || 0;
        const height = parseFloat(updatedPayload.height) || 0;
        const capacityInKg = Math.ceil(length * width * height);

        // Return the updated payload with capacityInKg
        return { ...updatedPayload, capacityInKg };
      }

      // Return the updated payload without changes to capacityInKg
      return updatedPayload;
    });
  };

  const handleDropChange = (value, name) => {
    setVehiclePayload((prev) => ({ ...prev, [name]: value.value }));
  };

  useEffect(() => {
      if(sharedData.id){
          let temp = {};
          let selectedVehicle = listing && listing?.length > 0 && listing.find((item) => item._id === sharedData.id);
          temp.vehicleNo = selectedVehicle.vehicleNo || ""
          temp.vehicleType = selectedVehicle.vehicleType || ""
          temp.capacityInKg = selectedVehicle.capacityInKg || ""
          temp.capacityInNumber = selectedVehicle.capacityInNumber || ""
          temp.params = selectedVehicle && selectedVehicle.capacityInNumber !== null ? "Quantity" : "Volume"
          temp.length = selectedVehicle.length || ""
          temp.width = selectedVehicle.width || ""
          temp.height = selectedVehicle.height || ""
          temp.freightRate = selectedVehicle.freightRate || ""
          setVehiclePayload(temp)
      }else{
        setError(defaultVehicleError)
        setVehiclePayload(defaultVehiclePayload);
      }
  }, [sharedData, listing])

  const validateVehiclePayload = (payload) => {
    const errors = {};

    // Validate vehicle number
    if (!payload.vehicleNo) {
      errors.vehicleNo = "Vehicle number is required.";
    } else if (!/^[A-Z]{2}[A-Z0-9]{1,2}[A-Z0-9]{0,2}\d{4}$/.test(payload.vehicleNo)) {
      errors.vehicleNo = "Vehicle number should be in the format: XX 00 XX 0000.";
    }

    // Validate vehicle type
    if (payload.vehicleType === "select") {
      errors.vehicleType = "Please select a vehicle type.";
    }

    if (payload.params === "Quantity") {
      // Validate capacity in number
      if (!payload.capacityInNumber || isNaN(payload.capacityInNumber)) {
        errors.capacityInNumber = "Capacity is required and must be a number.";
      }
    } else {
      // Validate dimensions
      if (!payload.length || isNaN(payload.length)) {
        errors.length = "Length is required and must be a number.";
      }
      if (!payload.width || isNaN(payload.width)) {
        errors.width = "Width is required and must be a number.";
      }
      if (!payload.height || isNaN(payload.height)) {
        errors.height = "Height is required and must be a number.";
      }

      // Validate freight rate
      if (!payload.freightRate || isNaN(payload.freightRate)) {
        errors.freightRate = "Freight rate is required";
      }
    }

    setError(errors);

    return errors;
  };

  const handleAddVehicle = async () => {
    // console.log("vehiclePayload", vehiclePayload);
    const errors = validateVehiclePayload(vehiclePayload);
    if (Object.keys(errors).length > 0) {
      // console.error("Validation errors:", errors);
      return;
    }
    try {
      const result = await handlePostApi("/vehicle/addVehicle", { ...vehiclePayload });
      // console.log("api result====>>>>", result);
      if (result.success) {
        showNotification({message: result.message, title: "Success!!", autohide: true, showInput: false}, (result) => {
          if (result.type === "confirm") {
            // console.log("confirm clicked=====>");
          }
        });
        dispatch(
          getVehiclesList({
            page: 1,
            limit: 10,
            searchType: "",
            searchValue: "",
          })
        );
        setError(defaultVehicleError)
        setVehiclePayload(defaultVehiclePayload)
        onClose();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpdateVehicle = async () => {
    const errors = validateVehiclePayload(vehiclePayload);
    if (Object.keys(errors).length > 0) {
      // console.error("Validation errors:", errors);
      return;
    }
    try {
      let payload = { updateDetails: vehiclePayload, _id: sharedData.id };
      const result = await handlePostApi("/vehicle/editVehicle", { ...payload });
      // console.log("api result====>>>>", result);
      if (result.success) {
        showNotification({message: result.message, title: "Success!!", autohide: true, showInput: false});
        dispatch(
          getVehiclesList({
            page: sharedData.pageCount,
            limit: sharedData.pageLimit,
            searchType: "",
            searchValue: "",
          })
        );
        setError(defaultVehicleError)
        setVehiclePayload(defaultVehiclePayload);
        onClose();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <CustomViewModal
      heading={sharedData.id ? "Edit Vehicle" : "Add Vehicle"}
      show={show}
      onClose={() => {
        setError(defaultVehicleError)
        setVehiclePayload(defaultVehiclePayload)
        onClose();
      }}
      size={"lg"}
    >
      <Container fluid className="">
        <Row className="addBg p-2">
          <Col className="">
            <Row className="px-2 d-flex justify-content-between align-items-center">
              <span className="custSpan">
                Vehicle Number<span className="asterick"> * </span> :{" "}
              </span>
              <Input readOnly={sharedData.id} error={error.vehicleNo} name="vehicleNo" value={vehiclePayload.vehicleNo} placeholder="Enter Vehicle Number" onChange={(e) => {
                const isValidInput = e.target.value.length < 11;
                if (isValidInput) {
                  handleChange({ ...e, target: { name : "vehicleNo", value: e.target.value } });
                }
              }} />
            </Row>
          </Col>
          <Col>
            <Row className="px-2 d-flex justify-content-around align-items-center">
              <span className="custSpan">
                Vehicle Type<span className="asterick"> * </span> :{" "}
              </span>
              <div style={{ width: "200px" }}>
                <DropDown
                  onChange={(value) => handleDropChange(value, "vehicleType")}
                  options={vehicleTypes}
                  styles={colourStyles}
                  error={error.vehicleType}
                  value={vehicleTypes.find((el) => el.value === vehiclePayload.vehicleType)}
                />
              </div>
            </Row>
          </Col>
        </Row>
        <Row className="addBg mt-2 p-2 d-flex justify-content-around align-items-center">
          <Col xs="5">
            <Row className="px-2  d-flex justify-content-between align-items-center">
              <span className="custSpan">
                Capacity<span className="asterick"> * </span> :{" "}
              </span>
              <div style={{ width: "200px" }}>
                <DropDown
                  onChange={(value) => handleDropChange(value, "params")}
                  options={dummy}
                  styles={colourStyles}
                  error={error.params}
                  value={dummy.find((el) => el.value === vehiclePayload.params)}
                />
              </div>
            </Row>
          </Col>
        </Row>
        {vehiclePayload.params === "Volume" && (
          <>
            <Row className="mt-2 addBg p-2">
              <Col className="">
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Length( cm )<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.length} name="length" value={vehiclePayload.length} placeholder="Enter Length" onChange={(e) => handleChange(e)} />
                </Row>
              </Col>
              <Col>
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Width( cm )<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.width} name="width" value={vehiclePayload.width} placeholder="Enter Width" onChange={(e) => handleChange(e)} />
                </Row>
              </Col>
            </Row>
            <Row className="mt-2 addBg p-2">
              <Col className="">
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Height( cm )<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.height} name="height" value={vehiclePayload.height} placeholder="Enter Height" onChange={(e) => handleChange(e)} />
                </Row>
              </Col>
              <Col>
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Freight Rate / KM<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.freightRate} name="freightRate" value={vehiclePayload.freightRate} placeholder="Enter Freight Rate" onChange={(e) => handleChange(e)} />
                </Row>
              </Col>
            </Row>
          </>
        )}
        {vehiclePayload.params === "Quantity" && <Row className="mt-2 addBg p-2">
          <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Quantity ( Number )<span className="asterick"> * </span> :{" "}
                </span>
                {vehiclePayload.params === "Volume" ? (
                  <Input name="capacityInKg" value={vehiclePayload.capacityInKg} placeholder="Enter Quantity(Kg)" onChange={(e) => handleChange(e)} />
                ) : (
                  <Input error={error.capacityInNumber} name="capacityInNumber" value={vehiclePayload.capacityInNumber} placeholder="Enter Quantity(Number)" onChange={(e) => handleChange(e)} />
                )}
              </Row>
          </Col>
          <Col></Col>
        </Row>}
        {vehiclePayload.params === "Volume" && (
          <Row className="addBg mt-2 p-2 d-flex justify-content-around align-items-center">
            <Col xs="3">
              <Row className="px-2 d-flex justify-content-around align-items-center">
                <span className="custSpan">
                  Volume ( cm <sup>3</sup> ) -
                </span>
                <span className="vehVol">
                  {vehiclePayload.capacityInKg ? vehiclePayload.capacityInKg : ""}
                  cm <sup>3</sup>
                </span>
              </Row>
            </Col>
          </Row>
        )}
        <Row className="mt-3 p-2 d-flex justify-content-around align-items-center">
          <button
            className="customBtn"
            onClick={() => {
              if(sharedData.id){
                handleUpdateVehicle()
              }else{
                handleAddVehicle();
              }
              
            }}
          >
            {sharedData.id ? "Edit Vehicle" : "Add Vehicle"}
          </button>
        </Row>
      </Container>
    </CustomViewModal>
  );
};

export default AddVehicle;
