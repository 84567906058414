import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getAddress } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import { colourStyles, handlePostApi, valid_email, valid_mobile } from '../../services/functions';
import { CustomViewModal, Input, DropDown, showNotification } from "../../views/Utils/Inputs";
import { Container, Row, Col, Button } from "react-bootstrap";
import { MarkerPreview } from '../Utils/MapPreview';

const defaultAddressPayload = {
  company: "",
  address: "",
  latitude: "",
  longitude: "",
  personName: "",
  mobileNo: "",
  email: "",
};

const defaultAddressError = {
  company: "",
  address: "",
  latitude: "",
  longitude: "",
  personName: "",
  mobileNo: "",
  email: "",
};


const AddAddress = ({ show, onClose, sharedData }) => {
    const markerRef = useRef();
    const dispatch = useDispatch();
    const listing = useSelector((state) => state.addressListRed.addressListing);
    const [addressPayload, setAddressPayload] = useState(defaultAddressPayload);
    const [error, setError] = useState(defaultAddressError);
    const [showMap, setShowMap] = useState(false);
    const [newLocation, setNewLocation] = useState({});

    const _handleChange = (e) => {
        // console.log(e.target.name, e.target.value);
        setAddressPayload((prev) => {
          const updatedPayload = { ...prev, [e.target.name]: e.target.value };
          return updatedPayload;
        });
      };
    
    const locateOnMap = () => {
        setShowMap(true)
    }
    const updateAddress = () => {
        setAddressPayload({ ...addressPayload, ...newLocation });
        setShowMap(false)
    }

    const handleChange = (item) => {
        // console.log(item)
        setAddressPayload({ ...addressPayload, address: item });
    };

    const handleSelect = (item) => {
        // console.log(item);
        setAddressPayload({ ...addressPayload, address: item });
        geocodeByAddress(item)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                // console.log(latLng);
                setAddressPayload({ ...addressPayload, address: item, latitude: latLng.lat, longitude: latLng.lng });
                setNewLocation({ address: item, latitude: latLng.lat, longitude: latLng.lng });
            })
            .catch(error => console.error('Error', error));
    };

    useEffect(() => {
      if (sharedData.id) {
        let temp = {};
        let selectedAddress = listing && listing?.length > 0 && listing.find((item) => item._id === sharedData.id);
        // console.log("selectedAddress", selectedAddress);
        if (selectedAddress) {
          temp = {
            company: selectedAddress.company || "",
            address: selectedAddress.address || "",
            latitude: selectedAddress.latitude || "",
            longitude: selectedAddress.longitude || "",
            personName: selectedAddress?.contactDetails?.personName || "",
            mobileNo: selectedAddress?.contactDetails?.mobileNo || "", 
            email: selectedAddress?.contactDetails?.email || "",
          };
          setAddressPayload(temp);
          setNewLocation({
            latitude: selectedAddress.latitude,
            longitude: selectedAddress.longitude,
          });
        } else {
          setAddressPayload(defaultAddressPayload);
        }
      } else {
        setError(defaultAddressError);
        setAddressPayload(defaultAddressPayload);
      }
    }, [sharedData, listing]);

    const validateAddressPayload = (payload) => {
      const errors = {};
      // Validate company
      if (!payload.company) {
        errors.company = "Company is required.";
      }

      // Validate address
      if (!payload.address) {
        errors.address = "Address is required.";
      }

      // Validate latitude
      if (payload.latitude === "") {
        errors.latitude = "Latitude is required.";
      } else if (isNaN(payload.latitude) || parseFloat(payload.latitude) < -90 || parseFloat(payload.latitude) > 90) {
        errors.latitude = "Latitude must be a number between -90 and 90.";
      }

      // Validate longitude
      if (payload.longitude === "") {
        errors.longitude = "Longitude is required.";
      } else if (isNaN(payload.longitude) || parseFloat(payload.longitude) < -180 || parseFloat(payload.longitude) > 180) {
        errors.longitude = "Longitude must be a number between -180 and 180.";
      }

      // Validate personName
      if (!payload.personName) {
        errors.personName = "Person name is required.";
      }

      // Validate mobileNo using valid_mobile function
      if (!payload.mobileNo) {
        errors.mobileNo = "Mobile number is required.";
      } else if (!valid_mobile(payload.mobileNo)) {
        errors.mobileNo = "Mobile number must be a valid 10-digit number starting with 6, 7, 8, or 9.";
      }

      // Validate email using valid_email function
      if (!payload.email) {
        errors.email = "Email is required.";
      } else if (!valid_email(payload.email)) {
        errors.email = "Email is not valid.";
      }

      setError(errors);

      return errors;
    };
    
      const handleAddAddress = async () => {
        // console.log("vehiclePayload", vehiclePayload);
        const errors = validateAddressPayload(addressPayload);
        if (Object.keys(errors).length > 0) {
          // console.error("Validation errors:", errors);
          return;
        }
        try {
          const payload = {
            company: addressPayload.company,
            address: addressPayload.address,
            latitude: addressPayload.latitude,
            longitude: addressPayload.longitude,
            contactDetails: {
              personName: addressPayload.personName,
              mobileNo: addressPayload.mobileNo,
              email: addressPayload.email,
            },
          };
          const result = await handlePostApi("/route/addAddress", { ...payload });
          // console.log("api result====>>>>", result);
          if (result.success) {
            showNotification({ message: result.message, title: "Success!!", autohide: true, showInput: false }, (result) => {
              if (result.type === "confirm") {
                // console.log("confirm clicked=====>");
              }
            });
            dispatch(
              getAddress({
                page: 1,
                limit: 10,
                searchType: "",
                searchValue: "",
              })
            );
            setError(defaultAddressError);
            setAddressPayload(defaultAddressPayload);
            onClose();
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
      const handleUpdateAddress = async () => {
        const errors = validateAddressPayload(addressPayload);
        if (Object.keys(errors).length > 0) {
          // console.error("Validation errors:", errors);
          return;
        }
        try {
          let payload = {
            updateDetails: {
              latitude: addressPayload.latitude,
              longitude: addressPayload.longitude,
              address: addressPayload.address,
              company: addressPayload.company,
              contactDetails: {
                personName: addressPayload.personName,
                mobileNo: addressPayload.mobileNo,
                email: addressPayload.email,
              },
            },
            _id: sharedData.id,
          };
          const result = await handlePostApi("/route/editAddress", { ...payload });
          // console.log("api result====>>>>", result);
          if (result.success) {
            showNotification({message: result.message, title: "Success!!", autohide: true, showInput: false});
            dispatch(
              getAddress({
                page: sharedData.pageCount,
                limit: sharedData.pageLimit,
                searchType: "",
                searchValue: "",
              })
            );
            setError(defaultAddressError)
            setAddressPayload(defaultAddressPayload)
            onClose();
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
    return (
      <CustomViewModal
        heading={sharedData.id ? "Edit Address" : "Add Address"}
        show={show}
        onClose={() => {
          setError(defaultAddressError);
          setAddressPayload(defaultAddressPayload);
          onClose();
        }}
        size={"lg"}
      >
        {showMap ? (
          <Container fluid className="">
            <Row className="mapOuter">
              <MarkerPreview defaultCenter={[newLocation.longitude, newLocation.latitude]} isDragable={true} newMarkerDetail={(location) => setNewLocation({ latitude: location.latitude, longitude: location.longitude, address: location.address || addressPayload.address })}>
                <div className='addresss-map-container'>
                  <button className="addLocation" onClick={updateAddress}>
                    Update Address
                  </button>
                  <button className="addLocation-back" onClick={() => setShowMap(false)}>
                    Back
                  </button>
                </div>
              </MarkerPreview>
            </Row>
          </Container>
        ) : (
          <Container fluid className="">
            <Row className="addBg p-2">
              <Col className="">
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Company<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.company} name="company" value={addressPayload.company} placeholder="Enter company" onChange={(e) => _handleChange(e)} />
                </Row>
              </Col>
              <Col className="">
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Contact Name<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.personName} name="personName" value={addressPayload.personName} placeholder="Enter Contact Name" onChange={(e) => _handleChange(e)} />
                </Row>
              </Col>
            </Row>
            <Row className="addBg p-2 mt-2">
              <Col className="">
                <Row className="px-2 d-flex justify-content-between align-items-center" style={{ flexWrap: "nowrap" }}>
                  <span className="custSpan" style={{ width: "240px" }}>
                    Address<span className="asterick"> * </span> :{" "}
                  </span>
                  <div className="latlngInpInn" style={{ flexWrap: "unset", justifyContent: "normal", border: `${error.address ? '2px solid  red' : '1px solid rgb(0 0 0 / 21%)'}` }}>
                    <div className="latlngFieldNew" style={{ width: "-webkit-fill-available" }}>
                      <PlacesAutocomplete name="address" value={addressPayload.address} onChange={(e) => handleChange(e)} onSelect={(e) => handleSelect(e)}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div className="placeHolderWidth">
                            <input
                              id="widthh"
                              value={addressPayload.address}
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "location-search-input",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                const style = suggestion.active
                                  ? { backgroundColor: "#007BC9", cursor: "pointer", color: "#fff", fontSize: "0.8rem", width: "100%", borderBottom: "0px", borderRadius: "0px", padding: "8px" }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                      fontSize: "0.8rem",
                                      border: `1px solid rgb(0 0 0 / 21%)`,
                                      width: "100%",
                                      padding: "8px",
                                      borderRadius: "0px",
                                      //   borderBottom: "0px",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div>
                    <button className="addreddBtn1" disabled={addressPayload.address === "" ? true : false} onClick={() => locateOnMap()}>
                      Locate On Map
                    </button>
                  </div>
                </Row>
                <Row>
                <span className="custSpan" style={{ width: "200px" }}></span><span style={{fontSize : "12px", color : 'red'}}>{error.address || ""}</span>
                </Row>
              </Col>
            </Row>
            <Row className="addBg p-2 mt-2">
              <Col className="">
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Latitude<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.latitude} name="latitude" value={addressPayload.latitude} placeholder="Enter latitude" onChange={(e) => _handleChange(e)} />
                </Row>
              </Col>
              <Col className="">
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Longitude<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.longitude} name="longitude" value={addressPayload.longitude} placeholder="Enter longitude" onChange={(e) => _handleChange(e)} />
                </Row>
              </Col>
            </Row>
            <Row className="addBg p-2 mt-2">
              <Col className="">
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Mobile No.<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.mobileNo} name="mobileNo" value={addressPayload.mobileNo} placeholder="Enter mobile No." onChange={(e) => _handleChange(e)} />
                </Row>
              </Col>
              <Col className="">
                <Row className="px-2 d-flex justify-content-between align-items-center">
                  <span className="custSpan">
                    Email Id<span className="asterick"> * </span> :{" "}
                  </span>
                  <Input error={error.email} name="email" value={addressPayload.email} placeholder="Enter email" onChange={(e) => _handleChange(e)} />
                </Row>
              </Col>
            </Row>
            <Row className="mt-3 p-2 d-flex justify-content-around align-items-center">
              <button
                className="customBtn"
                onClick={() => {
                  if (sharedData.id) {
                    handleUpdateAddress();
                  } else {
                    handleAddAddress();
                  }
                }}
              >
                {sharedData.id ? "Edit Address" : "Add Address"}
              </button>
            </Row>
          </Container>
        )}
      </CustomViewModal>
    );
}

export default AddAddress;