import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from "jwt-decode";
import user from './../../../static/images/dummy-user.png'
import ProfileLocation from './ProfileLocation'
import { getUsers } from '../../../state/redux/actions/index';
import CustomPageLayout from '../../Utils/CustomPageLayout/CustomPageLayout';
import { Input } from '../../Utils/Inputs';
import { Container, Row, Col } from "react-bootstrap";
import './Profile.css';
import AddProfile from './AddProfile';

const Profile = () => {
    const dispatch = useDispatch()
    const [geo, setGeo] = useState({ lat: '', lng: '' });
    const listing = useSelector((state) => state.getUserRed.usersList);
    const [profileDetail, setProfileDetail] = useState({})
    const [showLocation, setShowLocation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const _handleClick = () => {
        setShowLocation(true);
        setGeo({ lat: profileDetail?.latitude, lng: profileDetail?.longitude });
    }

    const handleChange = (item) => {
            console.log(item)
            setProfileDetail({ ...profileDetail, address: item  });
            // setAddressPayload({ ...addressPayload, address: item, latitude: profileDetail?.latitude, longitude: profileDetail?.longitude });
        };

    useEffect(() => {
        var token = localStorage.getItem('secretkey')
        var decoded = jwt_decode(token);
        var userType = decoded && decoded.token ? decoded.token.userType : ''
        var searcValue = userType === 'distributor' ? decoded.token.code : ''
        // console.log("decoded==>", decoded.token);
        if (userType === 'distributor') {
            dispatch(getUsers({
                searchType: "code",
                searchValue: searcValue,
                userType: 'distributor',
                type: 'distributor',
            }))
        }
        else {
            setProfileDetail(decoded.token)
        }
    }, [])

    useEffect(() => {
        if (listing && listing?.length > 0) {
          console.log("listing==>", listing[0]);
            setProfileDetail(listing[0])
        }
    }, [listing])

    return (
      <CustomPageLayout title="Profile Details">
        <Container fluid className="">
          <Row className="p-4 addBg border">
            <Col xs="auto" className="">
              <div className="profile-container">
                <img className="profile-dummy-image" src={user} alt="sd" />
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-start">
              <Row className="">
                <div className="profile-title">
                  <h1>{profileDetail?.name ? profileDetail.name : "-"}</h1>
                  <span>{profileDetail?.email ? profileDetail.email : "-"}</span>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2 p-4 addBg border">
            <Container fluid>
              <Row>
                <span>
                  <span style={{ fontWeight: "800", fontSize: "25px", marginRight: "10px" }}>User Details</span>
                  {localStorage.getItem("userType") === "distributor" && <i
                    className="fa fa-pencil-square-o editIcon"
                    onClick={() => {
                      setIsEdit(!isEdit);
                    }}
                    style={{ marginRight: "10px" }}
                  ></i>}
                </span>
              </Row>
              <Row>
                <Col xs="auto">
                  <Row className="p-2  d-flex justify-content-between align-items-center">
                    <span style={{ width: "200px" }} className="ltspan">
                      Type :{" "}
                    </span>
                    <div style={{ marginLeft: "10px", marginTop: "2px", color: "#007BC9" }}>{profileDetail?.userType ? profileDetail.userType.toUpperCase() : "-"}</div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <Row className="p-2  d-flex justify-content-between align-items-center">
                    <span style={{ width: "200px" }} className="ltspan">
                      Email :{" "}
                    </span>
                    <div style={{ marginLeft: "10px", marginTop: "2px", color: "#007BC9" }}>{profileDetail?.email ? profileDetail?.email : "-"}</div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <Row className="p-2  d-flex justify-content-between align-items-center">
                    <span style={{ width: "200px" }} className="ltspan">
                      Mobile Number :{" "}
                    </span>
                    <div style={{ marginLeft: "10px", marginTop: "2px", color: "#007BC9" }}>{profileDetail?.mobile ? profileDetail.mobile : "-"}</div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <Row className="p-2  d-flex justify-content-between align-items-center">
                    <span style={{ width: "200px" }} className="ltspan">
                      State :{" "}
                    </span>
                    <div style={{ marginLeft: "10px", marginTop: "2px", color: "#007BC9" }}>{profileDetail?.state ? profileDetail.state : "-"}</div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <Row className="p-2  d-flex justify-content-between align-items-center">
                    <span style={{ width: "200px" }} className="ltspan">
                      City :{" "}
                    </span>
                    <div style={{ marginLeft: "10px", marginTop: "2px", color: "#007BC9" }}>{profileDetail?.city ? profileDetail.city : "-"}</div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <Row className="p-2  d-flex justify-content-between align-items-center">
                    <span style={{ width: "200px" }} className="ltspan">
                      Address :{" "}
                    </span>
                    <div style={{ marginLeft: "10px", marginTop: "2px", color: "#007BC9" }}>{profileDetail?.address ? profileDetail?.address : "-"}</div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <Row className="p-2  d-flex justify-content-between align-items-center">
                    <span style={{ width: "200px" }} className="ltspan">
                      Location :{" "}
                    </span>
                    <div style={{ marginLeft: "10px", marginTop: "2px", color: "#007BC9" }}>
                      {`${profileDetail?.latitude ? profileDetail.latitude.toFixed(8) : "-"} , ${profileDetail?.longitude ? profileDetail.longitude.toFixed(8) : "-"}`}{" "}
                      <i
                        onClick={() => {
                          _handleClick("ProfileLocation");
                        }}
                        className="fa fa-map-marker lMarker"
                      ></i>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        <ProfileLocation
          show={showLocation}
          onClose={() => {
            setShowLocation(false);
            setGeo({ lat: "", lng: "" });
          }}
          sharedData={{ geo: geo }}
        />
        <AddProfile
          show={isEdit}
          onClose={() => {
            setIsEdit(false);
            // setSelectedAddress(null);
          }}
          sharedData={profileDetail}
        />
      </CustomPageLayout>
    );
};

export default Profile;