import React, { useEffect, useState } from "react";
import CustomPageLayout from "../Utils/CustomPageLayout/CustomPageLayout";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { RoutePreview , MultiRoutePreview } from "../Utils/MapPreview";
import { locationAreEqual, calculateETA, calculateETAMin } from "../../services/functions";
import { handlePostApi } from "../../services/functions";
import { showNotification } from "../Utils/Inputs";
import { generateUniqueHexColors } from "../../services/functions";
import history from '../../services/history';
import moment from "moment";
import './TripManagment.css';
import { Checkbox } from "../Utils/Inputs";

export default function TripMapPreview() {
    const location = useLocation();
    const { state } = location;
    const {isSaveTrip, clusterArr, previewPayload} = state;
    const [tripDetails, setTripDetails] = useState([]);
    const [colorArray, setColorArray] = useState(['#007bc9']);
    const [showListView, setShowListView] = useState(true);
    const [collapsedVehicles, setCollapsedVehicles] = useState({});
    const [selectedTrip, setSelectedTrip] = useState([]);
    const [selectedColor, setSelectedColor] = useState([]);

    // Toggle collapse state for a specific vehicle
    const toggleCollapse = (vehicleNo) => {
      setCollapsedVehicles((prevState) => ({
        ...prevState,
        [vehicleNo]: !prevState[vehicleNo], // Toggle the collapse state
      }));
    };

    useEffect(() => {
      if (clusterArr.success) {
        setTripDetails(clusterArr.data);
        setColorArray(generateUniqueHexColors(clusterArr.data.length))
    } else {
        setTripDetails({});
        history.goBack();
    }
      
    }, [clusterArr]);

    const handleSubmitTrip = async () => {
      try {
        const result = await handlePostApi("/trip/saveTrip", { data: tripDetails });
        if (result.success) {
          showNotification({message: result.message, title: "Success!!", autohide: true, showInput: false});
          history.push('/dashboard')
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    const handleSelectedTrip = (e, trip, color) => {
      const isChecked = e.target.checked;
  
      if (isChecked) {
        // Add trip and color to the arrays
        setSelectedTrip((prevTrips) => [...prevTrips, trip]);
        setSelectedColor((prevColors) => [...prevColors, color]);
      } else {
        // Remove trip and color from the arrays
        setSelectedTrip((prevTrips) => prevTrips.filter(t => t !== trip));
        setSelectedColor((prevColors) => prevColors.filter(c => c !== color));
      }
    };
    
  return (
    <CustomPageLayout
      title="Trip Preview"
      addTitleButton={
        <span className="d-flex align-items-center">
          <i className="fa fa-arrow-left mt-1"></i>&nbsp; Back
        </span>
      }
      buttonChildern={
        <button className="myMapBtnGrey" onClick={() => setShowListView(!showListView)}>
          {"List View"}
        </button>
      }
      addHandleClick={() => history.goBack()}
    >
      <div>
        <div style={{ width: "100%", height: "calc(100vh - 230px)" }}>
          <MultiRoutePreview routeCoordinates={selectedTrip.length > 0 ? selectedTrip : tripDetails} setColorArray={selectedColor.length > 0 ? selectedColor : colorArray}>
            {isSaveTrip && (
              <button className="SaveTrip-btn" onClick={() => handleSubmitTrip()}>
                Save Trip
              </button>
            )}
            {showListView && (
              <div className="ListView-container">
                <div className="ListView-header">
                  <span className="ListView-header-title">Estimated Time Of Arrival (ETA)</span>
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowListView(!showListView)}></button>
                </div>
                <div className="ListView-body">
                  {tripDetails?.length > 0 &&
                    tripDetails.map((el, i) => (
                      <div key={el?._id || i} className={`vehicle-container ${!collapsedVehicles[el?._id || i] ? "vehicle-container-height" : ""}`}>
                        <div style={{ backgroundColor: `${colorArray[i]}` }} className="vehicle-header">
                          <Checkbox checked={selectedTrip.length > 0 ? selectedTrip.some(e => e._id === el._id)  : false} sx={{ marginTop: "9px", position: "absolute", left: "5px" }} onChange={(e) => handleSelectedTrip(e, el, colorArray[i])} />
                          <span>
                            {el.tripId ? el.tripId : el.vehicle.vehicleNo}&nbsp;({calculateETAMin(el.eta || 0)})
                          </span>
                          <button className="collasing-btn" onClick={() => toggleCollapse(el?._id || i)}>
                            {collapsedVehicles[el?._id || i] ? "-" : "+"}
                          </button>
                        </div>
                        {collapsedVehicles[el?._id || i] && (
                          <div className="vehicle-detail">
                            {el.locations.map((ele, index) => (
                              <div key={index} className="route-address-container">
                                {index !== 0 && (
                                  <div className="arrow-container">
                                    {!ele.isDelivered && <span className="eta-status-left">ETA - {ele?.locationDetails?.eta ? calculateETAMin(ele.locationDetails?.eta) : "0 Minutes"}</span>}
                                    <i className="fa fa-arrow-down downArrow"></i>
                                    <span className="eta-status-right"></span>
                                  </div>
                                )}
                                {/* {index !== 0 && (
                                  <div className="arrow-container">
                                    <i className="fa fa-arrow-down downArrow"></i>
                                    {!ele.isDelivered && <span className="eta-status">ETA - {ele?.locationDetails?.eta ? calculateETAMin(ele.locationDetails?.eta) : "0 Minutes"}</span>}
                                  </div>
                                )} */}
                                
                                <div className={`addressCard ${ele.isDelivered ? "delivered" : (ele.isRescheduleForOtherDay === true || ele.isRescheduleForToday === true) ? 'skippedTrip' :  el.currentWaypoint == ele.waypoint_index ? "card-active" : ""}`}>
                                  {ele?.locationDetails?.startSlotTime && ele?.locationDetails?.endSlotTime && (
                                    <div className="slot-status">
                                      <span>
                                        Preferred Slot -{ele?.locationDetails?.startSlotTime + ":00"}-{ele?.locationDetails?.endSlotTime + ":00"}
                                      </span>
                                    </div>
                                  )}
                                  {index !== 0 &&
                                    (ele.isDelivered ? (
                                      <div className="delivered-status">
                                        <span>Delivered At -{moment(ele.deliveryTime).format("DD MMM YYYY hh:mm A")}</span>
                                      </div>
                                    ) : el.locations?.length - 1 === index ? (
                                      <span className="pit-delivered-right">Pit Stop Time - {el?.pitStopTime ? calculateETA(el.pitStopTime) : "0 Minutes"}</span>
                                    ) : (
                                      <span className="pit-delivered-right">Service Time - {ele?.locationDetails?.serviceTime ? calculateETA(ele.locationDetails.serviceTime) : "0 Minutes"}</span>
                                    ))}
                                  <div className="location-mark">{ele.locationDetails.locationType === "source" || locationAreEqual(el.locations[0].location, ele.location) ? "S." : `${index}.`}</div>
                                  <div className="address">
                                    <span style={{ textAlign: "center" }}>{ele?.locationDetails?.address || "-"}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <div className="ListView-footer"></div>
              </div>
            )}
          </MultiRoutePreview>
        </div>
      </div>
    </CustomPageLayout>
  );
}
