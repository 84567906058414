import axios from 'axios'
import { logoutOnExpiry, url } from './requests';
import { showNotification } from '../views/Utils/Inputs';
import moment from 'moment'

export const selectInit = (callback, elem = "") => {
  window
    .jQuery((elem ? elem : ".select2-single") + ":not(.bound)")
    .addClass("bound")
    .select2({
      maximumSelectionSize: 6,
      containerCssClass: ":all:",
      minimumResultsForSearch: 10,
    })
    .on("change", (evt) => {
      callback((evt.target.id, evt.target.value));
    });
};

export const base64Convert = (file) => {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = (e) => {
      resolve(fr.result);
    };
    fr.onerror = (error) => {
      reject("Error: " + error);
    };
    fr.readAsDataURL(file);
  });
};

export const base64MimeType = (encoded) => {
  var result = null;
  if (typeof encoded !== "string") {
    return result;
  }
  try {
    var mime = encoded.split(";base64")[0].split(":")[1].split("/");
    if (mime && mime.length) {
      if (mime[0] === "image") {
        result = mime[0];
      } else {
        result = mime[1];
      }
    }
  } catch (e) {
    console.error(e.message);
  }
  return result;
};

export const get_pdf_thumbnail = (base64) => {
  return new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = function (ev) {
      window.pdfjsLib.getDocument(fileReader.result).promise.then(
        function (doc) {
          // var pages = []; while (pages.length < doc.numPages) pages.push(pages.length + 1);
          return doc
            .getPage(1)
            .then(makeThumb)
            .then(function (canvas) {
              resolve(canvas.toDataURL("image/jpeg"));
            })
            .catch((err) => {
              console.log("Error", err);
              resolve();
            });
          // return Promise.all(pages.map(function (num) {
          // }))
        },
        function (error) {
          console.log(error);
          resolve();
        }
      );
    };
    fileReader.readAsArrayBuffer(base64);
  });
};

export const make_donut_chart = (id, options) => {
  var canvas = document.getElementById(id);
  var ctx = canvas.getContext("2d");
  var total_value = 0;
  var color_index = 0;
  var colors = [];
  for (var categ of options.data) {
    colors.push(categ.color);
    total_value += categ.value;
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  if (total_value === 0) {
    drawPieSlice(ctx, canvas.width / 2, canvas.height / 2, Math.min(canvas.width / 2, canvas.height / 2), 0, 6.28319, "#eaeaea", "");
  } else {
    var start_angle = 0;
    for (categ of options.data) {
      var slice_angle = (2 * Math.PI * categ.value) / total_value;
      drawPieSlice(
        ctx,
        canvas.width / 2,
        canvas.height / 2,
        Math.min(canvas.width / 2, canvas.height / 2),
        start_angle,
        start_angle + slice_angle,
        colors[color_index % colors.length],
        categ["label"]
      );
      start_angle += slice_angle;
      color_index++;
    }
    //drawing a white circle over the chart
    //to create the doughnut chart
    if (options.doughnutHoleSize) {
      drawPieSlice(ctx, canvas.width / 2, canvas.height / 2, options.doughnutHoleSize * Math.min(canvas.width / 2, canvas.height / 2), 0, 2 * Math.PI, "#fff", "Total-" + total_value);
    }
    start_angle = 0;
    for (categ of options.data) {
      slice_angle = (2 * Math.PI * categ.value) / total_value;
      var pieRadius = Math.min(canvas.width / 2, canvas.height / 2);
      var labelX = canvas.width / 2 + (pieRadius / 2) * Math.cos(start_angle + slice_angle / 2);
      var labelY = canvas.height / 2 + (pieRadius / 2) * Math.sin(start_angle + slice_angle / 2);

      if (options.doughnutHoleSize) {
        var offset = (pieRadius * options.doughnutHoleSize) / 2;
        labelX = canvas.width / 2 + (offset + pieRadius / 2) * Math.cos(start_angle + slice_angle / 2);
        labelY = canvas.height / 2 + (offset + pieRadius / 2) * Math.sin(start_angle + slice_angle / 2);
      }

      // var labelText = Math.round(100 * categ.value / total_value);
      ctx.fillStyle = "white";
      ctx.font = "bold 12px Arial";
      if (categ.label && categ.label != "0") {
        ctx.fillText(categ.label, labelX, labelY);
      }
      start_angle += slice_angle;
    }
  }
};

export const make_pie_chart = (id, arr) => {
  var canvas = document.getElementById(id);
  var elemLeft = canvas.offsetLeft;
  var elemTop = canvas.offsetTop;
  var elements = [];
  var ctx = canvas.getContext("2d");
  var lastend = 0;
  var data = [];
  var myTotal = 0;
  var myColor = [];
  var labels = [];
  arr.map((el) => {
    data.push(el.data);
    myColor.push(el.color);
    labels.push(el.label);
  });

  for (var e = 0; e < data.length; e++) {
    myTotal += data[e];
  }

  // make the chart 10 px smaller to fit on canvas
  var off = 10;
  var w = (canvas.width - off) / 2;
  var h = (canvas.height - off) / 2;
  for (var i = 0; i < data.length; i++) {
    ctx.fillStyle = myColor[i];
    ctx.strokeStyle = "white";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(w, h);
    var len = (data[i] / myTotal) * 2 * Math.PI;
    var r = h - off / 2;
    ctx.arc(w, h, r, lastend, lastend + len, false);
    ctx.lineTo(w, h);
    ctx.fill();
    ctx.stroke();
    ctx.fillStyle = "white";
    ctx.font = "16px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    var mid = lastend + len / 2;
    ctx.fillText(labels[i], w + Math.cos(mid) * (r / 2), h - 10 + Math.sin(mid) * (r / 2));
    ctx.font = "12px Arial";
    let percent = Math.floor((data[i] / myTotal) * 100 + 0.5);
    ctx.fillText("(" + percent + "%)", w + Math.cos(mid) * (r / 2), h + 10 + Math.sin(mid) * (r / 2));
    elements.push({ start: lastend, end: Math.PI * 2 * (data[i] / myTotal), text: `${labels[i]}:${data[i]}`, w, h, r });
    lastend += Math.PI * 2 * (data[i] / myTotal);
  }
  // try{
  //   canvas.addEventListener('click', function(event) {
  //     console.log("hello")
  //     // var x = event.pageX - elemLeft,
  //     //     y = event.pageY - elemTop;
  //     // console.log(x, y);
  //     // console.log(elements)
  //     var mouse = oMousePos(canvas, event)
  //     try{
  //       elements.forEach(function(element) {
  //         // drawElement(element, ctx);
  //         let circle = new Path2D();
  //         circle.arc(element.w , element.h, element.r, element.start,element.end,false);
  //         let context = canvas.getContext("2d");
  //         if(context.isPointInPath(circle,mouse.x, mouse.y)){console.log(element.text)}else{console.log("not in path")}
  //       });

  //     }
  //     catch(e){
  //       console.log(e)
  //     }

  //   }, false);

  // }
  // catch(e){
  //   console.log(e)
  // }
};

export const formatLineChartData = (values, chartHeight) => {
  const widgetSize = chartHeight;
  const pointSize = 16;

  const base = (widgetSize - pointSize / 2) / values.length;

  let sortedValues = sortValues([...values]);

  const topMostPoint = sortedValues[0].value;
  let leftOffset = pointSize; //padding for left axis labels
  let nextPoint = 0;
  let rise = 0;
  let cssValues = [];

  for (var i = 0, len = values.length - 1; i < len; i++) {
    var currentValue = {
      left: 0,
      bottom: 0,
      hypotenuse: 0,
      angle: 0,
      value: 0,
    };

    currentValue.value = values[i].value;
    currentValue.name = values[i].name;
    currentValue.left = leftOffset;
    leftOffset += base;
    currentValue.bottom = (widgetSize - pointSize) * (currentValue.value / topMostPoint);
    nextPoint = (widgetSize - pointSize) * (values[i + 1].value / topMostPoint);

    rise = currentValue.bottom - nextPoint;
    currentValue.hypotenuse = Math.sqrt(base * base + rise * rise);
    currentValue.angle = radiansToDegrees(Math.asin(rise / currentValue.hypotenuse));

    cssValues.push(currentValue);
  }

  var lastPoint = {
    left: leftOffset,
    bottom: (widgetSize - pointSize) * (values[values.length - 1].value / topMostPoint),
    hypotenuse: 0,
    angle: 0,
    value: values[values.length - 1].value,
    name: values[values.length - 1].name,
  };

  cssValues.push(lastPoint);

  return cssValues;
};

const sortValues = (values) => values.sort((a, b) => b.value - a.value);

const radiansToDegrees = (rads) => rads * (180 / Math.PI);

// const sum = (total, value) => total + value.value

function drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle, color, label = "") {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(centerX, centerY);
  ctx.arc(centerX, centerY, radius, startAngle, endAngle);
  ctx.closePath();
  ctx.fill();
}

function makeThumb(page) {
  // console.log(page.view)
  // draw page to fit into 96x96 canvas
  var vp = page.getViewport({ scale: 1 });
  var canvas = document.createElement("canvas");
  canvas.width = 612;
  canvas.height = 792;
  var scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
  return page.render({ canvasContext: canvas.getContext("2d"), viewport: { scale: scale } }).promise.then(function () {
    return canvas;
  });
}
export const colourStyles = {
  menuList: (styles) => ({
    ...styles,
    background: "white",
    width: "100%",
  }),
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "white",
      boxShadow: state.isFocused ? "0 0 0 .2rem rgba(0, 123, 255, .25)" : state.isFocused ? "0 0 0 .2rem rgba(0, 123, 255, .25)" : "none",
      cursor: "pointer",
      ":focus": {
        ...styles[":focus"],
        //   borderColor: "var(--clr--accent)",
        // boxShadow: "0 0 3px var(--clr--accent)",
      },
      ":active": {
        ...styles[":active"],
        //   borderColor: "var(--clr--accent)",
        // boxShadow: "0 0 3px var(--clr--accent)",
      },

      ":hover": {
        ...styles[":hover"],
        // borderColor: "var(--clr--accent)",
        // boxShadow: "0 0 3px var(--clr--accent)",
      },
      // height: 35,
      minHeight: 38,
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "#007BC9" : isSelected ? "#fff" : undefined,
    color: isFocused ? "#fff" : "black",
    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled ? (isSelected ? "var(--clr--accent)" : undefined) : undefined,
    },
    cursor: isDisabled ? "not-allowed" : "default",
    ":hover": {
      ...styles[":hover"],
      color: !isDisabled ? "white" : "black",
      backgroundColor: !isDisabled ? "#007BC9" : "gray",
    },
    zIndex: 1,
  }),
  menu: (base) => ({
    ...base, // override border radius to match the box
    // borderRadius: 0,
    // kill the gap
    // marginTop: 0,
    fontSize: '12px',
    zIndex: 999,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // height: '35px',
    fontSize: '12px',
    margin: "-2px 0 0 0",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "35px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#727376', // Set the value color to red
    fontWeight: 700,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#727376', // Set the placeholder color to red
    fontWeight: 700,
  }),
};

export const handlePostApi = async (requestUrl, body = {}) => {
  try {
    const token = localStorage.getItem("secretkey");
    const config = {
      headers: { Authorization: token },
    };
    const response = await axios.post(`${url}${requestUrl}`, { ...body }, config);

    const status = response.data.success;
    const message = response.data.message;

    if (status === true) {
      return { data: response.data, success: true, message: message || 'success' }; // Return response to the caller
    } else {
      showNotification({ message: message || 'something went wrong', title: "Error!!", type: "error", autohide: true, showInput: false });
      return { success: false, message: message };
    }
  } catch (error) {
    console.log("error", error);
    showNotification({ message: "Some Error Occured.", title: "Error!!", type: "error", autohide: true, showInput: false });
    // Check if the error status is 401
    if (error.response && error.response.status === 401) {
      logoutOnExpiry(); // Call your logout function when 401 occurs
    }
  }
};

export const handlePostApiV2 = async (requestUrl, body = {}) => {
  try {
    const token = localStorage.getItem("secretkey");
    const config = {
      headers: { Authorization: token },
    };
    const response = await axios.post(`${url}${requestUrl}`, { ...body }, config);

    const status = response.data.success;
    const message = response.data.message;

    if (status === true) {
      return { data: response.data, success: true, message: message || 'success' }; // Return response to the caller
    } else {
      showNotification({ message: message || 'something went wrong', title: "Info!!", type: "info", autohide: true, showInput: false });
      return { success: false, message: message };
    }
  } catch (error) {
    console.log("error", error);
    showNotification({ message: "Some Error Occured.", title: "Error!!", type: "error", autohide: true, showInput: false });
    // Check if the error status is 401
    if (error.response && error.response.status === 401) {
      logoutOnExpiry(); // Call your logout function when 401 occurs
    }
  }
};

export const valid_mobile = (mobile) => {
	var phoneno = /^[6-9][0-9]{9}$/;
	if (!mobile.match(phoneno)) {
		return false;
	} else {
		return true;
	}
};

export const valid_pincode = (value) => {
	const pattern = /^\d{6}$/;
	// Check if the input value is a valid pincode
	if (!pattern.test(value)) {
		return false;
	} else {
		return true;
	}
};

export const valid_email = (email) => {
	if (/^[a-zA-Z0-9]{1}[A-Za-z0-9.+_-]+@[A-Za-z0-9]+\.[A-Za-z]{2,}$/.test(email)) {
		return true;
	} else {
		return false;
	}
};

export const valid_gst = (gst) => {
	var regexGST =
		/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}Z[a-zA-Z\d]{1}?$/;
	return regexGST.test(gst);
};

export const valid_pan = (pan) => {
	var regexPAN = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}?$/;
	if (!pan.match(regexPAN) || pan.length !== 10) {
		return false;
	} else {
		return true;
	}
};

export const formattedDateTime = (date) => {
  return moment(date)
  .format('DD-MM-YYYY hh:mm:ss A');
}


export function generateUniqueHexColors(count) {
  if (count < 1) return [];

  const colors = new Set();

  // Helper function to determine if a color falls within a restricted range
  const isRestrictedColor = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16); // Red component
    const g = parseInt(hex.slice(3, 5), 16); // Green component
    const b = parseInt(hex.slice(5, 7), 16); // Blue component

    // Check for red shades (high R, low G and B)
    if (r > 150 && g < 100 && b < 100) return true;

    // Check for green shades (high G, low R and B)
    if (g > 150 && r < 100 && b < 100) return true;

    // Check for gold shades (high R and G, low B)
    if (r > 180 && g > 150 && b < 80) return true;

    return false;
  };

  while (colors.size < count) {
    const color = `#${Math.floor(Math.random() * 0xffffff).toString(16).padStart(6, '0')}`;

    // Ensure the color avoids restricted ranges
    if (!isRestrictedColor(color)) {
      colors.add(color);
    }
  }

  return Array.from(colors);
}


// export function generateUniqueHexColors(count) {
//   if (count < 1) return [];

//   const colors = new Set();

//   // Function to check if a color is light
//   const isLightColor = (hex) => {
//     const r = parseInt(hex.slice(1, 3), 16); // Red component
//     const g = parseInt(hex.slice(3, 5), 16); // Green component
//     const b = parseInt(hex.slice(5, 7), 16); // Blue component

//     // Calculate luminance (0.2126*R + 0.7152*G + 0.0722*B)
//     const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

//     // A threshold to define "light" colors
//     return luminance > 200; // Adjust threshold as needed
//   };

//   while (colors.size < count) {
//     const color = `#${Math.floor(Math.random() * 0xffffff).toString(16).padStart(6, '0')}`;

//     // Ensure the color is not white, black, or light
//     if (
//       color !== '#FFFFFF' &&
//       color !== '#000000' &&
//       !isLightColor(color) &&
//       color !== '#008000' && // green
//       color !== '#FFD700' // gold
//     ) {
//       colors.add(color);
//     }
//   }

//   return Array.from(colors);
// }


export const locationAreEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
  if (arr1?.length !== arr2?.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
}

export const calculateETAMin = (eta) => {
  if (eta) {
    const timeInMinutes = eta / 60;
    let finalTime;
    if (timeInMinutes > 60) {
      const hours = Math.floor(timeInMinutes / 60);
      const minutes = Math.round(timeInMinutes % 60);
      finalTime = `${hours} hour${hours !== 1 ? "s" : ""} ${minutes} minute${minutes !== 1 ? "s" : ""}`;
    } else {
      finalTime = `${Math.trunc(timeInMinutes)} minute${Math.trunc(timeInMinutes) !== 1 ? "s" : ""}`;
    }

    return finalTime
  }else{
    return 0;
  }
};

export const calculateETASEC = (eta) => {
  if (eta) {
    const hours = Math.floor(eta / 3600);
    const minutes = Math.floor((eta % 3600) / 60);
    const seconds = eta % 60;

    let finalTime = "";

    if (hours > 0) {
      finalTime += `${hours} hour${hours !== 1 ? "s" : ""} `;
    }
    if (minutes > 0 || hours > 0) {
      finalTime += `${minutes} minute${minutes !== 1 ? "s" : ""} `;
    }
    finalTime += `${seconds} second${seconds !== 1 ? "s" : ""}`;

    return finalTime.trim();
  } else {
    return "0 seconds";
  }
};

export const calculateETA = (eta) => {
  if (eta) {
    const hours = Math.floor(eta / 3600);
    const minutes = Math.floor((eta % 3600) / 60);
    const seconds = Math.floor(eta % 60);

    let finalTime = "";

    if (hours > 0) {
      finalTime += `${hours} hour `;
    }
    if (minutes > 0 || hours > 0) {
      finalTime += `${minutes} min `;
    }
    finalTime += `${seconds > 0 ? `${seconds}s` : ""}`;

    return finalTime.trim();
  } else {
    return "0 seconds";
  }
};