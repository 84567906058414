import React, { useEffect, useState } from "react";
import { CustomViewModal } from "../Utils/Inputs";
import { MarkerPreviewWithPolyGon } from "../Utils/MapPreview";
const Geofence = ({ show, onClose, sharedData }) => {
 

  // const center = { lat: sharedData?.lat || 0, lng: sharedData?.lng || 0 };
  const center = [sharedData?.lng,sharedData?.lat]
  const coordinates =
    sharedData?.geofence && sharedData.geofence[0] && sharedData.geofence[0].geofence.coordinates
      ? sharedData.geofence[0].geofence.coordinates.map((x) => ([x[0],x[1]]))
      : [];
  // console.log(coordinates, center);

  return (
    <CustomViewModal heading="Geofence" show={show} onClose={onClose} size="lg">
      <div style={{ maxHeight: "70vh", height: "70vh" }}>
        <MarkerPreviewWithPolyGon defaultCenter={center} isPolyGon={true} PolygonCoordinate={coordinates} />
      </div>
    </CustomViewModal>
  );
};

export default Geofence;
