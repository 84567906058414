import React, { useEffect, useState } from "react";
import CustomPageLayout from "../Utils/CustomPageLayout/CustomPageLayout";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { RoutePreview , MultiRoutePreview } from "../Utils/MapPreview";
import { locationAreEqual, calculateETA, calculateETAMin } from "../../services/functions";
import { handlePostApi } from "../../services/functions";
import { showNotification } from "../Utils/Inputs";
import { generateUniqueHexColors } from "../../services/functions";
import history from '../../services/history';
import moment from "moment";
import './TripManagment.css';
import { Checkbox } from "../Utils/Inputs";
import axios from 'axios'
import { logoutOnExpiry, url  } from "../../services/requests";
import { DriverRes } from "../Utils/Dummy";

export default function TripMapMobilePreview() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const driverId = searchParams.get('driverid');
    const tripDate = searchParams.get('date');
    const [tripDetails, setTripDetails] = useState([]);
    const [colorArray, setColorArray] = useState(['#007bc9']);
    const [showListView, setShowListView] = useState(false);
    const [collapsedVehicles, setCollapsedVehicles] = useState({});
    const [selectedTrip, setSelectedTrip] = useState([]);
    const [selectedColor, setSelectedColor] = useState([]);
    const [maploading, setMaploading] = useState(false);


    // console.log('state-mobile',location, token, driverId, tripDate)

    // Toggle collapse state for a specific vehicle
    const toggleCollapse = (vehicleNo) => {
      setCollapsedVehicles((prevState) => ({
        ...prevState,
        [vehicleNo]: !prevState[vehicleNo], // Toggle the collapse state
      }));
    };

    // {
    //     "date": "2025-02-04 12:00:00",
    //     "searchType": "vehicle.driverDetails._id",
    //     "searchValue": "66b22ca6e45ad87258d16de7"
    // }


    useEffect(()=> {
        if(token && driverId) {
            const body = {
              date: formatDateTime(tripDate || new Date()),
              searchType: "vehicle.driverDetails._id",
              searchValue: driverId,
            };
            fetchTrip(token, body)     
        }

    }, [token, driverId, tripDate])

    const fetchTrip = async (token, body) => {
        try {
          setMaploading(true)
            const result = await handlePostApiWithToken(token, "/trip/getTrip", { ...body });
            // console.log("result", result);
            if (result.success) {
              setMaploading(false)
              // console.log("result.data", result.data);
              setTripDetails(result.data.data)
              setColorArray(generateUniqueHexColors(result.data.data.length))
            } else {
              // setTripDetails(DriverRes.data);
              // setColorArray(generateUniqueHexColors(DriverRes.data.length))
              setMaploading(false)
              showNotification({ message: result?.message || "Something went wrong", type: "error", title: "Error!!", autohide: true, showInput: false });
            }
          } catch (error) {
            setMaploading(false)
            console.error("Error fetching data:", error);
          }
    }

    const handleSelectedTrip = (e, trip, color) => {
      const isChecked = e.target.checked;
  
      if (isChecked) {
        // Add trip and color to the arrays
        setSelectedTrip((prevTrips) => [...prevTrips, trip]);
        setSelectedColor((prevColors) => [...prevColors, color]);
      } else {
        // Remove trip and color from the arrays
        setSelectedTrip((prevTrips) => prevTrips.filter(t => t !== trip));
        setSelectedColor((prevColors) => prevColors.filter(c => c !== color));
      }
    };
    
  return (
    <CustomPageLayout title="Trip Preview">
      {maploading ? <span>Loading....</span> :
      tripDetails.length > 0 ? (
        <div>
          <div style={{ width: "100%", height: "calc(100vh - 120px)" }}>
            <MultiRoutePreview routeCoordinates={selectedTrip.length > 0 ? selectedTrip : tripDetails} setColorArray={selectedColor.length > 0 ? selectedColor : colorArray}>
              {showListView && (
                <div className="ListView-container">
                  <div className="ListView-header">
                    <span className="ListView-header-title">Estimated Time Of Arrival (ETA)</span>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowListView(!showListView)}></button>
                  </div>
                  <div className="ListView-body">
                    {tripDetails?.length > 0 &&
                      tripDetails.map((el, i) => (
                        <div key={el?._id || i} className={`vehicle-container ${!collapsedVehicles[el?._id || i] ? "vehicle-container-height" : ""}`}>
                          <div style={{ backgroundColor: `${colorArray[i]}` }} className="vehicle-header">
                            <Checkbox
                              checked={selectedTrip.length > 0 ? selectedTrip.some((e) => e._id === el._id) : false}
                              sx={{ marginTop: "9px", position: "absolute", left: "5px" }}
                              onChange={(e) => handleSelectedTrip(e, el, colorArray[i])}
                            />
                            <span>
                              {el.tripId ? el.tripId : el.vehicle.vehicleNo}&nbsp;({calculateETAMin(el.eta || 0)})
                            </span>
                            <button className="collasing-btn" onClick={() => toggleCollapse(el?._id || i)}>
                              {collapsedVehicles[el?._id || i] ? "-" : "+"}
                            </button>
                          </div>
                          {collapsedVehicles[el?._id || i] && (
                            <div className="vehicle-detail">
                              {el.locations.map((ele, index) => (
                                <div key={index} className="route-address-container">
                                  {index !== 0 && (
                                    <div className="arrow-container">
                                      {!ele.isDelivered && <span className="eta-status-left">ETA - {ele?.locationDetails?.eta ? calculateETAMin(ele.locationDetails?.eta) : "0 Minutes"}</span>}
                                      <i className="fa fa-arrow-down downArrow"></i>
                                      <span className="eta-status-right"></span>
                                    </div>
                                  )}
                                  {/* {index !== 0 && (
                                  <div className="arrow-container">
                                    <i className="fa fa-arrow-down downArrow"></i>
                                    {!ele.isDelivered && <span className="eta-status">ETA - {ele?.locationDetails?.eta ? calculateETAMin(ele.locationDetails?.eta) : "0 Minutes"}</span>}
                                  </div>
                                )} */}

                                  <div
                                    className={`addressCard ${
                                      ele.isDelivered
                                        ? "delivered"
                                        : ele.isRescheduleForOtherDay === true || ele.isRescheduleForToday === true
                                        ? "skipped"
                                        : el.currentWaypoint == ele.waypoint_index
                                        ? "card-active"
                                        : ""
                                    }`}
                                  >
                                    {ele?.locationDetails?.startSlotTime && ele?.locationDetails?.endSlotTime && (
                                      <div className="slot-status">
                                        <span>
                                          Preferred Slot -{ele?.locationDetails?.startSlotTime + ":00"}-{ele?.locationDetails?.endSlotTime + ":00"}
                                        </span>
                                      </div>
                                    )}
                                    {index !== 0 &&
                                      (ele.isDelivered ? (
                                        <div className="delivered-status">
                                          <span>Delivered At -{moment(ele.deliveryTime).format("DD MMM YYYY hh:mm A")}</span>
                                        </div>
                                      ) : el.locations?.length - 1 === index ? (
                                        <span className="pit-delivered-right">Pit Stop Time - {el?.pitStopTime ? calculateETA(el.pitStopTime) : "0 Minutes"}</span>
                                      ) : (
                                        <span className="pit-delivered-right">Service Time - {ele?.locationDetails?.serviceTime ? calculateETA(ele.locationDetails.serviceTime) : "0 Minutes"}</span>
                                      ))}
                                    <div className="location-mark">
                                      {ele.locationDetails.locationType === "source" || locationAreEqual(el.locations[0].location, ele.location) ? "S." : `${index}.`}
                                    </div>
                                    {/* width: calc(100% - 20px);
    height: auto;
    display: flex
;
    justify-content: center;
    padding: 5px 10px;
    flex-direction: column; */}
                                    <div style={{ width: "calc(100% - 20px)", height: "auto", display: "flex", justifyContent: "center", padding: "5px 10px", flexDirection: "column" }}>
                                      <span style={{ textAlign: "center" }}>
                                        {ele?.locationDetails?.personName || ""}, {ele?.locationDetails?.mobile || ""}
                                      </span>
                                      <span style={{ textAlign: "center" }}>
                                        {ele?.locationDetails?.address
                                          ? (() => {
                                              const words = ele.locationDetails.address.split(/\s+/); // Split by spaces
                                              return words.length > 5 ? words.slice(0, 5).join(" ") + "..." : ele.locationDetails.address;
                                            })()
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="ListView-footer"></div>
                </div>
              )}
            </MultiRoutePreview>
          </div>
          <div className="d-flex justify-content-center">
            <button className="myMapBtnGrey" onClick={() => setShowListView(!showListView)}>
              {"List View"}
            </button>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", display : 'flex', justifyContent : 'center', alignItems : 'center', height: "calc(100vh - 180px)" }}>
            <p style={{color : 'red'}}>No Data Available</p>
        </div>
      )}
    </CustomPageLayout>
  );
}


const handlePostApiWithToken = async (token, requestUrl, body = {}) => {
    try {
    //   const token = localStorage.getItem("secretkey");
      const config = {
        headers: { Authorization: token  },
      };
      const response = await axios.post(`${url}${requestUrl}`, { ...body }, config);
  
      const status = response.data.success;
      const message = response.data.message;
  
      if (status === true) {
        return { data: response.data, success: true, message: message || 'success' }; // Return response to the caller
      } else {
        showNotification({ message: message || 'something went wrong', title: "Error!!", type: "error", autohide: true, showInput: false });
        return { success: false, message: message };
      }
    } catch (error) {
      console.log("error", error);
      showNotification({ message: "Some Error Occured.", title: "Error!!", type: "error", autohide: true, showInput: false });
      // Check if the error status is 401
    //   if (error.response && error.response.status === 401) {
    //     logoutOnExpiry(); // Call your logout function when 401 occurs
    //   }
    }
  };

  function formatDateTime(inputDateTime) {
    return moment(inputDateTime)
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 }) // Set time to 12:00:00
      .format("YYYY-MM-DD HH:mm:ss"); // Format as "YYYY-MM-DD HH:mm:ss"
  }
