import React, { useEffect, useState, useRef } from "react";
import mapboxgl from 'mapbox-gl';
import * as turf from "@turf/turf";
import 'mapbox-gl/dist/mapbox-gl.css';
import { routeCoordinates } from "./Dummy";
import { generateUniqueHexColors } from "../../services/functions";
import { useDispatch, useSelector } from "react-redux";
import { getSingleMapRouteList, getMultiMapRouteList } from "../../state/redux/actions/mapRoute";



const INITIAL_CENTER =  [80.8918069, 26.7966107] //[77.275491, 28.525446]
const INITIAL_ZOOM = 10.12
const AccessKey = process.env.REACT_APP_MAPBOX_MAPS_API_KEY

// const routeCoordinat = [
//   [80.91822233051062, 26.799217170255265], // Point A (Delhi)
//   [80.91836113482714, 26.798972969930702], // Waypoint 1
//   [80.91892004013062, 26.796849063320668], // Waypoint 2
//   [80.91882281005383, 26.796083525445386], // Waypoint 3
//   [80.92103160917759, 26.794562011063533], // Point B (Destination)
// ];

const labels = ["A", "B", "C", "D", "E"];

// const coordinates = routeCoordinates
        //   .map((coord) => coord.location.join(","))
        //   .join(";");

        // console.log('cordinates===>>>>', coordinates)

        // const response = await fetch(
        //   `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapboxgl.accessToken}`
        // );
        // const data = await response.json();

        // console.log('response', response)

        // const route = data.routes[0].geometry;


export const RoutePreviewDragable = ({ routeCoordinates, realTimeLocation, isDragable, newMarkerDetail, children }) => {
  // console.log('routeCoordinates', routeCoordinates)
  const mapContainerRef = useRef(null);
  const markersRef = useRef([]); // To store markers for removal or updates
  const radiusRef = useRef([]); // To store radius circles for removal or updates
  mapboxgl.accessToken = AccessKey;
  const [map, setMap] = useState(null);
  const [truckMarker, setTruckMarker] = useState(null);

  useEffect(() => {
    if (routeCoordinates?.length > 0 && routeCoordinates[0].location) {
      const initialLocation = routeCoordinates[1].location;

      const mapInstance = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: initialLocation,
        zoom: 15,
      });

      mapInstance.addControl(new mapboxgl.NavigationControl(), "top-right");
      setMap(mapInstance);

      return () => mapInstance.remove();
    }
  }, [routeCoordinates]);

  useEffect(() => {
    if (map && routeCoordinates.length > 0) {
      const getRoute = async () => {
        const chunkedCoordinates = chunkCoordinates(routeCoordinates, 24);
        const allRoutes = { coordinates: [], type: "LineString" };

        for (const chunk of chunkedCoordinates) {
          const coordinates = chunk.map((coord) => coord.location.join(",")).join(";");
          const response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapboxgl.accessToken}`);
          const data = await response.json();
          if (data.routes && data.routes[0]) {
            allRoutes.coordinates.push(...data.routes[0].geometry.coordinates);
          }
        }

        if (!map.getSource("route")) {
          map.addSource("route", {
            type: "geojson",
            data: { type: "Feature", properties: {}, geometry: allRoutes },
          });

          map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: { "line-join": "round", "line-cap": "round" },
            paint: { "line-color": "#ff7e5f", "line-width": 5 },
          });
        }
      };

      // Remove previous markers and circles
      const removeMarkersAndCircles = () => {
        markersRef.current.forEach(marker => marker.remove());
        markersRef.current = []; // Clear markers list

        radiusRef.current.forEach(circle => {
          if (map.getSource(circle.sourceId)) {
            map.removeLayer(circle.layerId);
            map.removeSource(circle.sourceId);
          }
        });
        radiusRef.current = []; // Clear radius circles list
      };

      // Remove markers and radius, and re-create them with updated draggable state
      const createMarkersAndRadius = () => {
        removeMarkersAndCircles();
        routeCoordinates.forEach((coord, index) => {
          let markerColor = "#ff7e5f";
          if (coord.isDelivered) markerColor = "green";
          else if (coord.isReached) markerColor = "gold";
          else if (coord.isRescheduleForOtherDay || coord.isRescheduleForToday) markerColor = "red";

          const markerElement = createMarkerElement({
            markerColor,
            textContent: index === 0 ? "S" : (routeCoordinates.length - 1 === index ? "D" : index.toString()),
          });

          const draggableMarker = new mapboxgl.Marker({ element: markerElement, draggable: isDragable })
            .setLngLat(coord.location)
            .addTo(map);

          markersRef.current.push(draggableMarker);

          // Create and show the radius circle only for draggable markers
          if (isDragable && index === 1) {
            // Create a fixed buffer (circle) around the original marker location
            const centerPoint = turf.point(coord.location);
            const radius = 0.5; // 500 meters
            const circle = turf.buffer(centerPoint, radius, { units: "kilometers" });

            const sourceId = `radius-circle-${index}`;
            const layerId = `radius-layer-${index}`;

            if (!map.getSource(sourceId)) {
              map.addSource(sourceId, { type: "geojson", data: circle });

              map.addLayer({
                id: layerId,
                type: "fill",
                source: sourceId,
                paint: {
                  "fill-color": "rgba(0, 122, 255, 0.3)",
                  "fill-outline-color": "#007AFF",
                },
              });

              // Store the radius circle info for future removal
              radiusRef.current.push({ sourceId, layerId });
            }
          }

          // Handle the "dragend" event
          draggableMarker.on("drag", () => {
            const newLngLat = draggableMarker.getLngLat();
            const distance = turf.distance(
              turf.point(coord.location),
              turf.point([newLngLat.lng, newLngLat.lat]),
              { units: "meters" }
            );
            if(distance < 500){
              newMarkerDetail([newLngLat.lng, newLngLat.lat])
            }
            if (distance > 500) {
              // Reset the marker to its original position if it moves beyond the 500m limit
              draggableMarker.setLngLat(coord.location);
            }
          });
        });
      };

      map.on("style.load", () => {
        if (!map || !routeCoordinates.length) return;

        createMarkersAndRadius(); // Create markers and radius when map style is loaded

        // Load the route on map load
        map.on("load", getRoute);
      });

      // When isDragable changes, remove and re-add the markers and radius
      createMarkersAndRadius();
    }
  }, [map, routeCoordinates, isDragable, newMarkerDetail]);

  // Add real-time truck marker
  useEffect(() => {
    if (realTimeLocation && Object.keys(realTimeLocation).length > 0 && map && routeCoordinates.length > 0) {
      const { latitude, longitude } = realTimeLocation;

      // If truck marker doesn't exist, create it
      if (!truckMarker) {
        const truckIcon = document.createElement('div');
        truckIcon.className = 'truck-marker'; // Define the truck marker CSS class

        const truckMarkerInstance = new mapboxgl.Marker({ element: truckIcon })
          .setLngLat([longitude, latitude])
          .addTo(map);

        setTruckMarker(truckMarkerInstance);
      } else {
        // If truck marker exists, update its position
        truckMarker.setLngLat([longitude, latitude]);
      }
    }
  }, [realTimeLocation, map]);

  return (
    <div ref={mapContainerRef} style={{ height: "100%", width: "100%", position: "relative" }}>
      {children}
    </div>
  );
};
        

      
export const RoutePreview = ({ tripId, routeCoordinates, realTimeLocation, children }) => {
  // console.log('routeCoordinates---', routeCoordinates)
  const dispatch = useDispatch()
  const mapContainerRef = useRef(null);
  mapboxgl.accessToken = AccessKey;
  const [map, setMap] = useState(null);
  const [truckMarker, setTruckMarker] = useState(null);
  const color  = generateUniqueHexColors(1)

  const Routelisting = useSelector((state) => state.getMapRouteRed.mapSingleRouteListing);

  useEffect(() => {
    // Initialize the map
    if(routeCoordinates?.length > 0 && routeCoordinates[0].location){
      // console.log('routeCoordinates', routeCoordinates)
      const mapInstance = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: routeCoordinates[0].location, // Start at Point A
        zoom: 17,
        // interactive: false,
      });
      mapInstance.addControl(new mapboxgl.NavigationControl(), "top-right");
      setMap(mapInstance);
      return () => mapInstance.remove();
    }
  }, [routeCoordinates]);

  useEffect(() => {
    if (map && routeCoordinates.length > 0) {

      
      // Function to fetch route data and add route line
      const getRoute = async () => {

        const chunkedCoordinates = chunkCoordinates(routeCoordinates, 24);

        // console.log('chucks', chunkedCoordinates)

        let allRoutes = {
          coordinates : [],
          type: "LineString"
        };


        if(Routelisting.length > 0){
          const filterRoute = Routelisting.filter(el => el.tripNumber === tripId) || [];
          // console.log('filterRoute', filterRoute)
          if(filterRoute.length > 0){
            allRoutes = filterRoute[0].allRoutes
          }else{
            for (const chunk of chunkedCoordinates) {
              const coordinates = chunk.map((coord) => coord.location.join(",")).join(";");
              const response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapboxgl.accessToken}`);
              const data = await response.json();
              if (data.routes && data.routes[0]) {
                allRoutes.coordinates.push(...data.routes[0].geometry.coordinates);
              }
            }
            dispatch(getSingleMapRouteList({tripNumber : tripId, allRoutes: allRoutes}))
          }
        }else{
          // console.log('runn for mapboxxxxxxxx 222')
          for (const chunk of chunkedCoordinates) {
            const coordinates = chunk.map((coord) => coord.location.join(",")).join(";");
            const response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapboxgl.accessToken}`);
            const data = await response.json();
            if (data.routes && data.routes[0]) {
              allRoutes.coordinates.push(...data.routes[0].geometry.coordinates);
            }
          }
          dispatch(getSingleMapRouteList({tripNumber : tripId, allRoutes: allRoutes}))
        }

        // console.log('allRoutes===>>>', allRoutes)
        // Add route to the map
        map.addSource("route", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: allRoutes,
          },
        });

        map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#ff7e5f",
            "line-width": 5,
          },
        });
      };

      // getRoute();
      // Add markers with labels
      routeCoordinates.forEach((coord, index) => {
        let markerColor = "#ff7e5f";
        if (coord.isDelivered) {
          markerColor = "green";
        } else if (coord.isReached) {
          markerColor = "gold";
        } else if (coord.isRescheduleForOtherDay === true || coord.isRescheduleForToday === true) {
          markerColor = "red";
        } else {
          markerColor = "#ff7e5f";
        }


        const routeDetail = getRouteDetails(coord.location, routeCoordinates);

        const markerElement = createMarkerElement({
          markerColor: markerColor,
          textContent: coord.locationType === "source" || coord.locationType === "destination" ? "S" : routeDetail.length > 1 ? `${routeDetail[0].waypoint_index}+` : `${index}`,
          isExtraDetail : routeDetail.length > 1 ? true : false,
        })

        const popup = new mapboxgl.Popup()
          .setLngLat(coord.location)
          .setHTML(
            `<div className="d-flex flex-column" >
             <div style={{color : 'black', fontWeight : '900'}}><strong>Details</strong></div>
              ${!(coord.locationType === "source" || coord.locationType === "destination")
                  ? routeDetail.map((el, index) => {
                      return `<div><span style={{fontSize : '12px', fontWeight : 'bold'}}><strong>${el?.waypoint_index}. </strong>${el?.locationDetails?.address || "-"}</span></div>
                             <div style{{marginTop : '10px'}}><strong>${el?.locationDetails?.personName || "-"}, ${el?.locationDetails?.mobile || "-"}</strong></div><br/>`;
                    }).join("")
                  :  `<div><span style={{fontSize : '12px', fontWeight : 'bold'}}>${coord?.locationDetails?.address || "-"}</span></div>
                     <div><strong>${coord?.locationDetails?.personName || "-"}, ${coord?.locationDetails?.mobile || "-"}</strong></div>`
               }
             </div>`)
          .addTo(map);

        new mapboxgl.Marker(markerElement)
          .setLngLat(coord.location) // Marker coordinates
          .setPopup(popup)
          .addTo(map);
      });

      map.on("load", getRoute);
    }
  }, [map, routeCoordinates]);

  // Add real-time truck marker
  useEffect(() => {
    if (realTimeLocation && Object.keys(realTimeLocation).length > 0 && map && routeCoordinates.length > 0) {
      const { latitude, longitude } = realTimeLocation;

      // If truck marker doesn't exist, create it
      if (!truckMarker) {
        const truckIcon = document.createElement('div');
        truckIcon.className = 'truck-marker'; // Define the truck marker CSS class

        const truckMarkerInstance = new mapboxgl.Marker({ element: truckIcon })
          .setLngLat([longitude, latitude])
          .addTo(map);

        setTruckMarker(truckMarkerInstance);
      } else {
        // If truck marker exists, update its position
        truckMarker.setLngLat([longitude, latitude]);
      }
    }
  }, [realTimeLocation, map]);
  

  return (
    <div
      ref={mapContainerRef}
      style={{
        height: "100%", // Adjust height as needed
        width: "100%", // Adjust width as needed
        position: "relative",
      }}
    >
      {children}
    </div>
  );
}


export const MarkerPreview = ({ defaultCenter, isDragable, newMarkerDetail, children }) => {
  const mapContainerRef = useRef(null);
  const markersRef = useRef([]); // To store markers for removal or updates
  mapboxgl.accessToken = AccessKey;
  useEffect(() => {
    // Initialize map
    const map = new mapboxgl.Map({
      container: mapContainerRef.current, // Reference to the map container
      style: "mapbox://styles/mapbox/streets-v11", // Map style
      center: defaultCenter || [77.1025, 28.7041], // Initial center coordinates [lng, lat]
      zoom: 10, // Initial zoom level
    });

    // Add navigation controls (zoom and rotation)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    const draggableMarker = new mapboxgl.Marker({draggable: isDragable})
    .setLngLat(defaultCenter || [77.1025, 28.7041]) // Marker coordinates
    .addTo(map);

    markersRef.current.push(draggableMarker);

    // Handle the "dragend" event
    draggableMarker.on("drag", async () => {
      const newLngLat = draggableMarker.getLngLat();
      const addressDetail = {longitude : newLngLat.lng, latitude : newLngLat.lat}
      try {
        const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${newLngLat.lng},${newLngLat.lat}.json?access_token=${mapboxgl.accessToken}`);
        const data = await response.json();
        const fetchedAddress = data.features[0]?.place_name || null;
        addressDetail.address = fetchedAddress;
      } catch (error) {
        console.error("Error fetching address:", error);
      }
      newMarkerDetail(addressDetail)
    })

    // Clean up on component unmount
    return () => map.remove();
  }, []);

  return (
    <div
      ref={mapContainerRef}
      style={{
        height: "100%", // Adjust height as needed
        width: "100%", // Adjust width as needed
        position: "relative",
      }}
    >
      {children}
    </div>
  );
};

export const MarkerPreviewWithPolyGon = ({ defaultCenter = [77.1025, 28.7041], isPolyGon, isCircleShape, PolygonCoordinate }) => {
  const mapContainerRef = useRef(null);
  mapboxgl.accessToken = AccessKey;
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: defaultCenter, 
      zoom: 17,
    });

    // Add navigation controls
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Add Marker at the center
    new mapboxgl.Marker().setLngLat(defaultCenter).addTo(map);

    // Function to generate polygon around defaultCenter
    const generatePolygon = (center, offset = 0.059) => {
      const [lng, lat] = center;
      return [
        [lng - offset, lat - offset], // Bottom-left
        [lng + offset, lat - offset], // Bottom-right
        [lng + offset, lat + offset], // Top-right
        [lng - offset, lat + offset], // Top-left
        [lng - offset, lat - offset], // Closing the polygon
      ];
    };

    const generateCircle = (center, radius = 0.05, numPoints = 64) => {
      const [lng, lat] = center;
      const coordinates = [];
    
      for (let i = 0; i <= numPoints; i++) {
        const angle = (i / numPoints) * (2 * Math.PI); // Divide the circle into equal angles
        const offsetLng = lng + 1 * (radius * Math.cos(angle)); // Calculate X (longitude)
        const offsetLat = lat + 0.9 * (radius * Math.sin(angle)); // Calculate Y (latitude)
        coordinates.push([offsetLng, offsetLat]);
      }
    
      return [coordinates]; // Return as a nested array for GeoJSON format
    };
    

    // Generate polygon dynamically
    // generateCircle(defaultCenter, 0.0008, 64)
    const polygonCoordinates = isPolyGon ? (isCircleShape ? generateCircle(defaultCenter, 0.0008, 64)    : PolygonCoordinate || generatePolygon(defaultCenter) ): null;

    // Add Polygon Source and Layer

    if(isPolyGon){
      map.on("load", () => {
        map.addSource("polygon", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: isCircleShape ?  polygonCoordinates : [polygonCoordinates], // Nested inside an array
            },
          },
        });
  
        map.addLayer({
          id: "polygon-layer",
          type: "fill",
          source: "polygon",
          layout: {},
          paint: {
            "fill-color": "#ff0000", // Red color
            "fill-opacity": 0.4, // 40% transparency
          },
        });
  
        map.addLayer({
          id: "polygon-border",
          type: "line",
          source: "polygon",
          layout: {},
          paint: {
            "line-color": "#ff0000", // Black border
            "line-width": 1,
          },
        });
      });
    }
    

    return () => map.remove();
  }, [defaultCenter]); // Re-run effect when defaultCenter changes

  return (
    <div
      ref={mapContainerRef}
      style={{ height: "100%", width: "100%" }}
    />
  );
};


export const MultiRoutePreview = ({ routeCoordinates, setColorArray, children }) => {
  const mapContainerRef = useRef(null);
  const dispatch = useDispatch()
  mapboxgl.accessToken = AccessKey;
  const [map, setMap] = useState(null);
  const colorArray = setColorArray.length > 0 ? setColorArray : generateUniqueHexColors(routeCoordinates.length);
  const Routelisting = useSelector((state) => state.getMapRouteRed.mapSingleRouteListing);
  // console.log('colorArray====>>>>>', colorArray)

  useEffect(() => {
    if (routeCoordinates.length > 0) {
      const defaultCenter = [routeCoordinates[0].origin.longitude, routeCoordinates[0].origin.latitude]
      const mapInstance = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: defaultCenter, // Default center
        zoom: 13, // Default zoom
      });
      mapInstance.addControl(new mapboxgl.NavigationControl(), "top-right");
      setMap(mapInstance);

      return () => mapInstance.remove();
    }
  }, [routeCoordinates]);

  useEffect(() => {
    if (map) {
      map.on('load', () => {
        routeCoordinates.forEach((routeEle, routeIndex) => {
          if (routeEle.locations.length > 0) {
            const getRoute = async () => {
              //new route render
              const chunkedCoordinates = chunkCoordinates(routeEle.locations, 24);

              // console.log('chucks', chunkedCoordinates)

              const allRoutes = {
                coordinates: [],
                type: "LineString",
              };

              if (Routelisting.length > 0) {
                const filterRoute = Routelisting.filter((el) => el.tripNumber === routeEle.tripId) || [];
                if (filterRoute.length > 0) {
                  allRoutes.coordinates = filterRoute[0].allRoutes.coordinates;
                }else{
                  for (const chunk of chunkedCoordinates) {
                    const coordinates = chunk.map((coord) => coord.location.join(",")).join(";");
    
                    const response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapboxgl.accessToken}`);
                    const data = await response.json();
    
                    if (data.routes && data.routes[0]) {
                      allRoutes.coordinates.push(...data.routes[0].geometry.coordinates);
                    }
                  }
                  dispatch(getSingleMapRouteList({tripNumber : routeEle.tripId, allRoutes: allRoutes}))
                }
              } else {
                for (const chunk of chunkedCoordinates) {
                  const coordinates = chunk.map((coord) => coord.location.join(",")).join(";");
  
                  const response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapboxgl.accessToken}`);
                  const data = await response.json();
  
                  if (data.routes && data.routes[0]) {
                    allRoutes.coordinates.push(...data.routes[0].geometry.coordinates);
                  }
                }
                dispatch(getSingleMapRouteList({tripNumber : routeEle.tripId, allRoutes: allRoutes}))
              }

              
  
              const sourceId = `route-${routeEle.vehicle.vehicleNo}-${routeIndex}`;
              const layerId = `layer-${routeEle.vehicle.vehicleNo}-${routeIndex}`;
              const color = colorArray[routeIndex];
  
              if (!map.getSource(sourceId)) {
                map.addSource(sourceId, {
                  type: "geojson",
                  data: {
                    type: "Feature",
                    properties: {},
                    geometry: allRoutes,
                  },
                });
  
                map.addLayer({
                  id: layerId,
                  type: "line",
                  source: sourceId,
                  layout: {
                    "line-join": "round",
                    "line-cap": "round",
                  },
                  paint: {
                    "line-color": color,
                    "line-width": 5,
                  },
                });
              }
  
              // Add markers
              routeEle.locations.forEach((coord, index) => {
                const markerColor = coord.isDelivered
                  ? "green"
                  : coord.isReached
                  ? "gold"
                  : color;


                const routeDetail = getRouteDetails(coord.location, routeEle.locations);
  
                // const markerElement = createMarkerElement({
                //   markerColor: markerColor,
                //   textContent: (locationAreEqual(routeEle.locations[0].location, coord.location)  ? "S" : index.toString()),
                // });
  
                // const popup = new mapboxgl.Popup()
                //   .setLngLat(coord.location)
                //   .setHTML(
                //     `<div>
                //       <strong>Details:</strong>
                //       <p>${coord?.locationDetails?.address || "N/A"}</p>
                //       <p>${coord?.locationDetails?.personName || "N/A"} (${coord?.locationDetails?.mobile || "N/A"})</p>
                //     </div>`
                //   );


                const markerElement = createMarkerElement({
                  markerColor: markerColor,
                  textContent: coord.locationType === "source" || coord.locationType === "destination" ? "S" : routeDetail.length > 1 ? `${routeDetail[0].waypoint_index}+` : `${index}`,
                  isExtraDetail : routeDetail.length > 1 ? true : false,
                })
        
                const popup = new mapboxgl.Popup()
                  .setLngLat(coord.location)
                  .setHTML(
                    `<div className="d-flex flex-column" >
                     <div style={{color : 'black', fontWeight : '900'}}><strong>Details</strong></div>
                      ${!(coord.locationType === "source" || coord.locationType === "destination")
                          ? routeDetail.map((el, index) => {
                              return `<div><span style={{fontSize : '12px', fontWeight : 'bold'}}><strong>${el?.waypoint_index}. </strong>${el?.locationDetails?.address || "-"}</span></div>
                                     <div style{{marginTop : '10px'}}><strong>${el?.locationDetails?.personName || "-"}, ${el?.locationDetails?.mobile || "-"}</strong></div><br/>`;
                            }).join("")
                          :  `<div><span style={{fontSize : '12px', fontWeight : 'bold'}}>${coord?.locationDetails?.address || "-"}</span></div>
                             <div><strong>${coord?.locationDetails?.personName || "-"}, ${coord?.locationDetails?.mobile || "-"}</strong></div>`
                       }
                     </div>`)
                  .addTo(map);
  
                new mapboxgl.Marker(markerElement)
                  .setLngLat(coord.location)
                  .setPopup(popup)
                  .addTo(map);
              });
            };
            getRoute();
          }
        });
      });
    }
  }, [map, routeCoordinates]);

  // useEffect(() => {
  //   if (map && routeCoordinates.length > 0) {
  //     routeCoordinates.forEach((routeEle, routeIndex) => {
  //       // console.log('routeEle', routeEle)
  //       if (routeEle.locations.length > 0) {
  //         const getRoute = async () => {
  //           const routeCoords = routeEle.locations.map((coord) => coord.location.join(",")).join(";");
  //           const response = await fetch(
  //             `https://api.mapbox.com/directions/v5/mapbox/driving/${routeCoords}?geometries=geojson&access_token=${mapboxgl.accessToken}`
  //           );
  //           const data = await response.json();

  //           const route = data.routes[0].geometry;

  //           const sourceId = `route-${routeEle.vehicle.vehicleNo}-${routeIndex}`;
  //           const layerId = `layer-${routeEle.vehicle.vehicleNo}-${routeIndex}`;
  //           const color = colorArray[routeIndex]

  //           if (!map.getSource(sourceId)) {
  //             map.addSource(sourceId, {
  //               type: "geojson",
  //               data: {
  //                 type: "Feature",
  //                 properties: {},
  //                 geometry: route,
  //               },
  //             });

  //             map.addLayer({
  //               id: layerId,
  //               type: "line",
  //               source: sourceId,
  //               layout: {
  //                 "line-join": "round",
  //                 "line-cap": "round",
  //               },
  //               paint: {
  //                 "line-color": color,
  //                 "line-width": 5,
  //               },
  //             });
  //           }

  //           // Add markers
  //           routeEle.locations.forEach((coord, index) => {
  //             const markerColor = coord.isDelivered
  //               ? "green"
  //               : coord.isReached
  //               ? "gold"
  //               : color;

  //             const markerElement = createMarkerElement({
  //               markerColor: markerColor,
  //               textContent: (locationAreEqual(routeEle.locations[0].location, coord.location)  ? "S" : index.toString()),
  //             })

  //             const popup = new mapboxgl.Popup()
  //               .setLngLat(coord.location)
  //               .setHTML(
  //                 `<div>
  //                   <strong>Details:</strong>
  //                   <p>${coord?.locationDetails?.address || "N/A"}</p>
  //                   <p>${coord?.locationDetails?.personName || "N/A"} (${coord?.locationDetails?.mobile || "N/A"})</p>
  //                 </div>`
  //               );

  //             new mapboxgl.Marker(markerElement)
  //               .setLngLat(coord.location)
  //               .setPopup(popup)
  //               .addTo(map);
  //           });
  //         };
  //         getRoute();
  //         colorCode();
  //       }
  //     });
  //   }
  // }, [map, routeCoordinates]);

  // const colorCode = () => {
  //   getColorArray(colorArray)
  // }

  return (
    <div
      ref={mapContainerRef}
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      {children}
    </div>
  );
};


const chunkCoordinates = (coords, size) => {
  const chunks = [];
  for (let i = 0; i < coords.length; i += size) {
    chunks.push(coords.slice(i, i + size));
  }

  // Ensure the last chunk has at least two elements
  if (chunks.length > 1 && chunks[chunks.length - 1].length === 1) {
    const lastChunk = chunks.pop(); // Remove the last chunk
    const secondLastChunk = chunks.pop(); // Remove the second last chunk

    // Add one coordinate from the second last chunk to the last chunk
    lastChunk.unshift(secondLastChunk.pop());

    // Push the modified chunks back
    chunks.push(secondLastChunk);
    chunks.push(lastChunk);
  }

  return chunks;
};


const createMarkerElement = ({ markerColor, textContent, isExtraDetail= false }) => {
  const markerElement = document.createElement("div");
  markerElement.style.backgroundColor = markerColor;
  markerElement.style.color = "#fff";
  markerElement.style.width = isExtraDetail ? "36px"  : "30px";
  markerElement.style.height = isExtraDetail ? "36px"  :"30px";
  markerElement.style.borderRadius = "50%";
  markerElement.style.display = "flex";
  markerElement.style.justifyContent = "center";
  markerElement.style.alignItems = "center";
  markerElement.style.fontWeight = "bold";
  markerElement.style.fontSize = "14px";
  markerElement.textContent = textContent;
  const innerCircle = document.createElement("div");
  innerCircle.style.position = "absolute";
  innerCircle.style.width = isExtraDetail ? "26px"  :"20px"; // Inner circle size
  innerCircle.style.height = isExtraDetail ? "25px"  :"20px"; // Inner circle size
  innerCircle.style.borderRadius = "50%";
  // innerCircle.style.backgroundColor = "#fff"; // Inner circle color
  innerCircle.style.display = "flex";
  innerCircle.style.alignItems = "center";
  innerCircle.style.justifyContent = "center";
  innerCircle.style.backgroundColor = "#007bc9";
  innerCircle.style.border = "2px solid gold";
  innerCircle.style.color = "white"; // Letter color
  innerCircle.style.fontWeight = "bold";
  innerCircle.style.fontSize = "12px";
  innerCircle.textContent = textContent;

  // Append the inner circle to the marker
  markerElement.appendChild(innerCircle);

  const pointedTail = document.createElement("div");
  pointedTail.style.position = "absolute";
  pointedTail.style.bottom = "-6px";
  pointedTail.style.left = "50%";
  pointedTail.style.transform = "translateX(-50%)";
  pointedTail.style.width = "0";
  pointedTail.style.height = "0";
  pointedTail.style.borderLeft = "10px solid transparent";
  pointedTail.style.borderRight = "10px solid transparent";
  pointedTail.style.borderTop = `10px solid ${markerColor}`;

  markerElement.appendChild(pointedTail);

  return markerElement;
};


const locationAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
}

const getRouteDetails = (location, allRoute) => {
  // console.log(location, allRoute);
  const route = allRoute.filter((route) =>
    (locationAreEqual(location, route.location) ? route : null)
  );
  // console.log(route);
  return route;
}













