/* eslint-disable */
import React, { useEffect, useState } from "react";
import moment from "moment";
import $ from "jquery";
import axios from 'axios'
import { logoutOnExpiry , url } from './../../../services/requests'


const timeSlots = [
  { label: "12:00 AM", value: "0" },
  { label: "01:00 AM", value: "1" },
  { label: "02:00 AM", value: "2" },
  { label: "03:00 AM", value: "3" },
  { label: "04:00 AM", value: "4" },
  { label: "05:00 AM", value: "5" },
  { label: "06:00 AM", value: "6" },
  { label: "07:00 AM", value: "7" },
  { label: "08:00 AM", value: "8" },
  { label: "09:00 AM", value: "9" },
  { label: "10:00 AM", value: "10" },
  { label: "11:00 AM", value: "11" },
  { label: "12:00 PM", value: "12" },
  { label: "01:00 PM", value: "13" },
  { label: "02:00 PM", value: "14" },
  { label: "03:00 PM", value: "15" },
  { label: "04:00 PM", value: "16" },
  { label: "05:00 PM", value: "17" },
  { label: "06:00 PM", value: "18" },
  { label: "07:00 PM", value: "19" },
  { label: "08:00 PM", value: "20" },
  { label: "09:00 PM", value: "21" },
  { label: "10:00 PM", value: "22" },
  { label: "11:00 PM", value: "23" },
  { label: "12:59 PM", value: "24" },
];

const DeliveryDetails = (props) => {
  const [deliverArr, setDeliverArr] = useState({});
  const [completeObj, setCompleteObj] = useState({});
  const [base64, setBase64] = useState('');
  const [imgLoader, setImgLoader] = useState(false)
  const _closeModal1 = () => {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible");
    $(".custom-modal").removeClass("custom-modal-is-visible");
  };
  useEffect(() => {
    const deliveryStateProp = props && props.deliveryState && props.deliveryState.data ? props.deliveryState.data : {};
    const driverDetail = props && props.deliveryState && props.deliveryState.item && props.deliveryState.item.vehicle ? props.deliveryState.item.vehicle : {};
    setCompleteObj(driverDetail)
    setDeliverArr(deliveryStateProp);
  }, [props]);

  useEffect(() => {
    if (Object.keys(deliverArr).length !== 0) {
      const token = localStorage.getItem('secretkey')
      const config = {
        headers: { Authorization: token }
      };
      setBase64('')
      setImgLoader(true)
      let data = { fileName: deliverArr && deliverArr.imagePath ? deliverArr.imagePath : '-' }
      axios.post(`${url}/general/getImageToBlob`, { ...data }, { headers: config.headers })
        .then(response => {
          let base = response && response.data && response.data.data ? response.data.data : ''
          let addString = "data:image/png;base64," + `${base}`
          setBase64(addString)
          setImgLoader(false)
        })
        .catch(error => {
          setImgLoader(false)
          console.log('error', error);
          if (error.error.status === 401) {
            logoutOnExpiry()
          }
        })

    }
  }, [deliverArr])

  // console.log('deliverArr', deliverArr)


  return (
    <>
      <section id="DeliveryDetails" className="custom-modal toggle_width" style={{ width: "65%" }}>
        <div className="custom-modal-header">
          <h1>Delivery Details</h1>
          <span className="ct-close" onClick={_closeModal1}></span>
        </div>
        <div className="custom-modal-body" style={{ overflowX: "auto", maxHeight: "76vh" }}>
          <div className="ddOuter">
            <div className="ddOtop">
              <div className="ddOl">
                <div className="ddOImg" style={{ position: "relative" }}>
                  {imgLoader ? (
                    <div className="loaderDiv" style={{ height: "100%" }}>
                      <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl " style={{ color: "#007BC9" }}></i>
                    </div>
                  ) : base64 !== "data:image/png;base64," ? (
                    <img className="base64Img" src={base64} alt="" />
                  ) : (
                    <span>No Image Uploaded</span>
                  )}
                </div>
              </div>
              <div className="ddOR">
                <div className="ddORB">
                  <div className="ddRow">
                    <div className="ddRspan1">Address </div>
                    <div className="ddRValue1 ddrStart" style={{ textAlign: "left" }}>
                      {"locationDetails" in deliverArr ? (deliverArr.locationDetails && deliverArr.locationDetails.address ? deliverArr.locationDetails.address : deliverArr.origin.address) : ""}
                    </div>
                    <div className="ddRIc">
                      {/* <i
                        className="fa fa-map-marker ddMrker"
                        onClick={() => toggleShow()}
                      ></i> */}
                    </div>
                  </div>
                  <div className="ddRow">
                    <div className="ddRspan1">Name </div>
                    <div className="ddRValue1 ddrStart">
                      {"locationDetails" in deliverArr ? (deliverArr.locationDetails && deliverArr.locationDetails.personName ? ` ${deliverArr.locationDetails.personName}` : "-") : ""}
                    </div>
                    <div className="ddRIc"></div>
                  </div>
                  <div className="ddRow">
                    <div className="ddRspan1">Mobile No. </div>
                    <div className="ddRValue1 ddrStart">
                      {"locationDetails" in deliverArr ? (deliverArr.locationDetails && deliverArr.locationDetails.mobile ? ` ${deliverArr.locationDetails.mobile}` : "") : "-"}
                    </div>
                    <div className="ddRIc"></div>
                  </div>
                  <div className="ddRow">
                    <div className="ddRspan1">Email Id </div>
                    <div className="ddRValue1 ddrStart">
                      {"locationDetails" in deliverArr ? (deliverArr.locationDetails && deliverArr.locationDetails.email ? ` ${deliverArr.locationDetails.email}` : "-") : "-"}
                    </div>
                    <div className="ddRIc"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ddOBottom">
              <span className="EnterpriseSpan">Details</span>
              <div className="in1">
                <div className="ddR1">
                  <div className="ddRspan">Cylinder ID </div>
                  <div className="ddRValue ddrStart">
                    <b>{deliverArr.qrCodeValue ? deliverArr.qrCodeValue : "-"}</b>
                  </div>
                </div>
                <div className="ddR1">
                  <div className="ddRspan">Preferred Time Slot </div>
                  <div className="ddRValue ddrStart">
                    {deliverArr.locationDetails && deliverArr.locationDetails.startSlotTime
                      ? deliverArr.locationDetails.startSlotTime < 10
                        ? timeSlots.find((el) => el.value == deliverArr.locationDetails.startSlotTime).label
                        : timeSlots.find((el) => el.value == deliverArr.locationDetails.startSlotTime).label
                      : "-"}
                    -
                    {deliverArr.locationDetails && deliverArr.locationDetails.endSlotTime
                      ? deliverArr.locationDetails.endSlotTime < 10
                        ? "0" + timeSlots.find((el) => el.value == deliverArr.locationDetails.endSlotTime).label
                        : timeSlots.find((el) => el.value == deliverArr.locationDetails.endSlotTime).label
                      : "-"}
                  </div>
                </div>
              </div>

              <div className="in1">
                <div className="ddR1">
                  <div className="ddRspan">Request Created at </div>
                  <div className="ddRValue ddrStart">
                    <span>
                      {deliverArr && deliverArr.locationDetails && deliverArr.locationDetails.requestTime ? `${moment(deliverArr.locationDetails.requestTime).format("DD-MM-YYYY hh:mm A")}` : "-"}
                    </span>
                  </div>
                </div>
                <div className="ddR1">
                  <div className="ddRspan">Delivered Date </div>
                  <div className="ddRValue ddrStart">
                    <span> {deliverArr && deliverArr.deliveryTime !== "" ? `${moment(deliverArr.deliveryTime).format("DD-MM-YYYY hh:mm A")}` : "-"}</span>
                  </div>
                </div>
              </div>

              <div className="in1">
                <div className="ddR1">
                  <div className="ddRspan">Delivered Time Slot </div>
                  <div className="ddRValue ddrStart">
                    <span>{moment(deliverArr.deliveryTime).format("HH:mm")}</span>

                    {deliverArr.isReached === true && deliverArr.isDelivered === true ? (
                      deliverArr.deliveryViolation === true ? (
                        <span className="dFail" style={{ paddingLeft: "12px" }}>
                          <b>Slot Violated</b>
                        </span>
                      ) : (
                        <span className="dSuccess">
                          <b>Delivered Timely</b>
                        </span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="ddR1">
                  <div className="ddRspan">Payment Mode </div>
                  <div className="ddRValue ddrStart">
                    <span>{deliverArr.locationDetails?.paymentMode ? deliverArr.locationDetails?.paymentMode : "Cash on Delivery"}</span>
                  </div>
                </div>
              </div>
              <div className="in1">
                <div className="ddR1">
                  <div className="ddRspan">OTP Status </div>
                  <div className="ddRValue ddrStart">
                    <span className={deliverArr.isOTP === false ? "dFail" : "dSucess"}>{deliverArr.isOTP === false ? "OTP Pending" : "OTP Success"}</span>
                  </div>
                </div>
                <div className="ddR1">
                  <div className="ddRspan">Leak Checked </div>
                  <div className="ddRValue ddrStart">
                    <span>{deliverArr?.leakCheck !== undefined ? (deliverArr.leakCheck ? "True" : "False") : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="in1">
                <div className="ddR1">
                  <div className="ddRspan">Cylinder Weight </div>
                  <div className="ddRValue ddrStart">
                    <span>{deliverArr?.cylinderWeight ? deliverArr?.cylinderWeight : "-"}</span>
                  </div>
                </div>
                <div className="ddR1">
                  <div className="ddRspan">Rating </div>
                  <div className="ddRValue ddrStart">
                    <span>
                      {deliverArr?.feedbackObj?.ratingNumber
                        ? [1, 2, 3, 4, 5]
                            .filter((el) => el <= Number(deliverArr?.feedbackObj?.ratingNumber))
                            ?.map(
                              (index) =>
                                (
                                  <span key={index} className={`star selected`} style={{ fontSize: "1.5em" }}>
                                    &#9733;
                                  </span>
                                ) || "-"
                            )
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="in1">
                <div className="ddR1">
                  <div className="ddRspan">FeedBack</div>
                  <div className="ddRValue ddrStart">
                    <span style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                      {deliverArr?.feedbackObj ? (
                        deliverArr.feedbackObj.comment != "" ? (
                          deliverArr.feedbackObj.comment || "-"
                        ) : Array.isArray(deliverArr.feedbackObj.reasons) && deliverArr.feedbackObj.reasons.length > 0 ? (
                          <>
                            {deliverArr.feedbackObj.reasons.map((el, index) => {
                              return (
                                <span key={index} style={{ textAlign: "left" }}>
                                  {index + 1}. {el}
                                </span>
                              );
                            })}
                          </>
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                </div>
                <div className="ddR1"></div>
              </div>
            </div>
            <div className="ddOBottom">
              <span className="EnterpriseSpan">Delivery Person Details</span>
              <div className="in1">
                <div className="ddR1">
                  <div className="ddRspan">Name </div>
                  <div className="ddRValue ddrStart">
                    <b>{completeObj && completeObj.driverDetails ? completeObj.driverDetails.name : "-"}</b>
                  </div>
                </div>
                <div className="ddR1">
                  <div className="ddRspan">Email Id </div>
                  <div className="ddRValue ddrStart">{completeObj && completeObj.driverDetails ? completeObj.driverDetails.email : "-"}</div>
                </div>
              </div>
              <div className="in1">
                <div className="ddR1">
                  <div className="ddRspan">Mobile Number </div>
                  <div className="ddRValue ddrStart">{completeObj && completeObj.driverDetails ? completeObj.driverDetails.mobile : "-"}</div>
                </div>
                <div className="ddR1 ddR1Display" style={{ visibility: "hidden" }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeliveryDetails;
