import { GET_SINGLE_MAP_ROUTE, GET_MULTI_MAP_ROUTE} from './types'


export const getSingleMapRouteList = (mapRoutes) => {
    return {
        type: GET_SINGLE_MAP_ROUTE,
        payload: mapRoutes
    }
}

export const getMultiMapRouteList = (mapRoutes) => {
    return {
        type: GET_MULTI_MAP_ROUTE,
        payload: mapRoutes
    }
}