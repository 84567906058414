import { useEffect, useState } from 'react'
import { getAddress, getRequestList } from '../../state/redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { colourStyles, handlePostApi } from "../../services/functions";
import { CustomViewModal, Input, DropDown, showNotification } from "../Utils/Inputs";
import { Container, Row, Col } from "react-bootstrap";
import { getSettings } from '../../state/redux/actions';


const shapes = [
    { value: 'Box', label: 'Box' },
    { value: 'Cylinder', label: 'Cylinder' }
];

const timeSlots = [
  { label: "12:00 AM", value: "0" },
  { label: "01:00 AM", value: "1" },
  { label: "02:00 AM", value: "2" },
  { label: "03:00 AM", value: "3" },
  { label: "04:00 AM", value: "4" },
  { label: "05:00 AM", value: "5" },
  { label: "06:00 AM", value: "6" },
  { label: "07:00 AM", value: "7" },
  { label: "08:00 AM", value: "8" },
  { label: "09:00 AM", value: "9" },
  { label: "10:00 AM", value: "10" },
  { label: "11:00 AM", value: "11" },
  { label: "12:00 PM", value: "12" },
  { label: "01:00 PM", value: "13" },
  { label: "02:00 PM", value: "14" },
  { label: "03:00 PM", value: "15" },
  { label: "04:00 PM", value: "16" },
  { label: "05:00 PM", value: "17" },
  { label: "06:00 PM", value: "18" },
  { label: "07:00 PM", value: "19" },
  { label: "08:00 PM", value: "20" },
  { label: "09:00 PM", value: "21" },
  { label: "10:00 PM", value: "22" },
  { label: "11:00 PM", value: "23" },
  { label: "12:59 PM", value: "24" },
];

const defaultRequestPayload = {
        address: {
            address: "",
            userName: "",
            geofenceId: "",
            status: "",
            base: "lynkit",
            latitude: "",
            longitude: ""
        },
        userId: "",
        quantity: "",
        unit: "",
        date: "",
        startSlotTime: "",
        endSlotTime: "",
        shapeType: "Cylinder",
        pi : 3.145,
        contactDetail:{}
}

const defaultDriverError = {
    address: "",
    userId: "",
    quantity: "",
    unit: "",
    date: "",
    startSlotTime: "",
    endSlotTime: "",
    shapeType: "",
}

const AddRequest = ({ show, onClose, sharedData }) => {  
    const dispatch = useDispatch()
    const unitListing = useSelector((state) => state.getSetRed.settingslisting);
    const areaListing = useSelector((state) => state.addressListRed.addressListing)
    const areaLoader =  useSelector((state) => state.addressListRed.addressLoader)

    const [requestPayload, setRequestPayload] = useState(defaultRequestPayload);
    const [error, setError] = useState(defaultDriverError);
    const [areaOptions, setAreaOptions] = useState([])


    useEffect(()=> {
        if (areaListing?.length > 0) {
          let options = [];
          areaListing.map((item) => {
            options.push({
              value: item._id,
              label: item.address,
              address: {
                address: item.address,
                userName: item.userName,
                geofenceId: item.geofenceId,
                status: item.status,
                latitude: item.latitude,
                longitude: item.longitude,
              },
              userId: item._id,
              contactDetail: {
                personName: item.contactDetails.personName,
                mobileNo: item.contactDetails.mobileNo,
                email: item.contactDetails.email,
                company: item.company,
              },
            });
          });
          setAreaOptions(options);
        }
    },[areaListing])

    useEffect(() => {
      if (unitListing[0]?.unitType) {
        localStorage.setItem('unit', unitListing[0]?.unitType)
        setRequestPayload({
          ...requestPayload,
          unit: unitListing[0]?.unitType,
        });
      }
    }, [unitListing, show]);

    useEffect(() => {
      if (show) {
        dispatch(
          getAddress({
            searchType: "",
            searchValue: "",
          })
        );
        dispatch(getSettings());
      }
    }, [dispatch, show]);
    

    const handleChange = (e) => {
      // console.log(e.target.name, e.target.value);
      setRequestPayload((prev) => {
        const updatedPayload = { ...prev, [e.target.name]: e.target.value };
        if (updatedPayload.shapeType === "Box") {
          const length = parseFloat(updatedPayload.length) || 0;
          const width = parseFloat(updatedPayload.width) || 0;
          const height = parseFloat(updatedPayload.height) || 0;
          const volume = Math.ceil(length * width * height);

          // Return the updated payload with capacityInKg
          return { ...updatedPayload, volume };
        }
        if (updatedPayload.shapeType === "Cylinder") {
          const radius = parseFloat(updatedPayload.radius) || 0;
          const height = parseFloat(updatedPayload.height) || 0;
          const pi = parseFloat(updatedPayload.pi) || 3.145;
          const volume = Math.ceil(pi * radius * radius * height);
          return { ...updatedPayload, volume };
        }
        return updatedPayload;
      });
    };

    const handleDropChange = (value, name) => {
      // console.log(name, value);
      setRequestPayload((prev) => {
        const updatedPayload = { ...prev, [name]: value.value };
        if(name === "shapeType" && value.label === "Box") {
          updatedPayload.volume = "";
          updatedPayload.length = "";
          updatedPayload.width = "";
          updatedPayload.height = "";
          updatedPayload.quantity = "";
          delete updatedPayload.radius;
          delete updatedPayload.pi;
        }
        if(name === "shapeType" && value.label === "Cylinder"){
            updatedPayload.volume = "";
            updatedPayload.pi = "3.145";
            updatedPayload.height = "";
            updatedPayload.radius = "";
            updatedPayload.quantity = "";
            delete updatedPayload.length;
            delete updatedPayload.width;
        }
        return updatedPayload;
      });
    };

    const validateRequest = (payload) => {
      let errors = {};

      if (!payload.userId) {
        errors.address = "address is required.";
      }

      if (!payload.unit) {
        errors.unit = "Unit is required.";
      }
      if (!payload.date) {
        errors.date = "Date is required.";
      }

      if(!payload.startSlotTime){
        errors.startSlotTime = "Time Slot is required.";
      }
      if(!payload.endSlotTime){
        errors.endSlotTime = "Time Slot is required.";
      }
      if (!payload.shapeType) {
        errors.shapeType = "Shape is required.";
      }
      if (payload.shapeType === "Box"){
        if(!payload.length){
            errors.length = "Length is required.";
        }
        if(!payload.width){
            errors.width = "Width is required.";
        }
        if(!payload.height){
            errors.height = "Height is required.";
        }
      }
      if (payload.shapeType === "Cylinder"){
        if(!payload.radius){
            errors.radius = "Radius is required.";
        }
        if(!payload.height){
            errors.height = "Height is required.";
        }
        if(!payload.pi){
            errors.pi = "PI is required.";
        }

      }
      if (!payload.quantity) {
        errors.quantity = "Quantity is required.";
      }

      setError(errors);

      return errors;
    };

    const handleAddRequest = async() => {
      // console.log(requestPayload);
      const errors = validateRequest({ ...requestPayload });
      if (Object.keys(errors).length > 0) {
        // console.error("Validation errors:", errors);
        return;
      }
      try {
        const result = await handlePostApi("/request/addRequest", { ...requestPayload });
        // console.log("api result====>>>>", result);
        if (result.success) {
          showNotification({message: result.message, title: "Success!!", autohide: true, showInput: false});
          dispatch(
            getRequestList({
              page: 1,
              limit: 10,
              searchType: "",
              searchValue: "",
            })
          );
          setError(defaultDriverError)
          setRequestPayload(defaultRequestPayload)
          onClose();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleUpdateRequest = async () => {
        // console.log('update', requestPayload);
        try {
            let payload = { updateDetails: requestPayload, _id: sharedData.id };
            const result = await handlePostApi("/request/editRequest", { ...payload });
            // console.log("api result====>>>>", result);
            if (result.success) {
              showNotification({message: result.message, title: "Success!!", autohide: true, showInput: false});
              dispatch(
                getRequestList({
                  page: sharedData.pageCount,
                  limit: sharedData.pageLimit,
                  searchType: "",
                  searchValue: "",
                })
              );
              setError(defaultDriverError)
              setRequestPayload(defaultRequestPayload)
              onClose();
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
    }

    return (
      <CustomViewModal
        heading={sharedData.id ? "Edit Request" : "Add Request"}
        show={show}
        onClose={() => {
          setError(defaultDriverError)
          setRequestPayload(defaultRequestPayload);
          onClose();
        }}
        size={"lg"}
      >
        <Container fluid className="">
          <Row className="addBg p-2 mt-2">
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center" style={{ flexWrap: "nowrap" }}>
                <span className="custSpan" style={{ width: "200px" }}>
                  Select Address<span className="asterick"> * </span> :{" "}
                </span>
                <div style={{ width: "-webkit-fill-available" }}>
                  <DropDown
                    options={areaOptions}
                    error={error.address}
                    isLoading={areaLoader}
                    value={areaOptions.find((el) => el.value === requestPayload.userId) || null} // Add value prop to bind selected options
                    onChange={(selectedOptions) => {
                      // console.log('selectedareaOptions', selectedOptions);
                      setRequestPayload({
                        ...requestPayload,
                        address: { ...requestPayload.address, ...selectedOptions.address },
                        contactDetail: { ...selectedOptions.contactDetail },
                        userId: selectedOptions.userId,
                      });
                    }}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2 addBg p-2">
            <Col>
              <Row>
                <Col className="">
                  <Row className="px-2 d-flex justify-content-between align-items-center">
                    <span className="custSpan">Customer Name : </span>
                    <Input readOnly value={requestPayload.contactDetail.personName || ""} />
                  </Row>
                </Col>
                <Col>
                  <Row className="px-2 d-flex justify-content-between align-items-center">
                    <span className="custSpan">Mobile No : </span>
                    <Input readOnly value={requestPayload.contactDetail.mobileNo || ""} />
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="">
                  <Row className="px-2 d-flex justify-content-between align-items-center">
                    <span className="custSpan">Email Id : </span>
                    <Input readOnly value={requestPayload.contactDetail.email || ""} />
                  </Row>
                </Col>
                <Col>
                  <Row className="px-2 d-flex justify-content-between align-items-center">
                    <span className="custSpan">Latitude : </span>
                    <Input readOnly value={requestPayload.address.latitude || ""} />
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="">
                  <Row className="px-2 d-flex justify-content-between align-items-center">
                    <span className="custSpan">Longitude : </span>
                    <Input readOnly value={requestPayload.address.longitude || ""} />
                  </Row>
                </Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2 addBg p-2">
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Unit<span className="asterick"> * </span> :{" "}
                </span>
                <Input readOnly error={error.unit} value={requestPayload.unit || ""} />
              </Row>
            </Col>
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Date<span className="asterick"> * </span> :{" "}
                </span>
                <Input type="date" error={error.date} name={"date"} min={new Date().toISOString().split("T")[0]} value={requestPayload.date} onChange={(e) => handleChange(e)} />
              </Row>
            </Col>
          </Row>
          <Row className="mt-2 addBg p-2">
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Time Slot<span className="asterick"> * </span> :{" "}
                </span>
                <div style={{ width: "200px", display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "95px" }}>
                    <DropDown
                      onChange={(value) => handleDropChange(value, "startSlotTime")}
                      options={timeSlots}
                      styles={colourStyles}
                      error={error.startSlotTime && error.endSlotTime ? error.startSlotTime : error.startSlotTime}
                      value={timeSlots.find((el) => el.value === requestPayload.startSlotTime) || null} // Add value prop to bind selected options
                    />
                  </div>
                  <div style={{ width: "95px" }}>
                    <DropDown
                      onChange={(value) => handleDropChange(value, "endSlotTime")}
                      options={timeSlots}
                      error={error.startSlotTime && error.endSlotTime ? true : error.endSlotTime}
                      styles={colourStyles}
                      value={timeSlots.find((el) => el.value === requestPayload.endSlotTime) || null} // Add value prop to bind selected options
                    />
                  </div>
                </div>
              </Row>
            </Col>
            <Col></Col>
          </Row>
          <Row className="addBg mt-2 p-2 d-flex justify-content-around align-items-center">
            <Col xs="5">
              <Row className="px-2  d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Shape Type<span className="asterick"> * </span> :{" "}
                </span>
                <div style={{ width: "200px" }}>
                  <DropDown
                    onChange={(value) => handleDropChange(value, "shapeType")}
                    options={shapes}
                    styles={colourStyles}
                    error={error.shapeType}
                    value={shapes.find((el) => el.value === requestPayload.shapeType) || null} // Add value prop to bind selected options
                  />
                </div>
              </Row>
            </Col>
          </Row>
          {requestPayload.shapeType &&
            (requestPayload.shapeType === "Box" ? (
              <>
                <Row className="mt-2 addBg p-2">
                  <Col className="">
                    <Row className="px-2 d-flex justify-content-between align-items-center">
                      <span className="custSpan">
                        Length( cm )<span className="asterick"> * </span> :{" "}
                      </span>
                      <Input name={"length"} error={error.length} value={requestPayload.length || ""} onChange={(e) => handleChange(e)} />
                    </Row>
                  </Col>
                  <Col>
                    <Row className="px-2 d-flex justify-content-between align-items-center">
                      <span className="custSpan">
                        Width( cm )<span className="asterick"> * </span> :{" "}
                      </span>
                      <Input name={"width"} error={error.width} value={requestPayload.width || ""} onChange={(e) => handleChange(e)} />
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-2 addBg p-2">
                  <Col className="">
                    <Row className="px-2 d-flex justify-content-between align-items-center">
                      <span className="custSpan">
                        Height( cm )<span className="asterick"> * </span> :{" "}
                      </span>
                      <Input name={"height"} error={error.height} value={requestPayload.height || ""} onChange={(e) => handleChange(e)} />
                    </Row>
                  </Col>
                  <Col>
                    <Row className="px-2 d-flex justify-content-between align-items-center">
                      <span className="custSpan">
                        Quantity<span className="asterick"> * </span> :{" "}
                      </span>
                      <Input name={"quantity"} error={error.quantity} value={requestPayload.quantity || ""} onChange={(e) => handleChange(e)} />
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="mt-2 addBg p-2">
                  <Col className="">
                    <Row className="px-2 d-flex justify-content-between align-items-center">
                      <span className="custSpan">
                        Height (cm)<span className="asterick"> * </span> :{" "}
                      </span>
                      <Input name={"height"} error={error.height} value={requestPayload.height || ""} onChange={(e) => handleChange(e)} />
                    </Row>
                  </Col>
                  <Col>
                    <Row className="px-2 d-flex justify-content-between align-items-center">
                      <span className="custSpan">
                        Radius (cm) <span className="asterick"> * </span> :{" "}
                      </span>
                      <Input name={"radius"} error={error.radius} value={requestPayload.radius || ""} onChange={(e) => handleChange(e)} />
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-2 addBg p-2">
                  <Col className="">
                    <Row className="px-2 d-flex justify-content-between align-items-center">
                      <span className="custSpan">
                        Pi (cm)<span className="asterick"> * </span> :{" "}
                      </span>
                      <Input readOnly error={error.pi} value={requestPayload.pi || ""} />
                    </Row>
                  </Col>
                  <Col>
                    <Row className="px-2 d-flex justify-content-between align-items-center">
                      <span className="custSpan">
                        Quantity<span className="asterick"> * </span> :{" "}
                      </span>
                      <Input name={"quantity"} error={error.quantity} value={requestPayload.quantity || ""} onChange={(e) => handleChange(e)} />
                    </Row>
                  </Col>
                </Row>
              </>
            ))}
          {true && (
            <Row className="addBg mt-2 p-2 d-flex justify-content-around align-items-center">
              <Col xs="3">
                <Row className="px-2 d-flex justify-content-around align-items-center">
                  <span className="custSpan">
                    Volume ( cm <sup>3</sup> ) -
                  </span>
                  <span className="vehVol">
                    {requestPayload.volume ? requestPayload.volume : ""}
                    &nbsp;cm <sup>3</sup>
                  </span>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="mt-3 p-2 d-flex justify-content-around align-items-center">
            <button
              className="customBtn"
              onClick={() => {
                if (sharedData.id) {
                  handleUpdateRequest();
                } else {
                  handleAddRequest();
                }
              }}
            >
              {sharedData.id ? "Edit Request" : "Add Request"}
            </button>
          </Row>
        </Container>
      </CustomViewModal>
    );
}

export default AddRequest;

