import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import { colourStyles } from "../../services/functions";
import "./Utils.css"


const RadioButton = ({ label, name, value, checked, onChange }) => {
  return (
    <label className="custom-radio-button">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="custom-radio-button__input"
        style={{marginTop : '10px'}}
      />
      <span className="custom-radio-button__checkmark"></span>
      {label}
    </label>
  );
};


const Checkbox = ({ checked, onChange, label, sx }) => {
  return (
      <label style={{...sx}} className="custom-new-checkbox">
          <input
              type="checkbox"
              checked={checked}
              onChange={onChange}
              className="checkbox-input"
          />
          <span className="checkbox-mark"></span>
          {label}
      </label>
  );
};
function Input({
  id = "",
  name,
  value,
  onChange,
  type = "text",
  label = "",
  placeholder = "",
  min = "",
  max = "",
  step = "",
  readOnly = false,
  disabled = false,
  required = false,
  children,
  sx = {},
  error = "",
}) {
  return (
    <div>
      {label && (
        <label htmlFor={id || name} style={{ display: "block", marginBottom: "0.5rem" }}>
          {label}
        </label>
      )}
      <input
        id={id || name}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        min={min}
        max={max}
        step={step}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
        className={`form-control inputCustom ${error ? "is-invalid" : ""}`}
        style={{ ...sx }}
      />
      {children}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

function DropDown({
    id = "",
    name,
    value,
    onChange,
    options = [],
    label = "",
    placeholder = "Select...",
    isDisabled = false,
    isMulti = false,
    isClearable = false,
    onMenuScrollToBottom,
    required = false,
    height="",
    error = "",
    sx = {},
  }) {
    return (
      <div>
        {label && (
          <label
            htmlFor={id || name}
            style={{ display: "block", marginBottom: "0.5rem" }}
          >
            {label}
          </label>
        )}
        <Select
          id={id || name}
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isClearable={isClearable}
          menuPlacement="auto"
          onMenuScrollToBottom={onMenuScrollToBottom}
          styles={{
            ...colourStyles,
            control: (provided) => ({
              ...provided,
              ...colourStyles.control,
              height: height ? height : provided.height,
              borderColor: error ? "red" : provided.borderColor,
              borderRadius: error ? "7px" : provided.borderRadius,
              borderWidth: error ? "2px" : provided.borderWidth         
            }),
          }}
        />
        {error && <div className="invalid-feedback" style={{display : "block"}}>{error}</div>}
      </div>
    );
}

function InputSearch({
  id = "",
  name,
  value,
  onChange,
  onSubmit,
  type = "text",
  label = "",
  placeholder = "",
  min = "",
  max = "",
  step = "",
  readOnly = false,
  disabled = false,
  buttonDisabled=false,
  required = false,
  sx = {},
  error = "",
}) {
  return (
    <div>
      {label && (
        <label htmlFor={id || name} style={{ display: "block", marginBottom: "0.5rem" }}>
          {label}
        </label>
      )}
      <span className={`inputCustom-group ${error ? "is-invalid" : ""}`}>
        <input
          id={id || name}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          min={min}
          max={max}
          step={step}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          className={`inputSearchCustom ${error ? "is-invalid" : ""}`}
          style={{ ...sx }}
        />
        <button onClick={onSubmit} disabled={buttonDisabled} className="btn btn-orange">
          <i className="fa fa-search"></i>
        </button>
      </span>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

function InputSearchDropDown({
  id = "",
  name,
  value,
  onChange,
  onSelectChange,
  onSubmit,
  type = "text",
  label = "",
  placeholder = "",
  min = "",
  max = "",
  step = "",
  readOnly = false,
  disabled = false,
  required = false,
  selectOptions = [],
  selectValue = "",
  sx = {},
  error = "",
  onClear, // New prop to handle clear action
}) {
  return (
    <div style={{ marginBottom: "1rem", position: "relative" }}>
      {label && (
        <label htmlFor={id || name} style={{ display: "block", marginBottom: "0.5rem" }}>
          {label}
        </label>
      )}
      <span className={`inputCustom-group ${error ? "is-invalid" : ""}`}>
        <select className="selectSearchCustom" value={selectValue} onChange={onSelectChange} id={`${id || name}-select`} disabled={disabled} style={{ marginRight: "0.5rem" }}>
          {selectOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div style={{ position: "relative", display: "inline-block"  }}>
          <input
            id={id || name}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            min={min}
            max={max}
            step={step}
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            className={`inputSearchCustom ${error ? "is-invalid" : ""}`}
            style={{ ...sx, width: '150px' }}
          />
          {value && (
            <button
              onClick={(e) => {
                e.preventDefault();
                if (onClear) onClear();
              }}
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              aria-label="Clear input"
            >
              <i className="fa fa-times" style={{ fontSize: "16px", color: "gray" }}></i>
            </button>
          )}
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (onSubmit) onSubmit();
          }}
          className="btn btn-orange"
          // style={{ marginLeft: "0.5rem" }}
        >
          <i className="fa fa-search"></i>
        </button>
      </span>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

function PageNavigation({ selectedLimit, handlePageLimit }) {

  // console.log("pageLimit====>>>>", selectedLimit);
  // const [page, setPage] = useState(10);
  const [selectedOption, setSelectedOption] = useState(10);
  const options = [10, 20, 25, 50];

  useEffect(() => {
    setSelectedOption(selectedLimit);
  }, [selectedLimit]);

  return (
    <div style={{ backgroundColor: "" }} className="page-navigation-container">
      <div className="c">
        <span>Page limit</span>
        <select
          id="drop-down"
          value={selectedOption} // Use value prop here
          onChange={(e) => {
            handlePageLimit(Number(e.target.value));
            setSelectedOption(Number(e.target.value));
          }}
          className="drop-down-page-navigation"
        >
          {options.map((item) => (
            <option className="page-numbers" value={item} key={`page_${item}`}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

function CustomPagination({ activePage = 1, pageLimit = 10, setPageLimit = 20, count = 1, handlePagination }) {
  const [pages, setPages] = useState([1, 2, 3]);
  // const [activePage, setActivePage] = useState(1)

  // console.log('table count', count)
  // console.log('pageLimit===newww', pageLimit) 
  useEffect(() => {
    let output = Array.from({ length: count }, (_, index) => index + 1);
    setPages(output.slice(0, 3));
  }, [count]);

  const next = () => {
    if (activePage <= count - 1) {
      if (pages[2] < count) {
        let result = pages.map((item) => {
          if (item + 1 <= count) {
            return item + 1;
          }
        });
        setPages(result);
      }
      // console.log('next page', count, activePage + 1)
      handlePagination(activePage + 1, undefined);
    }
  };

  const Previous = () => {
    if (activePage > 1) {
      if (pages[0] > 1) {
        let result = pages.map((item) => {
          if (item - 1 <= count) {
            return item - 1;
          }
        });
        setPages(result);
      }
      // console.log('previous page', count, activePage - 1)
      handlePagination(activePage - 1, undefined);
    }
  };

  const handlePageLimit = (page) => {
    // alert(page)
    // setActivePage(page)
    handlePagination(undefined, page);
  };

  return (
    <>
      <tr className="pagination-container">
        <td component="th" scope="row" colSpan={100}>
          <div className="mt-2 d-flex justify-content-center">
            <nav aria-label="Page navigation example">
              <ul className="pagination pagination-sm justify-content-center">
                <li className={`page-item ${activePage === 1 ? "disabled" : ""}`} onClick={() => Previous()}>
                  <a className="page-link" aria-disabled="true">
                    Previous
                  </a>
                </li>
                {pages.map((item, index) => (
                  <li key={index} className={`page-item ${activePage === item ? "active" : ""}`} aria-current="page" onClick={() => handlePagination(item)}>
                    <a className="page-link">{item}</a>
                  </li>
                ))}
                <li className={`page-item ${activePage === count ? "disabled" : ""}`} onClick={() => next()}>
                  <a className="page-link">Next</a>
                </li>
              </ul>
            </nav>
            <div style={{ marginLeft: "1rem" }}>
              <PageNavigation selectedLimit={pageLimit} handlePageLimit={(pageLimit) => handlePageLimit(pageLimit)} />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

function CustomTable({ header, rows, activePage, pageLimit, count, children, handlePagination, loading = false, customErrorMessage, rowStyleCondition=null }) {
  // console.log('pageLimit', pageLimit)
  return (
    <>
      <div className="generic-table-wrapper mt20">
        <table className="generic-table">
          <thead className="bg-accent">
            <tr>
              {header.map((head, i) => {
                return <th key={i}>{head}</th>;
              })}
            </tr>
          </thead>
          <tbody className="bg-gray-100">
            {loading ? (
              <tr>
                <td colSpan={100}>
                  <div className="d-flex justify-content-center align-items-center text-center">
                    <div className="spinner"></div> {/* Add spinner style */}
                    Loading...
                  </div>
                </td>
              </tr>
            ) : rows.length > 0 ? (
              rows.map((row, i) => (
                <tr key={i} style={rowStyleCondition ? rowStyleCondition(i) : {}}>
                  {row.map((col, j) => {
                    return (
                      <td key={`${i}-${j}`} className={j === row.length - 1 ? "last_col" : ""}>
                        {col}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td component="th" scope="row" colSpan={100}>
                  <div className="text-center">{customErrorMessage || 'No Data Found'}</div>
                </td>
              </tr>
            )}
            {children && (
              <tr className="p-0">
                <td className="p-0" component="th" scope="row" colSpan={100}>
                  {children}
                </td>
              </tr>
            )}

            {handlePagination && <CustomPagination pageLimit={pageLimit} activePage={activePage} count={count} handlePagination={handlePagination} />}
          </tbody>
        </table>
      </div>
    </>
  );
}

function CustomViewModal({ size, onClose, show, heading, children }) {
	const [open, setOpen] = useState(false);
	const handleClose = (e) => {
		setOpen(!open);
		onClose(false);
	};
	useEffect(() => {
		setOpen(show);
	}, [show]);
	return (
		<div className="model_container">
			<Modal
				show={open}
				onHide={handleClose}
				centered={true}
				size={size}
				scrollable={true}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header className="p-0 px-4 model_header" closeButton>
					<Modal.Title className="py-2">
						<h4>{heading}</h4>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
				<Modal.Footer className="p-2">
                    
                </Modal.Footer>
			</Modal>
		</div>
	);
}

const showNotification = (options, callback) => {
    window.$.fn.show_notification(
      {
        message: options.message,
        title: options.title || "Message!!",
        type: options.type || "success", // success, error, warning, info, confirm
        autohide: options.autohide ?? true,
        showInput: options.showInput ?? false,
      },
      (result) => {
        if (callback) callback(result);
      }
    );
};

Input.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  min: "",
  max: "",
  step: "",
  readOnly: false,
  disabled: false,
  required: false,
  sx: {},
  error: "",
};

export { Input, RadioButton, Checkbox, DropDown, InputSearch, InputSearchDropDown, CustomTable, CustomViewModal, showNotification };
