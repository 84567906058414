import { TRIP_LISTING, SHOW_LOADER, HIDE_LOADER , TRIP_MSSG , TRIP_COUNT} from './types'
import { post , logoutOnExpiry} from './../../../services/requests'

export const getTrips = (object) => {
    return (dispatch) => {
        dispatch(showLoader())
        post('trip/getTrip', { ...object })
            .then(response => {
                const tripLists = response.data !== undefined ? response.data : []
                const status = response.success
                const count = response.count
                const message = response && response.message ? response.message : ''
                if (status === true) {
                    const points = tripLists.map((a, b) => { return (a) })
                    // for (let num = 0; num < points.length; num++) {
                    //     points[num].locations.sort((a, b) => {
                    //         return a.waypoint_index - b.waypoint_index;
                    //     });
                    // }
                    dispatch(tripsMessage(''))
                    dispatch(getTripLists(points))
                    dispatch(getCount(count))
                    dispatch(hideLoader())
                }
                else{
                    dispatch(tripsMessage(message))
                    dispatch(getTripLists([]))
                    dispatch(hideLoader())
                }
            })
            .catch(error => {
                dispatch(tripsMessage('Some Error Occured.'))
                dispatch(hideLoader())
                if (error?.error?.status === 401) {
                    logoutOnExpiry()
                }
                // console.log('error', error);
            })
    }
}


export const getTripLists = (points) => {
    return {
        type: TRIP_LISTING,
        payload: points
    }
}
export const showLoader = () =>{
    return{
        type : SHOW_LOADER,
    }
}
export const hideLoader = () =>{
    return{
        type : HIDE_LOADER,
    }
}
export const tripsMessage = (mssg) =>{
    return{
        type : TRIP_MSSG,
        payload : mssg
    }
}
export const getCount = (count) =>{
    return{
        type : TRIP_COUNT,
        payload : count
    }
}


const dummyData = {
    "data": [
        {
            "_id": "67a5a1c15b55a3ef2d3190f1",
            "userId": "63f762d9a62a978fd387e9d5",
            "base": "lynkit",
            "tripId": "TR1380",
            "dateOfTrip": "2025-02-07T00:00:00.000Z",
            "vehicle": {
                "vehicle_id": "66a207c7ed51decd054d2be3",
                "vehicleType": "CYCLE or TWO-WHEELER ",
                "vehicleNo": "UP16DK1001",
                "driverDetails": {
                    "_id": "66b22ca6e45ad87258d16de7",
                    "email": "test@gmail.com",
                    "mobile": "9455125992",
                    "name": "Arvind Kumar"
                }
            },
            "locations": [
                {
                    "location": [],
                    "waypoint_index": 0,
                    "locationDetails": {
                        "latitude": 26.794164196530403,
                        "longitude": 80.91695414949027,
                        "address": "MAMTA GAS SERVICE ADDRESS (Lucknow)",
                        "eta": 522
                    },
                    "locationType": "source",
                    "deliveryInstruction": "asasa",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 1,
                    "locationDetails": {
                        "distributorId": "115686",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-19T18:30:00.000Z",
                        "orderId": "16012028046",
                        "status": true,
                        "consumerId": "44560584",
                        "_id": "678fae3587b137750059b0a6",
                        "latitude": 26.799217170255265,
                        "longitude": 80.91822233051062,
                        "address": "J-79 SECT-J AASIYANA COLONY LDA COLON LUCKNOW UTTAR PRADES Utta LUCKNOW 226012",
                        "email": "0",
                        "mobile": "7007295704",
                        "personName": "MRS SHWETA SINGH",
                        "areaId": "87461",
                        "paymentMode": "Cash on Delivery",
                        "eta": 1118,
                        "serviceTime": 600,
                        "arrival": "2025-02-07T02:38:46.696000"
                    },
                    "location": [
                        80.91822233051062,
                        26.799217170255265
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-07T02:38:46.696000",
                    "locationDepartureTime": "2025-02-07T02:55:13.696000",
                    "deliveryLocation": {
                        "latitude": 28.5256211,
                        "longitude": 77.2754925
                    },
                    "deliveryViolation": false,
                    "isReached": true,
                    "time": "2025-02-07T06:08:25.718Z",
                    "cylinderWeight": "",
                    "deliveryTime": "2025-02-07T06:09:02.290Z",
                    "filePath": "",
                    "isDelivered": true,
                    "isOTP": true,
                    "leakCheck": false,
                    "qrCodeValue": "QR code not scanned",
                    "feedbackObj": {
                        "ratingNumber": 1,
                        "reasons": [
                            "Delivery boy not in uniform",
                            "Home Delivery Not done",
                            "Delivery boy demanded more money than selling Price",
                            "Bad Behaviour by delivery boy",
                            "Leakage from LPG cylinder",
                            "Weight of LPG in cylinder less than 14.2 kg"
                        ],
                        "comment": ""
                    },
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 2,
                    "locationDetails": {
                        "distributorId": "115686",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-19T18:30:00.000Z",
                        "orderId": "16012028038",
                        "status": true,
                        "consumerId": "44558794",
                        "_id": "678fae3587b137750059b09e",
                        "latitude": 26.794562011063533,
                        "longitude": 80.92103160917759,
                        "address": "B-37 SECT. L LDA COLONYLUCKNOW 0",
                        "email": "0",
                        "mobile": "9839750538",
                        "personName": "S.G. SHUKLA",
                        "areaId": "87461",
                        "paymentMode": "Cash on Delivery",
                        "eta": 816,
                        "serviceTime": 600,
                        "arrival": "2025-02-07T02:58:20.696000"
                    },
                    "location": [
                        80.92103160917759,
                        26.794562011063533
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-07T03:01:53.696000",
                    "locationDepartureTime": "2025-02-07T03:20:26.696000",
                    "deliveryLocation": {
                        "latitude": 28.5256211,
                        "longitude": 77.27548
                    },
                    "deliveryViolation": false,
                    "isReached": true,
                    "time": "2025-02-07T07:48:53.704Z",
                    "cylinderWeight": "",
                    "deliveryTime": "2025-02-07T07:49:00.798Z",
                    "filePath": "",
                    "isDelivered": true,
                    "isOTP": false,
                    "leakCheck": false,
                    "qrCodeValue": "QR code not scanned",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 3,
                    "locationDetails": {
                        "distributorId": "115686",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-19T18:30:00.000Z",
                        "orderId": "16012028043",
                        "status": true,
                        "consumerId": "44559510",
                        "_id": "678fae3587b137750059b0a3",
                        "latitude": 26.789279049370567,
                        "longitude": 80.9230462834239,
                        "address": "K1/ 1340 A SECT. K1 LDA COLONY LUCKNOW 2423320 0",
                        "email": "0",
                        "mobile": "8887763928",
                        "personName": "J.C. TRIPATHI (DR.)",
                        "areaId": "87461",
                        "paymentMode": "Cash on Delivery",
                        "eta": 757,
                        "serviceTime": 600,
                        "arrival": "2025-02-07T03:24:11.696000"
                    },
                    "location": [
                        80.9230462834239,
                        26.789279049370567
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-07T03:25:38.696000",
                    "locationDepartureTime": "2025-02-07T03:45:39.696000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 4,
                    "locationDetails": {
                        "distributorId": "115686",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-19T18:30:00.000Z",
                        "orderId": "16012028036",
                        "status": true,
                        "consumerId": "44558618",
                        "_id": "678fae3587b137750059b09c",
                        "latitude": 26.79889156970569,
                        "longitude": 80.91572586447,
                        "address": "EWS 267 SEC-I LDA COLONY KNP RD LUCKNOW 0",
                        "email": "0",
                        "mobile": "9415756170",
                        "personName": "KISHORI",
                        "areaId": "87461",
                        "paymentMode": "Cash on Delivery",
                        "eta": 918,
                        "serviceTime": 600,
                        "arrival": "2025-02-07T03:52:11.696000"
                    },
                    "location": [
                        80.91572586447,
                        26.79889156970569
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-07T03:52:10.696000",
                    "locationDepartureTime": "2025-02-07T04:10:52.696000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "location": [
                        80.8918069,
                        26.7966107
                    ],
                    "locationDetails": {
                        "latitude": 26.7966107,
                        "longitude": 80.8918069,
                        "address": "MAMTA GAS SERVICE ADDRESS (Lucknow)",
                        "eta": 1138
                    },
                    "locationType": "destination",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                }
            ],
            "eta": 4747,
            "tripStatus": "in_transit",
            "isVehicleOverWeight": false,
            "totalQuantity": 4,
            "currentWaypoint": 2,
            "allWaypointCovered": false,
            "origin": {
                "latitude": 26.7966107,
                "longitude": 80.8918069,
                "address": "MAMTA GAS SERVICE ADDRESS (Lucknow)",
                "eta": 522
            },
            "status": true,
            "allAreaIds": [
                "87461"
            ],
            "tripCreationType": "manualAutoTrip",
            "pitStopTime": 240,
            "tripArrivalTime": "2025-02-06T22:40:52.696Z",
            "tripDepartureTime": "2025-02-06T21:00:00.696Z",
            "dayBasedSerialNumber": 1,
            "routeOptimizerRequestName": "RouteOptimiser_115686_63f762d9a62a978fd387e9d5_1738908082380",
            "createdAt": "2025-02-07T06:01:37.456Z",
            "updatedAt": "2025-02-07T08:54:09.713Z",
            "__v": 0,
            "tripStartTime": "2025-02-07T06:02:55.511Z"
        }
    ],
    "count": 1,
    "success": true
}