import React, { useEffect, useState } from "react";
import CustomPageLayout from "../Utils/CustomPageLayout/CustomPageLayout";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { RoutePreview } from "../Utils/MapPreview";
import { generateUniqueHexColors } from "../../services/functions";
import history from '../../services/history';
import moment from "moment";
import axios from 'axios'
import { url } from './../../services/requests';
import './TripManagment.css'

export default function TripMapping() {
  const location = useLocation();
  const { state } = location;
  const [tripDetails, setTripDetails] = useState({});
  const [showListView, setShowListView] = useState(true);
  const [realTimeLocation, setRealTimeLocation] = useState({})
  const color = generateUniqueHexColors(1);
  // console.log("color-state", color, state);

  useEffect(() => {
    if (state) {
      const { locations = [], vehicle } = state; // Destructure locations with a default empty array
      // console.log('state', vehicle)
      const updatedLocations = locations.map((ele) => {
          return {
              ...ele,
              locationDetails: {
                  ...ele.locationDetails,
                  eta:  calculateETA(ele.locationDetails?.eta), // Use optional chaining
              },
          };
      });
  
      // Create a new state object without deep cloning
      const updatedState = {
        ...state,
        locations: updatedLocations,
      };

      axios
        .post(`${url}/track/getLocation`, { vehicleNumber: vehicle.vehicleNo })
        .then((response) => {
          let vehLat = response.data?.data?.latitude || 0;
          let vehLng = response.data?.data?.longitude || 0;
          console.log('location', { latitude: vehLat, longitude: vehLng })
          setRealTimeLocation({ latitude: vehLat, longitude: vehLng });
        })
        .catch((error) => {
          console.log("error", error);
        });
      const intervalId = setInterval(() => {
        axios
          .post(`${url}/track/getLocation`, { vehicleNumber: vehicle.vehicleNo })
          .then((response) => {
            let vehLat = response.data?.data?.latitude|| 0;
            let vehLng = response.data?.data?.longitude || 0;
            console.log('location', { latitude: vehLat, longitude: vehLng })
            setRealTimeLocation({ latitude: vehLat, longitude: vehLng });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }, 30000);
  
      setTripDetails(updatedState);

      return () => clearInterval(intervalId);
  } else {
      setTripDetails({});
      history.goBack();
  }
    
  }, [state]);
  return (
    <CustomPageLayout
      title="Trip Map"
      addTitleButton={
        <span className="d-flex align-items-center">
          <i className="fa fa-arrow-left mt-1"></i>&nbsp; Back
        </span>
      }
      buttonChildern={
        <button className="myMapBtnGrey" onClick={() => setShowListView(!showListView)}>
          {"List View"}
        </button>
      }
      addHandleClick={() => history.goBack()}
    >
      <div style={{marginTop: '-40px'}}>
        <div className="tripTop-container">
          <div className="tripTipbox">
            <span style={{ color: "#fff", fontWeight: "500" }}>Trip ID - </span>
            <span style={{ color: "#fff", fontWeight: "500", paddingLeft: "6px" }}>{tripDetails.tripId ? tripDetails.tripId : '-'}</span>
          </div>
        </div>
        <div style={{ width: "100%", height: "calc(100vh - 230px)" }}>
          <RoutePreview tripId={tripDetails.tripId} routeCoordinates={tripDetails?.locations} realTimeLocation={realTimeLocation}>
            {showListView && (
              <div className="ListView-container">
                <div className="ListView-header">
                  <span className="ListView-header-title">{moment(tripDetails.dateOfTrip).format("DD-MM-YYYY")}&nbsp;TOTAL ETA - ( {`${calculateETA(tripDetails.eta)}`} )</span>
                  <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowListView(!showListView)}></button>
                </div>
                <div className="ListView-body">
                  {tripDetails.locations?.length > 0 && tripDetails.locations.map((ele, index) => (
                    <div key={index} className="route-address-container">
                      {index !== 0 && (
                        <div className="arrow-container">
                          {!ele.isDelivered && <span className="eta-status-left">ETA - {ele?.locationDetails?.eta ? ele.locationDetails?.eta : '0 Minutes'}</span>}
                          <i className="fa fa-arrow-down downArrow"></i>
                          <span className="eta-status-right"></span>
                        </div>
                      )}
                      <div className={`addressCard ${ele.isDelivered ? "delivered" : (ele.isRescheduleForOtherDay === true || ele.isRescheduleForToday === true) ? 'skippedTrip' :  tripDetails.currentWaypoint === ele.waypoint_index ? "card-active" : ""}`}>
                        {index !== 0 && (
                          ele.isDelivered ? (
                            <div className="delivered-status">
                              <span>Delivered At -{moment(ele.deliveryTime).format('DD MMM YYYY hh:mm A')}</span>
                            </div>
                          ) : 
                          (tripDetails.locations?.length - 1 === index ?
                            <span className="pit-delivered-right">Pit Stop Time - {tripDetails?.pitStopTime ? calculateETA(tripDetails.pitStopTime) : '0 Minutes'}</span>
                            :<span className="pit-delivered-right">Service Time - {ele?.locationDetails?.serviceTime ? calculateETA(ele.locationDetails.serviceTime) : '0 Minutes'}</span>)
                        )}
                        <div className="location-mark">{ele.locationType === "source" || ele.locationType === "destination" ? "S." : `${index}.`}</div>
                        <div className="address">
                          <span style={{ textAlign: "center" }}>{ele?.locationDetails?.address || "-"}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="ListView-footer"></div>
              </div>
            )}
          </RoutePreview>
        </div>
      </div>
    </CustomPageLayout>
  );
}

const locationAreEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
  if (arr1?.length !== arr2?.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
}

const calculateETA = (eta) => {
  if (eta) {
    const hours = Math.floor(eta / 3600);
    const minutes = Math.floor((eta % 3600) / 60);
    const seconds = Math.floor(eta % 60);

    let finalTime = "";

    if (hours > 0) {
      finalTime += `${hours} hour `;
    }
    if (minutes > 0 || hours > 0) {
      finalTime += `${minutes} min `;
    }
    finalTime += `${seconds > 0 ? `${seconds}s` : ""}`;

    return finalTime.trim();
  } else {
    return "0 seconds";
  }
};
