import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components';
import reportWebVitals from './reportWebVitals';


import { Provider } from 'react-redux';
import store from './state/store';

// const mode = localStorage.getItem('theme-mode') || 'light';
// const store = createStore({ theme: { mode } });

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// ReactDOM.render(
//   <Provider store={store}>
//       <App />,
//    </Provider>,
//   document.getElementById('root'),
// )

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
      <App />,
   </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
