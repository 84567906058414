import { GET_SINGLE_MAP_ROUTE, GET_MULTI_MAP_ROUTE } from "../actions/types";

const initialState={
    mapSingleRouteListing: [],
    mapMultiRouteListing: []
} 

const getMapRouteRed =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_SINGLE_MAP_ROUTE : return{
            ...state,
            mapSingleRouteListing : [...state.mapSingleRouteListing, payload]
        }
        case GET_MULTI_MAP_ROUTE : return{
            ...state,
            mapMultiRouteListing : [...state.mapMultiRouteListing, payload]
        }
        default: return state;  
    }
}
export default getMapRouteRed ;