import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from './../../static/images/logo.png'
import bharat from './../../static/images/bharatNew.png'
import history from './../../services/history';
import PasswordModal from './Modal/ChangePassword'
import $ from 'jquery'
import jwt_decode from "jwt-decode";


const Header = (props) => {
    const [token, setToken] = useState('')

    const changeRoute = () => {
        history.push('/dashboard/user-profile')
    }
    const openSettings = () => {
        history.push('/dashboard/settings/vehicle-settings')
    }

    const _openModal = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    useEffect(() => {
        if (localStorage.getItem('secretkey') !== '') {
            var getToken = localStorage.getItem('secretkey')
            setToken(getToken)
            var decoded = jwt_decode(getToken);
            localStorage.setItem('name', decoded?.token?.name)
            localStorage.setItem('userType', decoded?.token?.userType)

        }
    }, [token])
    const openSidebar = (e) => {
        e.preventDefault()
    }

    return (
        <header>
            <div className="headerpanel">

                <div className="logopanel">
                    <Link to="/dashboard"><img  src={bharat} alt="logo" /></Link>
                    <a id="menuToggle" className="menutoggle" href="abcd" onClick={(event) => event.preventDefault()}>
                        <i className="fa fa-bars"></i>
                    </a>
                </div>

                <div className="headerbar">
                        <div >
                            <i className="fa fa-bars myBars" id='myBar' onClick={(e) => openSidebar(e)}></i>
                        </div>
                        <div className="header-right">

                            <ul className="headermenu">
                                <li>
                                    <div className="btn-group" >
                                        <button type="button" className="btn btn-logged" data-toggle="dropdown" data-target="#headdp" aria-expanded="false">
                                            {/* <img src={logo} alt="" /> */}
                                            <div className='udeta'>
                                                <span className='n1'>{localStorage.getItem('name')}</span>
                                                <span className='n2'>{localStorage.getItem('email')}</span>
                                                <span className='n3'>{localStorage.getItem('userType')?.toLocaleUpperCase() === 'DRIVER' ? 'DELIVERY MAN' : localStorage.getItem('userType')?.toLocaleUpperCase()}</span>
                                            </div>
                                            <span className="caret"></span>
                                        </button>
                                        <div className="user-dropmenu" id="headdp">
                                            <ul>
                                                <li onClick={changeRoute} ><i className="fa fa-user" aria-hidden="true"></i> Profile</li>
                                                {localStorage.getItem('userType') === 'distributor' ?
                                                    <li onClick={() => { _openModal('changePasswordModal') }} ><i className="fa fa-key" aria-hidden="true"></i> Change Password</li>
                                                    : ''}
                                                {localStorage.getItem('userType') === 'distributor' ?
                                                    <li onClick={openSettings}><i className="fa fa-cog" aria-hidden="true"></i> Setting</li>
                                                    : ''}
                                                <li onClick={props.handleClick}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                  

                </div>
            </div>
            <PasswordModal closeModal={_closeModal} />
        </header>
    )
};

export default Header;