import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from '../../../state/redux/actions/index';
import AddDriver from './AddDriver';
import moment from 'moment';
import TripSettingsModal from './TripSettingsModal'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { handlePostApi } from '../../../services/functions'
import CustomPageLayout from '../../Utils/CustomPageLayout/CustomPageLayout';
import { CustomTable, showNotification } from '../../Utils/Inputs';
import history from '../../../services/history';
import { DriverRes } from '../../Utils/Dummy';


const DriverSettings = () => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getUserRed.usersList)
    const loader = useSelector((state) => state.getUserRed.loading)
    const message = useSelector((state) => state.getUserRed.message)
    const count = useSelector((state) => state.getUserRed.count)

    const [vehicleDetails, setVehicleDetails] = useState(null);
    const [showAddDriver, setShowAddDriver] = useState(false);
    const [showTripSettings, setShowTripSettings] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [loadingViewTrip, setLoadingViewTrip] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    

    const _handleClick = () => {
        setShowAddDriver(true)
    }
    const handlePagination = (page, pageLimitt) => {
        if (page) {
          setPageCount(page);
          dispatch(
            getUsers({
              page: page,
              limit: pageLimit,
              searchType: "",
              searchValue: "",
              userType: "driver",
              type: "driver",
            })
          );
        }
        if (pageLimitt) {
          setPageCount(1);
          setPageLimit(pageLimitt);
          dispatch(
            getUsers({
              page: 1,
              limit: pageLimitt,
              searchType: "",
              searchValue: "",
              userType: "driver",
              type: "driver",
            })
          );
        }
        // console.log("handlePagination", page, pageLimit);
      };

      const handleEdit = (id) => {
        setSelectedDriver(id);
        setShowAddDriver(true);
      };

      const handleTripSetting = (id) => {
        setSelectedDriver(id);
        setShowTripSettings(true);
      };

      const deleteDriver = (id) => {
        showNotification({ message: "Delete this Driver ?", title: "Alert!!", type: "confirm" }, async (result) => {
          if (result.type === "confirm") {
            const response = await handlePostApi("/user/deleteUser", { _id: id });
            if (response.success) {
              showNotification({ message: response.message, title: "Success!!", autohide: true, showInput: false });
              dispatch(
                getUsers({
                  page: pageCount,
                  limit: pageLimit,
                  searchType: "",
                  searchValue: "",
                  userType: "driver",
                  type: "driver",
                })
              );
            }
          }
        });
      };
    
    const fetchData = async (body) => {
        try {
            const result = await handlePostApi('/vehicle/getVehiclesAllocatedToDriver', { ...body });
            if (result.success) {
                setVehicleDetails(result.data.data); // Show popover after data is fetched
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Function to handle vehicle details popover
    const handleVehicleDetails = (details) => {
        if (details._id) {
            fetchData({ "_id": details._id });
        }
    };

    const handleViewTrip = async (data, index) => {
      // console.log('item', data)
      setLoadingViewTrip(index)
      const body = {
        date: formatDateTime(new Date()),
        searchType: "vehicle.driverDetails._id",
        searchValue: data._id,
      };
      try {
        const result = await handlePostApi("/trip/getTrip", { ...body });
        if (result.success) {
          setLoadingViewTrip(null)
          history.push({
            pathname: "/dashboard/trip/preview",
            state: { isSaveTrip : false, clusterArr: result.data },
          });
          // setVehicleDetails(result.data.data); // Show popover after data is fetched
        }else{
          // history.push({
          //   pathname: "/dashboard/trip/preview",
          //   state: { isSaveTrip : false, clusterArr: DriverRes },
          // });
          showNotification({ message: result?.message || 'Something went wrong', type : 'error', title: "Error!!", autohide: true, showInput: false });
          setLoadingViewTrip(null)
        }
      } catch (error) {
        setLoadingViewTrip(null);
        console.error("Error fetching data:", error);
      }
      
    }

    // Create popover content with dynamic vehicle details
    const vehiclePopover = (vehicle) => (
        <Popover id="popover-basic" style={{maxWidth : 'max-content'}}>
            <Popover.Header as="h3">Vehicle Details</Popover.Header>
            <Popover.Body>
                {vehicle && vehicle?.length > 0 ? vehicle.map((el, idx) => (
                    <div key={idx}>
                        <p><strong>Vehicle No.:</strong> {el.vehicleNo}, <strong>Capacity in No.:</strong> {el.capacityInNumber}</p>
                    </div>
                )) : (
                    <p>No vehicle details available.</p>
                )}
            </Popover.Body>
        </Popover>
    );
    const tableHeader = [
      "S. No",
      "Name",
      "Email",
      "Mobile Number",
      "Number of Allocated Vehicle",
      "User  Type",
      "Allocated",
      "Primary Area ID",
      // "Secondary Area ID",
      // "Other Area ID",
      // "Allocated Vehicle", // Uncomment if needed
      // "Vehicle Type",     // Uncomment if needed
      "Trip View",
      "Action",
    ];
    const tableRows =
      (listing &&
        listing.map((item, index) => {
          let items = [];
          items.push(
            <>{(pageCount - 1) * pageLimit + index + 1}</>,
            <>{item.name ? item.name : "-"}</>,
            <>{item.email ? item.email : "-"}</>,
            <>{item.mobile ? item.mobile : "-"}</>,
            <>
              <OverlayTrigger trigger="click" placement="left" overlay={vehiclePopover(vehicleDetails)} rootClose={true}>
                <span style={{ cursor: "pointer", color: "blue", padding: "10px", fontWeight: "bold", textDecoration: "underline", fontSize: "14px" }} onClick={() => handleVehicleDetails(item)}>
                  {item.allocatedVehicleCount ? item.allocatedVehicleCount : "NA"}
                </span>
              </OverlayTrigger>
            </>,
            <>{item.userType === "driver" ? "Delivery Man" : ""}</>,
            <>{item.isAllocated === true ? <span className="bdaSpangreen">True</span> : <span className="bdaSpanred">False</span>}</>,
            <>{item && item.settings && item.settings.areaId ? item.settings.areaId.join(" , ") : "-"}</>,
            // <>{item && item.settings && Array.isArray(item.settings.secondaryAreaId) && item.settings.secondaryAreaId.length > 0 ? item.settings.secondaryAreaId.join(" , ") : "-"}</>,
            // <>
            //   {item && item.settings && Array.isArray(item.settings.othersAreaId) && item.settings.othersAreaId.length > 0 ? (
            //     <>
            //       {/* Display first 12 othersAreaId */}
            //       {item.settings.othersAreaId.slice(0, 12).join(", ")}

            //       {/* If there are more than 12, show the remaining on hover */}
            //       {item.settings.othersAreaId.length > 12 && (
            //         <OverlayTrigger
            //           trigger={["hover", "focus"]}
            //           placement="top"
            //           overlay={
            //             <Popover id="popover-othersAreaId">
            //               <Popover.Body>
            //                 {/* Display the rest of the othersAreaId */}
            //                 {item.settings.othersAreaId.slice(12).join(", ")}
            //               </Popover.Body>
            //             </Popover>
            //           }
            //         >
            //           <span style={{ cursor: "pointer", color: "blue", marginLeft: "4px" }}>...more</span>
            //         </OverlayTrigger>
            //       )}
            //     </>
            //   ) : (
            //     "-"
            //   )}
            // </>,
            <>
              <button style={{ height: "auto" }} className="myMapBtnGrey" onClick={() => handleViewTrip(item, index)}>
                {loadingViewTrip === index ? <span className='py-1 d-flex align-items-center'><i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>&nbsp; <span>Loading...</span></span>  : 'All Today Trips Map View'}
              </button>
            </>,
            <span className="d-flex justify-content-center align-items-center" style={{ gap: "8px" }}>
              <i className="fa fa-cog" style={{ fontSize: "14px", cursor: "pointer" }} onClick={() => handleTripSetting(item._id)}></i>
              <i className="fa fa-pencil-square-o editIcon" onClick={() => handleEdit(item._id)}></i>
              <i className="fa fa-trash trashIcon" onClick={() => deleteDriver(item._id)}></i>
            </span>
          );

          return items;
        })) ||
      [];

    useEffect(() => {
        dispatch(getUsers({
            page: pageCount,
            limit: pageLimit,
            searchType: "",
            searchValue: "",
            userType: "driver",
            type: 'driver'
        }))
    }, [dispatch])


    return (
      <CustomPageLayout
        title="Delivery Man Management"
        addTitleButton="Add Delivery Man"
        addHandleClick={() => {
          _handleClick("Add Delivery Man");
        }}
      >
        <div>
          <CustomTable
            activePage={pageCount}
            pageLimit={pageLimit}
            count={Math.ceil(count / pageLimit)}
            handlePagination={(page, pageLimit) => handlePagination(page, pageLimit)}
            header={tableHeader}
            rows={tableRows ? tableRows : []}
            loading={loader}
          />
        </div>
        <AddDriver
          show={showAddDriver}
          onClose={() => {
            setShowAddDriver(false);
            setSelectedDriver(null);
          }}
          sharedData={{ id: selectedDriver, pageCount, pageLimit }}
        />
        <TripSettingsModal
          show={showTripSettings}
          onClose={() => {
            setShowTripSettings(false);
            setSelectedDriver(null);
          }}
          sharedData={{ id: selectedDriver, pageCount, pageLimit }}
        />
      </CustomPageLayout>

      //     <TripSettingsModal closeModal={_closeModal} dataState={dataState} settingsState={settingsState} />
    );
}
export default DriverSettings;


function formatDateTime(inputDateTime) {
  return moment(inputDateTime)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 }) // Set time to 12:00:00
    .format("YYYY-MM-DD HH:mm:ss"); // Format as "YYYY-MM-DD HH:mm:ss"
}