import { TRIP_LISTING ,SHOW_LOADER, HIDE_LOADER, TRIP_MSSG , TRIP_COUNT } from "../actions/types";

const initialState={
    tripListing: [],
    loading: true,
    message:'',
    count : ''
} 
// dayBasedSerialNumber
const tripListingRed =(state=initialState,{type,payload})=>{
    switch(type){
        case TRIP_LISTING : return{
            ...state,
            tripListing : payload
        }
        case SHOW_LOADER : return{ 
            ...state,
            loading: true
        }
        case HIDE_LOADER : return{ 
            ...state,
            loading:false
        }
        case TRIP_MSSG : return{ 
            ...state,
            message: payload
        }
        case TRIP_COUNT : return{ 
            ...state,
            count: payload
        }
        default: return state;  
    }
}

const tripData = {
    "data": [
        {
            "_id": "679bafea2d0be93747602a29",
            "userId": "63f7631ba62a978fd387ead9",
            "base": "lynkit",
            "tripId": "TR1177",
            "dateOfTrip": "2025-01-30T00:00:00.000Z",
            "vehicle": {
                "vehicle_id": "67125fa5f895caa3905a5095",
                "vehicleType": "CYCLE or TWO-WHEELER ",
                "vehicleNo": "DL20CL2020",
                "driverDetails": {
                    "_id": "671a1592d254f044cac397be",
                    "email": "majorgas@yopmail.com",
                    "mobile": "7277478119",
                    "name": "major gas service"
                }
            },
            "locations": [
                {
                    "location": [
                        80.9978845,
                        26.8881083
                    ],
                    "waypoint_index": 0,
                    "locationDetails": {
                        "latitude": 26.8881083,
                        "longitude": 80.9978845,
                        "address": "Major Mehra Bharat Gas Service, Sector 13, Indira Nagar, Lucknow, Uttar Pradesh, India",
                        "eta": 468
                    },
                    "locationType": "source",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 1,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-21T18:30:00.000Z",
                        "orderId": "22012521040",
                        "status": true,
                        "consumerId": "20355633",
                        "_id": "679238720a8f72449c87320f",
                        "latitude": 26.8798933,
                        "longitude": 80.9926327,
                        "address": "11/14-A ANAD VIHAR INDIRA NAGAR LUCKNOW 226016",
                        "email": "0",
                        "mobile": "7277478115",
                        "personName": "S K DAS",
                        "areaId": "145110",
                        "eta": 297,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T00:34:57.834000"
                    },
                    "location": [
                        80.9926327,
                        26.8798933
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T00:34:57.834000",
                    "locationDepartureTime": "2025-01-30T00:44:57.834000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 2,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-21T18:30:00.000Z",
                        "orderId": "22012521039",
                        "status": true,
                        "consumerId": "20355632",
                        "_id": "679238720a8f72449c87320e",
                        "latitude": 26.8466937,
                        "longitude": 80.94616599999999,
                        "address": "B 259 DIN DAYAL PURAM LUCKNOW 0",
                        "email": "0",
                        "mobile": "7277478115",
                        "personName": "MANGALI",
                        "areaId": "145110",
                        "eta": 920,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T01:10:17.834000"
                    },
                    "location": [
                        80.94616599999999,
                        26.8466937
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T01:10:17.834000",
                    "locationDepartureTime": "2025-01-30T01:20:17.834000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 3,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-21T18:30:00.000Z",
                        "orderId": "22012521025",
                        "status": true,
                        "consumerId": "20355616",
                        "_id": "679238720a8f72449c873200",
                        "latitude": 26.898175,
                        "longitude": 81.0008568,
                        "address": "P No 37K No 14 TAKROHI INDIRA NAGAR LUCKNOW 226016",
                        "email": "0",
                        "mobile": "7277478112",
                        "personName": "D SINGH",
                        "areaId": "145110",
                        "eta": 1377,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T01:53:14.834000"
                    },
                    "location": [
                        81.0008568,
                        26.898175
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T01:53:14.834000",
                    "locationDepartureTime": "2025-01-30T02:03:14.834000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 4,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-21T18:30:00.000Z",
                        "orderId": "22012521021",
                        "status": true,
                        "consumerId": "20355612",
                        "_id": "679238720a8f72449c8731fc",
                        "latitude": 26.8981841,
                        "longitude": 81.0027056,
                        "address": "3A/636/81 TAKROHI BAZAR INDIRA NAGAR LUCKNOW 226016",
                        "email": "0",
                        "mobile": "7277478117",
                        "personName": "RAM NARAIN SRIVASTAVA",
                        "areaId": "145110",
                        "eta": 119,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T02:15:13.834000"
                    },
                    "location": [
                        81.0027056,
                        26.8981841
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T02:15:13.834000",
                    "locationDepartureTime": "2025-01-30T02:25:13.834000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 5,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-21T18:30:00.000Z",
                        "orderId": "22012521027",
                        "status": true,
                        "consumerId": "20355618",
                        "_id": "679238720a8f72449c873202",
                        "latitude": 26.9018236,
                        "longitude": 81.0114656,
                        "address": "38 SURYA CITY TAKROHI LUCKNOW 226016",
                        "email": "0",
                        "mobile": "7277478114",
                        "personName": "SHARMILA",
                        "areaId": "145110",
                        "eta": 358,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T02:41:11.834000"
                    },
                    "location": [
                        81.0114656,
                        26.9018236
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T02:41:11.834000",
                    "locationDepartureTime": "2025-01-30T02:51:11.834000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 6,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-21T18:30:00.000Z",
                        "orderId": "22012521033",
                        "status": true,
                        "consumerId": "20355626",
                        "_id": "679238720a8f72449c873208",
                        "latitude": 26.8994577,
                        "longitude": 81.0131629,
                        "address": "A-2-ADARSH COLONY SEC-11 TAKROHI INDIRA NAGAR LUCKNOW LUCKNOW 226016",
                        "email": "0",
                        "mobile": "7277478116",
                        "personName": "RADHEY SHYAM",
                        "areaId": "145110",
                        "eta": 148,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T03:03:39.834000"
                    },
                    "location": [
                        81.0131629,
                        26.8994577
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T03:03:39.834000",
                    "locationDepartureTime": "2025-01-30T03:13:39.834000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 7,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-21T18:30:00.000Z",
                        "orderId": "22012521030",
                        "status": true,
                        "consumerId": "20355623",
                        "_id": "679238720a8f72449c873205",
                        "latitude": 26.8980167,
                        "longitude": 81.0112453,
                        "address": "634/9 SURAYA CITIY-1 TAKROHI INDIRA NAGAR LUCKNOW 226016",
                        "email": "0",
                        "mobile": "7277478116",
                        "personName": "MALIK RAM SHUKLA",
                        "areaId": "145110",
                        "eta": 108,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T03:25:27.834000"
                    },
                    "location": [
                        81.0112453,
                        26.8980167
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T03:25:27.834000",
                    "locationDepartureTime": "2025-01-30T03:35:27.834000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 8,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-21T18:30:00.000Z",
                        "orderId": "22012521036",
                        "status": true,
                        "consumerId": "20355629",
                        "_id": "679238720a8f72449c87320b",
                        "latitude": 26.8984573,
                        "longitude": 81.0096435,
                        "address": "636/003 K-020B SHAHEED BHAGAT SINGH WARD LUCKNOW 226016",
                        "email": "0",
                        "mobile": "7277478116",
                        "personName": "SMT SAMTRA",
                        "areaId": "145110",
                        "eta": 108,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T03:47:15.834000"
                    },
                    "location": [
                        81.0096435,
                        26.8984573
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T03:47:15.834000",
                    "locationDepartureTime": "2025-01-30T03:57:15.834000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 9,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-21T18:30:00.000Z",
                        "orderId": "22012521038",
                        "status": true,
                        "consumerId": "20355631",
                        "_id": "679238720a8f72449c87320d",
                        "latitude": 26.8949777,
                        "longitude": 81.00761539999999,
                        "address": "636/34 GFLOOR ANAND VIHAR COLONY TAKROH LUCKNOW Uttar Pradesh INDIRA NAGAR 226016",
                        "email": "0",
                        "mobile": "7277478115",
                        "personName": "Mr. SANDEEP KUMAR KASHYAP",
                        "areaId": "145110",
                        "eta": 149,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T04:09:44.834000"
                    },
                    "location": [
                        81.00761539999999,
                        26.8949777
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T04:09:44.834000",
                    "locationDepartureTime": "2025-01-30T04:19:44.834000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 10,
                    "locationDetails": {
                        "_id": "679393c58b55b76394f1652d",
                        "quantity": 1,
                        "address": "636/117 PRAGATI NAGAR 636/117 TAKROHI LUCKNOW UTTAR PRADES Utta INDIRA NAGAR 226016",
                        "status": true,
                        "date": "2025-01-22T18:30:00.000Z",
                        "latitude": 26.893225101093915,
                        "longitude": 81.01153369992971,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "personName": "MR. MOHAMMAD SHABBIR",
                        "requestId": "RN-5069",
                        "orderType": "emergency",
                        "eta": 191,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T04:32:55.834000"
                    },
                    "location": [
                        81.01153369992971,
                        26.893225101093915
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T04:32:55.834000",
                    "locationDepartureTime": "2025-01-30T04:42:55.834000",
                    "mainRequests": []
                },
                {
                    "location": [
                        80.9978845,
                        26.8881083
                    ],
                    "locationDetails": {
                        "latitude": 26.8881083,
                        "longitude": 80.9978845,
                        "address": "Major Mehra Bharat Gas Service, Sector 13, Indira Nagar, Lucknow, Uttar Pradesh, India",
                        "eta": 468
                    },
                    "locationType": "destination",
                    "mainRequests": []
                }
            ],
            "eta": 10843,
            "tripStatus": "initiated",
            "isVehicleOverWeight": false,
            "totalQuantity": 10,
            "currentWaypoint": 0,
            "allWaypointCovered": false,
            "origin": {
                "latitude": 26.8881083,
                "longitude": 80.9978845,
                "address": "Major Mehra Bharat Gas Service, Sector 13, Indira Nagar, Lucknow, Uttar Pradesh, India",
                "eta": 405
            },
            "status": true,
            "allAreaIds": [
                "145110"
            ],
            "tripCreationType": "manualAutoTrip",
            "pitStopTime": 600,
            "tripArrivalTime": "2025-01-29T23:20:43.834Z",
            "tripDepartureTime": "2025-01-29T19:00:00.834Z",
            "dayBasedSerialNumber": 25,
            "routeOptimizerRequestName": "RouteOptimiser_1738257376957",
            "createdAt": "2025-01-30T16:59:22.477Z",
            "updatedAt": "2025-01-30T17:16:27.340Z",
            "__v": 0,
            "editRemark": "New order(s) added to the specific trip",
            "editType": "emergency"
        }
    ],
    "count": 1,
    "success": true
}
export default tripListingRed ;