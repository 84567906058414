



export const routeCoordinates =  [
    {
        "location": [
            80.8905518,
            26.7877001
        ],
        "waypoint_index": 0,
        "locationDetails": {
            "latitude": 26.7877001,
            "longitude": 80.8905518,
            "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India"
        },
        "requestId": "1736247029479",
        "mainRequests": []
    },
    {
        "waypoint_index": 1,
        "locationDetails": {
            "value": "677d06a29f43f74d1118d9c0",
            "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(07-01-2025), Mrs MRS  AYSHA(9005700119})",
            "quantity": 1,
            "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
            "status": true,
            "latitude": 26.787006418269588,
            "longitude": 80.89352689447325,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-07T00:00:00.000Z",
            "volume": 4186,
            "email": "saurabh.k@lynkit.in",
            "mobile": "9005700119",
            "personName": "Mrs MRS  AYSHA",
            "eta": 728,
            "arrival": "2025-01-07T00:31:56.767000",
            "orderNo": "",
            "requestTime": ""
        },
        "location": [
            80.89352689447325,
            26.787006418269588
        ],
        "requestId": "1736247029482",
        "deliveryLocation": {
            "latitude": 28.5256022,
            "longitude": 77.2754624
        },
        "deliveryViolation": false,
        "isReached": true,
        "time": "2025-01-07T10:50:57.358Z",
        "deliveryTime": "2025-01-07T11:09:09.249Z",
        "isDelivered": true,
        "isOTP": true,
        "qrCodeValue": "https://lpgpfs.bpcl.in?id=060923591390345",
        "mainRequests": []
    },
    {
        "waypoint_index": 2,
        "locationDetails": {
            "value": "677d065f9f43f74d1118d996",
            "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(07-01-2025), Mrs SONI  KANAUJIYA(7388373721})",
            "quantity": 1,
            "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
            "status": true,
            "latitude": 26.786613739938698,
            "longitude": 80.893661004924,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-07T00:00:00.000Z",
            "volume": 4186,
            "email": "saurabh@yopmail.com",
            "mobile": "7388373721",
            "personName": "Mrs SONI  KANAUJIYA",
            "eta": 616,
            "arrival": "2025-01-07T00:42:08.767000",
            "orderNo": "",
            "requestTime": ""
        },
        "location": [
            80.893661004924,
            26.786613739938698
        ],
        "requestId": "1736247029484",
        "deliveryLocation": {
            "latitude": 28.5256022,
            "longitude": 77.2754624
        },
        "deliveryViolation": false,
        "isReached": true,
        "time": "2025-01-07T11:10:27.605Z",
        "mainRequests": []
    },
    {
        "waypoint_index": 3,
        "locationDetails": {
            "value": "677d067f9f43f74d1118d9ab",
            "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(07-01-2025), RAJ KISHOR SHARMA(9140421669})",
            "quantity": 1,
            "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
            "status": true,
            "latitude": 26.78675261413783,
            "longitude": 80.89349470796508,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-07T00:00:00.000Z",
            "volume": 4186,
            "email": "saurabh.k@lynkit.in",
            "mobile": "9140421669",
            "personName": "RAJ KISHOR SHARMA",
            "eta": 610,
            "arrival": "2025-01-07T00:52:14.767000",
            "orderNo": "",
            "requestTime": ""
        },
        "location": [
            80.89349470796508,
            26.78675261413783
        ],
        "requestId": "1736247029486",
        "mainRequests": []
    },
    {
        "location": [
            80.8905518,
            26.7877001
        ],
        "waypoint_index": 0,
        "locationDetails": {
            "latitude": 26.7877001,
            "longitude": 80.8905518,
            "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India"
        },
        "requestId": "1736247029479",
        "mainRequests": []
    },
  ]


export const payload = {
    "dateOfTrip": "2025-01-15",
    "vehicle": [
        "66a207c7ed51decd054d2be3",
        "67877ba8aea7603292975cd2"
    ],
    "locations": [
        {
            "value": "67877d1daea7603292975d99",
            "label": "426/457 SABA MANZIL ,NEW WAZEER BAGH SAADAT GA ,LUCKNOW UTTAR PRADES Utta ,LUCKNOW , PIN-226003(15-01-2025), MR GHAUSAL AZAM PASHA(9450757853})",
            "quantity": 1,
            "address": "426/457 SABA MANZIL ,NEW WAZEER BAGH SAADAT GA ,LUCKNOW UTTAR PRADES Utta ,LUCKNOW , PIN-226003",
            "status": true,
            "latitude": 26.8628331,
            "longitude": 80.886203,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-15T00:00:00.000Z",
            "volume": 4186,
            "email": "saurabh.k@lynkit.in",
            "mobile": "9450757853",
            "personName": "MR GHAUSAL AZAM PASHA"
        },
        {
            "value": "67877d08aea7603292975d89",
            "label": "356/75  , SAADATGANJ ,LUCKNOW UTTAR PRADES Utta ,LUCKNOW , PIN-226017(15-01-2025), MR MOHD ARIF(9936237492})",
            "quantity": 1,
            "address": "356/75  , SAADATGANJ ,LUCKNOW UTTAR PRADES Utta ,LUCKNOW , PIN-226017",
            "status": true,
            "latitude": 26.875759402058833,
            "longitude": 80.87898597121239,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-15T00:00:00.000Z",
            "volume": 4186,
            "email": "Saurabh1@Yopmail.Com",
            "mobile": "9936237492",
            "personName": "MR MOHD ARIF"
        },
        {
            "value": "67877cf5aea7603292975d79",
            "label": "430/21 GROUND FLOOR ,BAIDAN TOLA  MAUZZAM NAGA ,LUCKNOW UTTAR PRADES Utta ,LUCKNOW , PIN-226003(15-01-2025), MR  MOHD  NASIM(9369973981})",
            "quantity": 1,
            "address": "430/21 GROUND FLOOR ,BAIDAN TOLA  MAUZZAM NAGA ,LUCKNOW UTTAR PRADES Utta ,LUCKNOW , PIN-226003",
            "status": true,
            "latitude": 26.85738021448771,
            "longitude": 80.88698633015156,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-15T00:00:00.000Z",
            "volume": 4186,
            "email": "saurabh.k@lynkit.in",
            "mobile": "9369973981",
            "personName": "MR  MOHD  NASIM"
        },
        {
            "value": "67877ce0aea7603292975d69",
            "label": "369/B-3/21 BIBI GANJ ,SAADAT GANJ ,LUCKNOW , , PIN-226003(15-01-2025), SUMER CHANDRA(8957381082})",
            "quantity": 1,
            "address": "369/B-3/21 BIBI GANJ ,SAADAT GANJ ,LUCKNOW , , PIN-226003",
            "status": true,
            "latitude": 26.8562606,
            "longitude": 80.8867322,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-15T00:00:00.000Z",
            "volume": 4186,
            "email": "saurabh.k@lynkit.in",
            "mobile": "8957381082",
            "personName": "SUMER CHANDRA"
        },
        {
            "value": "67877cb5aea7603292975d54",
            "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(15-01-2025), Mrs NURIN  FATIMA(8115862605})",
            "quantity": 1,
            "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
            "status": true,
            "latitude": 26.78688669941022,
            "longitude": 80.8939131325714,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-15T00:00:00.000Z",
            "volume": 4186,
            "email": "saurabh@yopmail.com",
            "mobile": "8115862605",
            "personName": "Mrs NURIN  FATIMA"
        },
        {
            "value": "67877c8eaea7603292975d44",
            "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(15-01-2025), Mrs MRS  AYSHA(9005700119})",
            "quantity": 1,
            "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
            "status": true,
            "latitude": 26.787006418269588,
            "longitude": 80.89352689447325,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-15T00:00:00.000Z",
            "volume": 4186,
            "email": "saurabh.k@lynkit.in",
            "mobile": "9005700119",
            "personName": "Mrs MRS  AYSHA"
        },
        {
            "value": "67877c6faea7603292975d2f",
            "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(15-01-2025), Mrs SONI  KANAUJIYA(7388373721})",
            "quantity": 1,
            "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
            "status": true,
            "latitude": 26.786613739938698,
            "longitude": 80.893661004924,
            "startSlotTime": 6,
            "endSlotTime": 20,
            "date": "2025-01-15T00:00:00.000Z",
            "volume": 4186,
            "email": "saurabh@yopmail.com",
            "mobile": "7388373721",
            "personName": "Mrs SONI  KANAUJIYA"
        }
    ],
    "origin": {
        "latitude": 26.7877001,
        "longitude": 80.8905518,
        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India"
    },
    "destination": {
        "latitude": 26.7877001,
        "longitude": 80.8905518,
        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India"
    }
}
export const previewRes = {
    "success": true,
    "data": [
        {
            "origin": {
                "latitude": 26.7877001,
                "longitude": 80.8905518,
                "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                "eta": 122
            },
            "locations": [
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "waypoint_index": 0,
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 122
                    },
                    "locationType": "source"
                },
                {
                    "waypoint_index": 1,
                    "locationDetails": {
                        "value": "678a0079c988a0151ea7809e",
                        "label": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India(17-01-2025), Mamta Gas(7277478119})",
                        "quantity": 1,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "status": true,
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-17T00:00:00.000Z",
                        "volume": 4186,
                        "email": "saurabh.k@lynkit.in",
                        "mobile": "7277478119",
                        "personName": "Mamta Gas",
                        "eta": 0,
                        "serviceTime": 600,
                        "arrival": "2025-01-17T00:30:00.941000"
                    },
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-17T00:30:00.941000",
                    "locationDepartureTime": "2025-01-17T00:40:00.941000"
                },
                {
                    "waypoint_index": 2,
                    "locationDetails": {
                        "value": "678a0063c988a0151ea7808e",
                        "label": "384/20 PURANA CHABUTRA ,SAADATGANJ ,LUCKNOW Uttar Pradesh ,LUCKNOW , PIN-226003(17-01-2025), MOHD IRFAN(9389893592})",
                        "quantity": 1,
                        "address": "384/20 PURANA CHABUTRA ,SAADATGANJ ,LUCKNOW Uttar Pradesh ,LUCKNOW , PIN-226003",
                        "status": true,
                        "latitude": 26.8569949,
                        "longitude": 80.886203,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-17T00:00:00.000Z",
                        "volume": 4186,
                        "email": "saurabh.k@lynkit.in",
                        "mobile": "9389893592",
                        "personName": "MOHD IRFAN",
                        "eta": 1264,
                        "serviceTime": 600,
                        "arrival": "2025-01-17T01:11:04.941000"
                    },
                    "location": [
                        80.886203,
                        26.8569949
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-17T01:11:04.941000",
                    "locationDepartureTime": "2025-01-17T01:21:04.941000"
                },
                {
                    "waypoint_index": 3,
                    "locationDetails": {
                        "value": "678a0050c988a0151ea7807e",
                        "label": "356/75  , SAADATGANJ ,LUCKNOW UTTAR PRADES Utta ,LUCKNOW , PIN-226017(17-01-2025), MR MOHD ARIF(9936237492})",
                        "quantity": 1,
                        "address": "356/75  , SAADATGANJ ,LUCKNOW UTTAR PRADES Utta ,LUCKNOW , PIN-226017",
                        "status": true,
                        "latitude": 26.875759402058833,
                        "longitude": 80.87898597121239,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-17T00:00:00.000Z",
                        "volume": 4186,
                        "email": "Saurabh1@Yopmail.Com",
                        "mobile": "9936237492",
                        "personName": "MR MOHD ARIF",
                        "eta": 713,
                        "serviceTime": 600,
                        "arrival": "2025-01-17T01:42:57.941000"
                    },
                    "location": [
                        80.87898597121239,
                        26.875759402058833
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-17T01:42:57.941000",
                    "locationDepartureTime": "2025-01-17T01:52:57.941000"
                },
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 122
                    },
                    "locationType": "destination"
                }
            ],
            "totalQuantity": 3,
            "dateOfTrip": "2025-01-17",
            "eta": 6554,
            "pitStopTime": 240,
            "tripArrivalTime": "2025-01-17T02:45:14.941000",
            "tripDepartureTime": "2025-01-17T00:30:00.941000",
            "vehicle": {
                "_id": "66a207c7ed51decd054d2be3",
                "vehicleType": "CYCLE or TWO-WHEELER ",
                "vehicleNo": "UP16DK1001",
                "capacityInKg": null,
                "capacityInNumber": 3,
                "base": "lynkit",
                "length": null,
                "width": null,
                "height": null,
                "freightRate": null,
                "isDeleted": false,
                "allocatedTo_Name": "Arvind Kumar",
                "allocatedTo_id": "66b22ca6e45ad87258d16de7"
            }
        },
        {
            "origin": {
                "latitude": 26.7877001,
                "longitude": 80.8905518,
                "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                "eta": 122
            },
            "locations": [
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "waypoint_index": 0,
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 122
                    },
                    "locationType": "source"
                },
                {
                    "waypoint_index": 1,
                    "locationDetails": {
                        "value": "678a0005c988a0151ea78059",
                        "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(17-01-2025), RAJ KISHOR SHARMA(9140421669})",
                        "quantity": 1,
                        "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
                        "status": true,
                        "latitude": 26.78675261413783,
                        "longitude": 80.89349470796508,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-17T00:00:00.000Z",
                        "volume": 4186,
                        "email": "saurabh.k@lynkit.in",
                        "mobile": "9140421669",
                        "personName": "RAJ KISHOR SHARMA",
                        "eta": 122,
                        "serviceTime": 600,
                        "arrival": "2025-01-17T00:32:02.941000"
                    },
                    "location": [
                        80.89349470796508,
                        26.78675261413783
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-17T00:32:02.941000",
                    "locationDepartureTime": "2025-01-17T00:42:02.941000"
                },
                {
                    "waypoint_index": 2,
                    "locationDetails": {
                        "value": "6789ff4ac988a0151ea77faf",
                        "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(17-01-2025), Mrs SONI  KANAUJIYA(7388373721})",
                        "quantity": 1,
                        "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
                        "status": true,
                        "latitude": 26.786613739938698,
                        "longitude": 80.893661004924,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-17T00:00:00.000Z",
                        "volume": 4186,
                        "email": "saurabh@yopmail.com",
                        "mobile": "7388373721",
                        "personName": "Mrs SONI  KANAUJIYA",
                        "eta": 108,
                        "serviceTime": 600,
                        "arrival": "2025-01-17T00:53:50.941000"
                    },
                    "location": [
                        80.893661004924,
                        26.786613739938698
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-17T00:53:50.941000",
                    "locationDepartureTime": "2025-01-17T01:03:50.941000"
                },
                {
                    "waypoint_index": 3,
                    "locationDetails": {
                        "value": "6789ff32c988a0151ea77f9f",
                        "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(17-01-2025), Mrs NURIN  FATIMA(8115862605})",
                        "quantity": 1,
                        "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
                        "status": true,
                        "latitude": 26.78688669941022,
                        "longitude": 80.8939131325714,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-17T00:00:00.000Z",
                        "volume": 4186,
                        "email": "saurabh@yopmail.com",
                        "mobile": "8115862605",
                        "personName": "Mrs NURIN  FATIMA",
                        "eta": 153,
                        "serviceTime": 600,
                        "arrival": "2025-01-17T01:16:23.941000"
                    },
                    "location": [
                        80.8939131325714,
                        26.78688669941022
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-17T01:16:23.941000",
                    "locationDepartureTime": "2025-01-17T01:26:23.941000"
                },
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 122
                    },
                    "locationType": "destination"
                }
            ],
            "totalQuantity": 3,
            "dateOfTrip": "2025-01-17",
            "eta": 5596,
            "pitStopTime": 240,
            "tripArrivalTime": "2025-01-17T04:45:30.941000",
            "tripDepartureTime": "2025-01-17T02:46:14.941000",
            "vehicle": {
                "_id": "6788ee1b5e268474a44464af",
                "vehicleType": "CYCLE or TWO-WHEELER ",
                "vehicleNo": "CH20CH0001",
                "capacityInKg": null,
                "capacityInNumber": 3,
                "base": "lynkit",
                "length": null,
                "width": null,
                "height": null,
                "freightRate": null,
                "isDeleted": false,
                "allocatedTo_Name": "",
                "allocatedTo_id": ""
            }
        },
        {
            "origin": {
                "latitude": 26.7877001,
                "longitude": 80.8905518,
                "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                "eta": 122
            },
            "locations": [
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "waypoint_index": 0,
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 122
                    },
                    "locationType": "source"
                },
                {
                    "waypoint_index": 1,
                    "locationDetails": {
                        "value": "6789ff0cc988a0151ea77f8c",
                        "label": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India(17-01-2025), Mrs MRS  AYSHA(9005700119})",
                        "quantity": 1,
                        "address": "Kanpur Rd, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh, India",
                        "status": true,
                        "latitude": 26.787006418269588,
                        "longitude": 80.89352689447325,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-01-17T00:00:00.000Z",
                        "volume": 4186,
                        "email": "saurabh.k@lynkit.in",
                        "mobile": "9005700119",
                        "personName": "Mrs MRS  AYSHA",
                        "eta": 115,
                        "serviceTime": 600,
                        "arrival": "2025-01-17T00:31:55.941000"
                    },
                    "location": [
                        80.89352689447325,
                        26.787006418269588
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-17T00:31:55.941000",
                    "locationDepartureTime": "2025-01-17T00:41:55.941000"
                },
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 122
                    },
                    "locationType": "destination"
                }
            ],
            "totalQuantity": 1,
            "dateOfTrip": "2025-01-17",
            "eta": 897,
            "pitStopTime": 60,
            "tripArrivalTime": "2025-01-17T05:00:27.941000",
            "tripDepartureTime": "2025-01-17T04:46:30.941000",
            "vehicle": {
                "_id": "678a00dac988a0151ea780e3",
                "vehicleType": "CYCLE or TWO-WHEELER ",
                "vehicleNo": "BR04KN1001",
                "capacityInKg": null,
                "capacityInNumber": 3,
                "base": "lynkit",
                "length": null,
                "width": null,
                "height": null,
                "freightRate": null,
                "isDeleted": false
            }
        }
    ]
}

export const DriverRes = {
    "data": [
        {
            "_id": "679b26a5a9c21c15e5520224",
            "userId": "63f762d9a62a978fd387e9d5",
            "base": "lynkit",
            "tripId": "TR1146",
            "dateOfTrip": "2025-01-30T00:00:00.000Z",
            "vehicle": {
                "_id": "66a207c7ed51decd054d2be3",
                "vehicleType": "CYCLE or TWO-WHEELER ",
                "vehicleNo": "UP16DK1001",
                "capacityInKg": null,
                "capacityInNumber": 10,
                "base": "lynkit",
                "length": null,
                "width": null,
                "height": null,
                "freightRate": null,
                "isDeleted": false,
                "allocatedTo_Name": "Arvind Kumar",
                "allocatedTo_id": "66b22ca6e45ad87258d16de7",
                "driverDetails": {
                    "_id": "66b22ca6e45ad87258d16de7",
                    "email": "test@gmail.com",
                    "mobile": "9455125992",
                    "name": "Arvind Kumar"
                }
            },
            "locations": [
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "waypoint_index": 0,
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 538
                    },
                    "locationType": "source",
                    "requestId": "1738221221890",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 1,
                    "locationDetails": {
                        "_id": "6798df39c856d77e9462950c",
                        "quantity": 1,
                        "address": "MIG 1132 SECT-I LDA COLONY KNP ROAD LUCKNOW UTTAR PRADES Utta LUCKNOW 226012",
                        "date": "2025-01-26T18:30:00.000Z",
                        "latitude": 26.79825862111011,
                        "longitude": 80.91764733195305,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "mobile": "9919503063",
                        "personName": "MRS SHEETALA DEVI",
                        "eta": 1188,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T00:40:10.989000",
                        "orderNo": "27012521165",
                        "requestTime": "2025-01-27T08:41:00.000Z"
                    },
                    "location": [
                        80.91764733195305,
                        26.79825862111011
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T00:40:10.989000",
                    "locationDepartureTime": "2025-01-30T00:50:10.989000",
                    "requestId": "1738221221892",
                    "deliveryLocation": {
                        "latitude": 28.5256322,
                        "longitude": 77.2754478
                    },
                    "deliveryViolation": false,
                    "isReached": true,
                    "time": "2025-01-30T07:33:46.724Z",
                    "cylinderWeight": "",
                    "deliveryTime": "2025-01-30T09:16:05.311Z",
                    "isDelivered": true,
                    "isOTP": true,
                    "leakCheck": false,
                    "qrCodeValue": "https://lpgpfs.bpcl.in?id=060923591390345",
                    "filePath": "",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 2,
                    "locationDetails": {
                        "_id": "6798df39c856d77e946294fc",
                        "quantity": 1,
                        "address": "L-197 SECT-L L.D.A. COLONY KANPUR ROAD LUCKNOW 2425323 0",
                        "date": "2025-01-26T18:30:00.000Z",
                        "latitude": 26.79577527378657,
                        "longitude": 80.92274755239487,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "mobile": "9415500197",
                        "personName": "RAKESH KUMAR",
                        "eta": 832,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T01:03:55.989000",
                        "orderNo": "27012521149",
                        "requestTime": "2025-01-27T08:41:00.000Z"
                    },
                    "location": [
                        80.92274755239487,
                        26.79577527378657
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T01:03:55.989000",
                    "locationDepartureTime": "2025-01-30T01:13:55.989000",
                    "requestId": "1738221221895",
                    "deliveryLocation": {
                        "latitude": 28.5256322,
                        "longitude": 77.2754478
                    },
                    "deliveryViolation": false,
                    "isReached": true,
                    "time": "2025-01-30T09:17:53.848Z",
                    "cylinderWeight": "",
                    "deliveryTime": "2025-01-30T09:18:50.117Z",
                    "isDelivered": true,
                    "isOTP": true,
                    "leakCheck": false,
                    "qrCodeValue": "https://lpgpfs.bpcl.in?id=060923591390345",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 3,
                    "locationDetails": {
                        "_id": "6798df39c856d77e9462950d",
                        "quantity": 1,
                        "address": "E W S-701 SEC.-I L D A COLONY LUCKNOW 0",
                        "date": "2025-01-26T18:30:00.000Z",
                        "latitude": 26.797619982871993,
                        "longitude": 80.9158955141902,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "mobile": "9919503063",
                        "personName": "PARSHU RAM",
                        "eta": 871,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T01:27:49.989000",
                        "orderNo": "27012521166",
                        "requestTime": "2025-01-27T08:41:00.000Z"
                    },
                    "location": [
                        80.9158955141902,
                        26.797619982871993
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T01:27:49.989000",
                    "locationDepartureTime": "2025-01-30T01:37:49.989000",
                    "requestId": "1738221221897",
                    "deliveryLocation": {
                        "latitude": 28.5256322,
                        "longitude": 77.2754478
                    },
                    "deliveryViolation": false,
                    "isReached": true,
                    "time": "2025-01-30T09:20:06.693Z",
                    "cylinderWeight": "",
                    "deliveryTime": "2025-01-30T09:21:11.205Z",
                    "isDelivered": true,
                    "isOTP": true,
                    "leakCheck": false,
                    "qrCodeValue": "https://lpgpfs.bpcl.in?id=060923591390345",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 4,
                    "locationDetails": {
                        "_id": "6798df39c856d77e946294fb",
                        "quantity": 1,
                        "address": "832 SEC.-I KANPUR ROAD LUCKNOW 0",
                        "date": "2025-01-26T18:30:00.000Z",
                        "latitude": 26.797418574659368,
                        "longitude": 80.91516796499491,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "mobile": "9889409449",
                        "personName": "B L BHARTIYA",
                        "eta": 621,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T01:49:37.989000",
                        "orderNo": "27012521148",
                        "requestTime": "2025-01-27T08:41:00.000Z"
                    },
                    "location": [
                        80.91516796499491,
                        26.797418574659368
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T01:49:37.989000",
                    "locationDepartureTime": "2025-01-30T01:59:37.989000",
                    "requestId": "1738221221899",
                    "deliveryLocation": {
                        "latitude": 28.5255784,
                        "longitude": 77.2753532
                    },
                    "deliveryViolation": false,
                    "isReached": true,
                    "time": "2025-01-30T09:21:51.382Z",
                    "cylinderWeight": "",
                    "deliveryTime": "2025-01-30T10:35:45.498Z",
                    "isDelivered": true,
                    "isOTP": true,
                    "leakCheck": false,
                    "qrCodeValue": "https://lpgpfs.bpcl.in?id=060923591390345",
                    "mainRequests": []
                },
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 1279
                    },
                    "locationType": "destination",
                    "requestId": "1738221221902",
                    "mainRequests": []
                }
            ],
            "eta": 4791,
            "tripStatus": "completed",
            "isVehicleOverWeight": false,
            "totalQuantity": 4,
            "currentWaypoint": 4,
            "allWaypointCovered": false,
            "origin": {
                "latitude": 26.7877001,
                "longitude": 80.8905518,
                "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                "eta": 538
            },
            "status": true,
            "allAreaIds": [],
            "tripCreationType": "manualTrip",
            "pitStopTime": 240,
            "tripArrivalTime": "2025-01-29T20:12:35.989Z",
            "tripDepartureTime": "2025-01-29T19:00:00.989Z",
            "routeOptimizerRequestName": "RouteOptimiser_1738221184050",
            "__v": 0,
            "createdAt": "2025-01-30T07:13:41.908Z",
            "updatedAt": "2025-01-30T10:36:34.990Z",
            "dayBasedSerialNumber": 1,
            "tripStartTime": "2025-01-30T07:33:43.505Z",
            "tripEndTime": "2025-01-30T10:36:34.990Z"
        },
        {
            "_id": "679b2d12a9c21c15e55205b3",
            "userId": "63f762d9a62a978fd387e9d5",
            "base": "lynkit",
            "tripId": "TR1147",
            "dateOfTrip": "2025-01-30T00:00:00.000Z",
            "vehicle": {
                "_id": "66a207c7ed51decd054d2be3",
                "vehicleType": "CYCLE or TWO-WHEELER ",
                "vehicleNo": "UP16DK1001",
                "capacityInKg": null,
                "capacityInNumber": 10,
                "base": "lynkit",
                "length": null,
                "width": null,
                "height": null,
                "freightRate": null,
                "isDeleted": false,
                "allocatedTo_Name": "Arvind Kumar",
                "allocatedTo_id": "66b22ca6e45ad87258d16de7",
                "driverDetails": {
                    "_id": "66b22ca6e45ad87258d16de7",
                    "email": "test@gmail.com",
                    "mobile": "9455125992",
                    "name": "Arvind Kumar"
                }
            },
            "locations": [
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "waypoint_index": 0,
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 598
                    },
                    "locationType": "source",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 1,
                    "locationDetails": {
                        "_id": "6798df39c856d77e94629504",
                        "quantity": 1,
                        "address": "E-360 SECT I L.D.A. COLONY LUCKNOW 0",
                        "status": true,
                        "date": "2025-01-26T18:30:00.000Z",
                        "latitude": 26.79934884668269,
                        "longitude": 80.91478373855352,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "personName": "RAJENDRA RAI",
                        "requestId": "RN-6960",
                        "eta": 1135,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T00:38:20.081000"
                    },
                    "location": [
                        80.91478373855352,
                        26.79934884668269
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T00:38:20.081000",
                    "locationDepartureTime": "2025-01-30T00:48:20.081000",
                    "deliveryLocation": {
                        "latitude": 28.5256355,
                        "longitude": 77.2754449
                    },
                    "deliveryViolation": false,
                    "isReached": true,
                    "time": "2025-01-30T10:10:37.058Z",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 2,
                    "locationDetails": {
                        "_id": "6798df39c856d77e94629501",
                        "quantity": 1,
                        "address": "M-1497 SECT-I L.D.A. COLONY KANPUR ROAD LUCKNOW 0",
                        "date": "2025-01-26T18:30:00.000Z",
                        "latitude": 26.797781887282866,
                        "longitude": 80.91473177075386,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "mobile": "9919503063",
                        "personName": "R.P.SRIVASTAVA",
                        "eta": 666,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T01:00:08.081000",
                        "orderNo": "27012521154",
                        "requestTime": "2025-01-27T08:41:00.000Z"
                    },
                    "location": [
                        80.91473177075386,
                        26.797781887282866
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T01:00:08.081000",
                    "locationDepartureTime": "2025-01-30T01:10:08.081000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 3,
                    "locationDetails": {
                        "_id": "6798df39c856d77e94629503",
                        "quantity": 1,
                        "address": "W/O RAMA SHANKAR YADAV E-236/SECT-I L.D.A. COLONY KANPUR ROAD LKO. 0",
                        "date": "2025-01-26T18:30:00.000Z",
                        "latitude": 26.799187243773186,
                        "longitude": 80.9164272621274,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "mobile": "9919503063",
                        "personName": "SUBARAN DEVI",
                        "eta": 729,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T01:21:56.081000",
                        "orderNo": "27012521156",
                        "requestTime": "2025-01-27T08:41:00.000Z"
                    },
                    "location": [
                        80.9164272621274,
                        26.799187243773186
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T01:21:56.081000",
                    "locationDepartureTime": "2025-01-30T01:31:56.081000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 4,
                    "locationDetails": {
                        "_id": "6798df39c856d77e94629502",
                        "quantity": 1,
                        "address": "W/O PYARE MOHAN A-880 SECT -I L.D.A. COLONY LKO. 2422700 0",
                        "date": "2025-01-26T18:30:00.000Z",
                        "latitude": 26.795593315132706,
                        "longitude": 80.9176466614008,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "mobile": "9919503063",
                        "personName": "REENA SRIVASTAVA",
                        "eta": 772,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T01:44:02.081000",
                        "orderNo": "27012521155",
                        "requestTime": "2025-01-27T08:41:00.000Z"
                    },
                    "location": [
                        80.9176466614008,
                        26.795593315132706
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T01:44:02.081000",
                    "locationDepartureTime": "2025-01-30T01:54:02.081000",
                    "mainRequests": []
                },
                {
                    "waypoint_index": 5,
                    "locationDetails": {
                        "_id": "6798df39c856d77e94629505",
                        "quantity": 1,
                        "address": "K-470 AASHIANA COLONY LUCKNOW 0",
                        "status": true,
                        "date": "2025-01-26T18:30:00.000Z",
                        "latitude": 26.79122143131625,
                        "longitude": 80.91331288218498,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "email": "0",
                        "personName": "R.K.YADAV",
                        "requestId": "RN-6961",
                        "eta": 808,
                        "serviceTime": 600,
                        "arrival": "2025-01-30T02:07:43.081000"
                    },
                    "location": [
                        80.91331288218498,
                        26.79122143131625
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-01-30T02:07:43.081000",
                    "locationDepartureTime": "2025-01-30T02:17:43.081000",
                    "mainRequests": []
                },
                {
                    "location": [
                        80.8905518,
                        26.7877001
                    ],
                    "locationDetails": {
                        "latitude": 26.7877001,
                        "longitude": 80.8905518,
                        "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                        "eta": 1201
                    },
                    "locationType": "destination",
                    "mainRequests": []
                }
            ],
            "eta": 5311,
            "tripStatus": "in_transit",
            "isVehicleOverWeight": false,
            "totalQuantity": 5,
            "currentWaypoint": 1,
            "allWaypointCovered": false,
            "origin": {
                "latitude": 26.7877001,
                "longitude": 80.8905518,
                "address": "494, Hind Nagar Road, Hind Nagar, Sector C1, LDA Colony, Lucknow, Uttar Pradesh 226012, India",
                "eta": 535
            },
            "status": true,
            "allAreaIds": [],
            "tripCreationType": "manualTrip",
            "pitStopTime": 300,
            "tripArrivalTime": "2025-01-29T20:22:41.081Z",
            "tripDepartureTime": "2025-01-29T19:00:00.081Z",
            "routeOptimizerRequestName": "RouteOptimiser_1738229691146",
            "__v": 0,
            "createdAt": "2025-01-30T07:41:06.686Z",
            "updatedAt": "2025-01-30T10:10:37.059Z",
            "editRemark": "New order(s) added to the specific trip",
            "editType": "emergency",
            "dayBasedSerialNumber": 2,
            "tripStartTime": "2025-01-30T10:09:54.632Z"
        },
        {
            "_id": "67b23d083c00cf3e1c6ac439",
            "userId": "63f7631ba62a978fd387ead9",
            "base": "lynkit",
            "tripId": "TR1441",
            "dateOfTrip": "2025-02-17T00:00:00.000Z",
            "vehicle": {
                "vehicle_id": "67125fa5f895caa3905a5095",
                "vehicleType": "CYCLE or TWO-WHEELER ",
                "vehicleNo": "DL20CL2020",
                "driverDetails": {
                    "_id": "671a1592d254f044cac397be",
                    "email": "majorgas@yopmail.com",
                    "mobile": "7277478119",
                    "name": "major gas service"
                }
            },
            "locations": [
                {
                    "location": [
                        80.9978845,
                        26.8881083
                    ],
                    "waypoint_index": 0,
                    "locationDetails": {
                        "latitude": 26.8881083,
                        "longitude": 80.9978845,
                        "address": "Major Mehra Bharat Gas Service, Sector 13, Indira Nagar, Lucknow, Uttar Pradesh, India",
                        "eta": 585
                    },
                    "locationType": "source",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 1,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-09T18:30:00.000Z",
                        "orderId": "10022025100116",
                        "status": true,
                        "consumerId": "20377169",
                        "_id": "67ac3ce4e529a57a08385983",
                        "latitude": 26.896252900138368,
                        "longitude": 81.01498872041702,
                        "address": "631/100 AJAY NAGAR KAMTA CHINHAT LUCKNOW LUCKNOW 226016",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "8115884400",
                        "personName": "A K VERMA",
                        "areaId": "146345",
                        "paymentMode": "Cash on Delivery",
                        "eta": 601,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T02:40:01.002000"
                    },
                    "location": [
                        81.01498872041702,
                        26.896252900138368
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T02:40:01.002000",
                    "locationDepartureTime": "2025-02-17T02:51:44.702000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 2,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-10T18:30:00.000Z",
                        "orderId": "110225201008",
                        "status": true,
                        "consumerId": "20377169",
                        "_id": "67ac567c353ea76674d1e3fd",
                        "latitude": 26.896252900138368,
                        "longitude": 81.01498872041702,
                        "address": "631/281 AJAY NAGAR-NEAR KAMTA CHINHAT LUCKNOW 226016",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "9451041537",
                        "personName": "RAMA SHANKER",
                        "areaId": "146345",
                        "paymentMode": "Cash on Delivery",
                        "eta": 0,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T02:51:44.702000"
                    },
                    "location": [
                        81.01498872041702,
                        26.896252900138368
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T03:00:01.002000",
                    "locationDepartureTime": "2025-02-17T03:13:29.402000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 3,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-10T18:30:00.000Z",
                        "orderId": "110225201006",
                        "status": true,
                        "consumerId": "20377169",
                        "_id": "67ac567c353ea76674d1e3fb",
                        "latitude": 26.896252900138368,
                        "longitude": 81.01498872041702,
                        "address": "M.N.-165-TAKROHI INDIRA NAGAR LUCKNOW 0",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "7277478119",
                        "personName": "MANOJ KUMAR VISHWAKARMA",
                        "areaId": "145110",
                        "paymentMode": "Cash on Delivery",
                        "eta": 0,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T03:13:29.402000"
                    },
                    "location": [
                        81.01498872041702,
                        26.896252900138368
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T03:20:01.002000",
                    "locationDepartureTime": "2025-02-17T03:35:14.102000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 4,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-10T18:30:00.000Z",
                        "orderId": "110225201005",
                        "status": true,
                        "consumerId": "20377169",
                        "_id": "67ac567c353ea76674d1e3fa",
                        "latitude": 26.896252900138368,
                        "longitude": 81.01498872041702,
                        "address": "M.N.-165-TAKROHI INDIRA NAGAR LUCKNOW 0",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "7277478119",
                        "personName": "MANOJ KUMAR VISHWAKARMA",
                        "areaId": "145110",
                        "paymentMode": "Cash on Delivery",
                        "eta": 0,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T03:35:14.102000"
                    },
                    "location": [
                        81.01498872041702,
                        26.896252900138368
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T03:40:01.002000",
                    "locationDepartureTime": "2025-02-17T03:56:58.802000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 5,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-09T18:30:00.000Z",
                        "orderId": "10022025100110",
                        "status": true,
                        "consumerId": "20370973",
                        "_id": "67ac3ce4e529a57a0838597d",
                        "latitude": 26.8983037797333,
                        "longitude": 81.01327445358038,
                        "address": "TAKROHI INDIRA NAGAR LUCKNOW 0",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "7277478119",
                        "personName": "RAM BUX\t",
                        "areaId": "145110",
                        "paymentMode": "Cash on Delivery",
                        "eta": 108,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T03:58:46.802000"
                    },
                    "location": [
                        81.01327445358038,
                        26.8983037797333
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T04:01:49.002000",
                    "locationDepartureTime": "2025-02-17T04:18:43.502000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 6,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-10T18:30:00.000Z",
                        "orderId": "110225201001",
                        "status": true,
                        "consumerId": "20370973",
                        "_id": "67ac567c353ea76674d1e3f6",
                        "latitude": 26.8983037797333,
                        "longitude": 81.01327445358038,
                        "address": "TAKROHI INDIRA NAGAR LUCKNOW 0",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "7277478119",
                        "personName": "RAM BUX\t",
                        "areaId": "145110",
                        "paymentMode": "Cash on Delivery",
                        "eta": 0,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T04:18:43.502000"
                    },
                    "location": [
                        81.01327445358038,
                        26.8983037797333
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T04:21:49.002000",
                    "locationDepartureTime": "2025-02-17T04:40:28.202000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 7,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-09T18:30:00.000Z",
                        "orderId": "10022025100113",
                        "status": true,
                        "consumerId": "20376560",
                        "_id": "67ac3ce4e529a57a08385980",
                        "latitude": 26.900921818888953,
                        "longitude": 81.0103166475892,
                        "address": "P NO-115 BUDHA VIHAR COLNOY TAKROHI INDIRA NAGAR LUCKNOW SV NOTARY 5/09/2012 226016",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "7277478124",
                        "personName": "DURGA PRASAD",
                        "areaId": "145110",
                        "paymentMode": "Cash on Delivery",
                        "eta": 180,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T04:43:28.202000"
                    },
                    "location": [
                        81.0103166475892,
                        26.900921818888953
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T04:44:49.002000",
                    "locationDepartureTime": "2025-02-17T05:02:12.902000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 8,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-10T18:30:00.000Z",
                        "orderId": "110225201004",
                        "status": true,
                        "consumerId": "20376560",
                        "_id": "67ac567c353ea76674d1e3f9",
                        "latitude": 26.900921818888953,
                        "longitude": 81.0103166475892,
                        "address": "P NO-115 BUDHA VIHAR COLNOY TAKROHI INDIRA NAGAR LUCKNOW SV NOTARY 5/09/2012 226016",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "7277478124",
                        "personName": "DURGA PRASAD",
                        "areaId": "145110",
                        "paymentMode": "Cash on Delivery",
                        "eta": 0,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T05:02:12.902000"
                    },
                    "location": [
                        81.0103166475892,
                        26.900921818888953
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T05:04:49.002000",
                    "locationDepartureTime": "2025-02-17T05:23:57.602000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 9,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-10T18:30:00.000Z",
                        "orderId": "110225201002",
                        "status": true,
                        "consumerId": "20371520",
                        "_id": "67ac567c353ea76674d1e3f7",
                        "latitude": 26.9004506002396,
                        "longitude": 81.00571498274803,
                        "address": "B-181 GROUND FLOOR B-181 DEEN DAYALPURAM LUCKNOW UTTAR PRADES Utta LUCKNOW 226028",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "7277478120",
                        "personName": "MRS SEEMA BANERJEE",
                        "areaId": "145110",
                        "paymentMode": "Cash on Delivery",
                        "eta": 173,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T05:26:50.602000"
                    },
                    "location": [
                        81.00571498274803,
                        26.9004506002396
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T05:27:42.002000",
                    "locationDepartureTime": "2025-02-17T05:45:42.302000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "waypoint_index": 10,
                    "locationDetails": {
                        "distributorId": "182224",
                        "quantity": 1,
                        "startSlotTime": 6,
                        "endSlotTime": 20,
                        "date": "2025-02-09T18:30:00.000Z",
                        "orderId": "10022025100111",
                        "status": true,
                        "consumerId": "20371520",
                        "_id": "67ac3ce4e529a57a0838597e",
                        "latitude": 26.9004506002396,
                        "longitude": 81.00571498274803,
                        "address": "B-181 GROUND FLOOR B-181 DEEN DAYALPURAM LUCKNOW UTTAR PRADES Utta LUCKNOW 226028",
                        "isLocationVerified": true,
                        "email": "0",
                        "mobile": "7277478120",
                        "personName": "MRS SEEMA BANERJEE",
                        "areaId": "145110",
                        "paymentMode": "Cash on Delivery",
                        "eta": 0,
                        "serviceTime": 600,
                        "arrival": "2025-02-17T05:45:42.302000"
                    },
                    "location": [
                        81.00571498274803,
                        26.9004506002396
                    ],
                    "locationType": "waypoint",
                    "locationStartServiceTime": "2025-02-17T05:47:42.002000",
                    "locationDepartureTime": "2025-02-17T06:07:27.002000",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                },
                {
                    "location": [
                        80.9978845,
                        26.8881083
                    ],
                    "locationDetails": {
                        "latitude": 26.8881083,
                        "longitude": 80.9978845,
                        "address": "Major Mehra Bharat Gas Service, Sector 13, Indira Nagar, Lucknow, Uttar Pradesh, India",
                        "eta": 585
                    },
                    "locationType": "destination",
                    "mainRequests": [
                        {
                            "_id": "67a0be7b40a708589ccfb2af",
                            "remarks": "Please deliver after 4pm",
                            "tripId": "TR1256",
                            "createdAt": "2025-02-03T13:02:51.010Z",
                            "updatedAt": "2025-02-03T13:02:51.010Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1cfc0cb581943caee6c8f",
                            "remarks": "Please deliver after 2 PM",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:28:48.339Z",
                            "updatedAt": "2025-02-04T08:28:48.339Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d015cb581943caee6c97",
                            "remarks": "Hi ",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:30:13.655Z",
                            "updatedAt": "2025-02-04T08:30:13.655Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d06fcb581943caee6ca2",
                            "remarks": "This location",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:31:43.273Z",
                            "updatedAt": "2025-02-04T08:31:43.273Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d1edcb581943caee6d16",
                            "remarks": "test",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:38:05.266Z",
                            "updatedAt": "2025-02-04T08:38:05.266Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a1d232cb581943caee6d1e",
                            "remarks": "etst",
                            "tripId": "TR1285",
                            "createdAt": "2025-02-04T08:39:14.018Z",
                            "updatedAt": "2025-02-04T08:39:14.018Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a409d98ad7c4402d1e33",
                            "remarks": "test ashis",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:21.825Z",
                            "updatedAt": "2025-02-07T06:11:21.825Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a420d98ad7c4402d1e39",
                            "remarks": "test map",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:11:44.672Z",
                            "updatedAt": "2025-02-07T06:11:44.672Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a528d98ad7c4402d1e5b",
                            "remarks": "test1",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:16:08.173Z",
                            "updatedAt": "2025-02-07T06:16:08.173Z",
                            "__v": 0
                        },
                        {
                            "_id": "67a5a832d98ad7c4402d1ec8",
                            "remarks": "asasa",
                            "tripId": "TR1380",
                            "createdAt": "2025-02-07T06:29:06.873Z",
                            "updatedAt": "2025-02-07T06:29:06.873Z",
                            "__v": 0
                        }
                    ]
                }
            ],
            "eta": 8247,
            "tripStatus": "initiated",
            "isVehicleOverWeight": false,
            "totalQuantity": 10,
            "currentWaypoint": 0,
            "allWaypointCovered": false,
            "origin": {
                "latitude": 26.8881083,
                "longitude": 80.9978845,
                "address": "Major Mehra Bharat Gas Service, Sector 13, Indira Nagar, Lucknow, Uttar Pradesh, India",
                "eta": 585
            },
            "status": true,
            "allAreaIds": [
                "145110",
                "146345"
            ],
            "tripCreationType": "cron job",
            "pitStopTime": 600,
            "tripArrivalTime": "2025-02-17T00:37:27.002Z",
            "tripDepartureTime": "2025-02-16T21:00:00.002Z",
            "dayBasedSerialNumber": 1,
            "routeOptimizerRequestName": "RouteOptimiser_182224_63f7631ba62a978fd387ead9_1739734243783",
            "createdAt": "2025-02-16T19:31:20.710Z",
            "updatedAt": "2025-02-16T19:31:20.710Z",
            "__v": 0
        }
    ],
    "count": 2,
    "success": true
}