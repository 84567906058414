import React, { useEffect, useState, useRef } from 'react'
import CreateTrip from '../Dashboard/Modal/CreateTrip'
import AllocateDriver from '../Dashboard/Modal/AllocateDriver'
import EditTrip from '../Dashboard/Modal/EditTrip'
import HandleInputOutput from '../Dashboard/Modal/HandleInputOutput'
import Solutions from '../Dashboard/Modal/Solutions'
import VehicleLoads from '../Dashboard/Modal/VehLoad'
import HandleOtp from '../Dashboard/Modal/HandleOtp'
import $, { data } from 'jquery'
import axios from 'axios'
import { getTrips } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import history from '../../services/history'
import { selectInit } from '../../services/functions'
import DeliveryDetails from '../Dashboard/Modal/DeliveryDetails'
import LocationModal from '../Dashboard/Modal/LocationModal'
import { logoutOnExpiry, url, socektDomain } from '../../services/requests'
import currentStock from './../../static/images/icon/sidebar/updated/currentStock.png'
import incomingStock from './../../static/images/icon/sidebar/updated/incomingStock.png'
import intransit from './../../static/images/icon/sidebar/updated/intransit.png'
import rider from './../../static/images/icon/sidebar/updated/rider.png'
import stock from './../../static/images/icon/sidebar/updated/stock.png'
import delivery from './../../static/images/icon/sidebar/updated/delivery.png'
import Select from 'react-select';
import { colourStyles } from '../../services/functions'
import { Table } from 'react-bootstrap'
import CustomPageLayout from '../Utils/CustomPageLayout/CustomPageLayout'
import { InputSearchDropDown, DropDown, InputSearch } from '../Utils/Inputs'
import { showNotification } from '../Utils/Inputs';
import { handlePostApi } from '../../services/functions'
import TripMapping from './TripMapping'
import { getUsers } from '../../state/redux/actions/index'
// import { io } from 'socket.io-client';
import { DriverRes } from '../Utils/Dummy'

const TripMana = () => {
    const dispatch = useDispatch()
    // const myRef = useRef(null)
    const DriverListing = useSelector((state) => state.getUserRed.usersList)
    const listing = useSelector((state) => state.tripListingRed.tripListing)
    const loader = useSelector((state) => state.tripListingRed.loading)
    const message = useSelector((state) => state.tripListingRed.message)
    const counts = useSelector((state) => state.tripListingRed.count)
    let user = localStorage.getItem("userType")
    const [pageCount, setPageCount] = useState(1)
    const [pageLimit, setPageLimit] = useState(10);
    // const [count, setCount] = useState(10)
    const [date, setDate] = useState('');
    const [loadingViewTrip, setLoadingViewTrip] = useState(false);
    const [sharedData, setSharedData] = useState({})
    const [sharedState, setSharedState] = useState({})
    const [paginationState, setPaginationState] = useState({ pageNumber: '', entries: '' })
    const [geo, setGeo] = useState({ val: '' })
    const [deliveryState, setdeliveryState] = useState({ data: {}, item: {} })
    const [otpData, setOtpData] = useState({
        tripId: '',
        type: ''
    })
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        searchType: 'tripId',
        searchValue: '',
        filterType:'', 
        filterValue: '',
    })
    const [fetchloading, setFetchLoading] = useState(false)
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const returnFun = () => {
        setPageCount(1)
    }
    const sharefun = (item) => {
        setSharedState(item)
    }
    const toggleTrip = (item, param) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if (param === 'start') {
            axios.post(`${url}/trip/editTrip`, { tripId: item, type: 'start_trip' }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    if (status === true) {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        dispatch(getTrips({
                            page: pageCount,
                            limit: pageLimit,
                            date: date
                        }))
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
        else if (param === 'reached') {
            axios.post(`${url}/trip/editTrip`, { tripId: item, type: 'reached_waypoint' }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    if (status === true) {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        dispatch(getTrips({
                            page: pageCount,
                            limit: pageLimit,
                            date: date
                        }))
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
        else if (param === 'stop') {
            axios.post(`${url}/trip/editTrip`, { tripId: item, type: 'end_trip' }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    if (status === true) {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        dispatch(getTrips({
                            page: pageCount,
                            limit: pageLimit,
                            date: date
                        }))
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    const openMap = (item) => {
        history.push({
            pathname: '/dashboard/trip-map',
            state: item
        })
    }
    const nextPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount += 1);
        });
    }
    const prevPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount -= 1);
        });
    }
    const _onDateChange = (e) => {
        setDate(e.target.value)
    }
    const shareData = (item) => {
        setSharedData({ data: item, status: true })
        let temp = { ...paginationState }
        temp.pageNumber = pageCount
        temp.entries = pageLimit
        setPaginationState(temp)
    }
    const deallocateVehicle = (id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: 'De-allocate Delivery Man ?', title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post(`${url}/trip/deallocateDriver`, { tripId: id }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        const message = response.data.message
                        if (status === true) {
                            dispatch(getTrips({
                                page: pageCount,
                                limit: pageLimit,
                                date: date
                            }))
                            window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }

                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        })

    }

    // console.log('DriverListing', DriverListing)
    useEffect(() => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 50)
        dispatch(
          getTrips({
            page: pageCount,
            limit: pageLimit,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue,
            filterType: searchObj.filterType,
            filterValue: searchObj.filterValue,
            date: searchObj.date,
          })
        );
    }, [pageCount])

    useEffect(()=> {
      dispatch(
        getUsers({
          page: 1,
          limit: 100,
          searchType: "",
          searchValue: "",
          userType: "driver",
          type: "driver",
        })
      );

    },[])

    const _searchDrop = (id, value) => {
        let temp = { ...searchObj }
        temp.searchType = id
        setSearchObject(temp)

    }
    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = (action, payload) => {
        if (action === "clear") {
          dispatch(
            getTrips({
              page: 1,
              limit: 10,
              searchType: searchObj.searchType,
              searchValue: "",
            })
          );
        } else {
          setPageCount(1);
          if (payload) {
            dispatch(getTrips({ ...payload, page: 1, limit: pageLimit }));
          } else {
            dispatch(
              getTrips({
                page: 1,
                limit: pageLimit,
                searchType: searchObj.searchType,
                searchValue: searchObj.searchValue,
                filterType: searchObj.filterType,
                filterValue:searchObj.filterValue,
                date: searchObj.date,
              })
            );
          }
        }
    }
    const openOtpModal = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const sendOtpData = (id, type) => {
        let local = { ...otpData }
        local.tripId = id
        local.type = type
        setOtpData(local)
    }
    const slide = (index) => {
        const buttonRight = document.getElementById(`slideRight-${index}`);
        const buttonLeft = document.getElementById(`slideLeft-${index}`);

        buttonRight.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft += 150;
        };
        buttonLeft.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft -= 150;
        };
    }
    const _handleClick1 = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const shareDeliveryData = (data, item) => {
        console.log("data", data, item)
        let temp = { ...deliveryState }
        temp.data = data
        temp.item = item
        setdeliveryState(temp)
    }
    const shareData1 = (data) => {
        let temp = { ...geo }
        temp.val = data
        setGeo(temp)
    }
    const deleteTrip = (id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: 'Delete this Trip ?', title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post(`${url}/trip/deleteTrip`, { tripId: id }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        if (status === true) {
                            setPageCount(1)
                            dispatch(getTrips({
                                page: pageCount,
                                limit: pageLimit,
                                date: ''
                            }))
                            window.$.fn.show_notification({ message: 'Trip deleted successfully.', title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                        else {
                            window.$.fn.show_notification({ message: 'Some Error Occured.', title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        })

    }
    const allTrips = () => {
        history.push({
            pathname: '/dashboard/all-trips'
            // state: item
        })
    }
    const entries = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 }
    ]
    const handleChange = (item) => {
        let counts = item.value
        setPageLimit(counts)
        setPageCount(1)
        dispatch(getTrips({
            page: 1,
            limit: item.value,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue,
            filterType: searchObj.filterType,
            filterValue:searchObj.filterValue,
            date: searchObj.date,
        }))
    }

    const handleFetchAutoTrip = async() => {
      showNotification({ message: "Are you sure you want to create auto trips for all ready orders?", title: "Alert!!", type: "confirm" }, async (result) => {
          if (result.type === "confirm") {
              setFetchLoading(true);
              const response = await handlePostApi("/routeOptimizer/getRouteAuto_v2", {  _id: localStorage.getItem('id') });
              if (response?.success === true) {
                if(response.data?.uniqId){
                  localStorage.setItem("autoTripUniqueId", response.data?.uniqId);
                  localStorage.setItem("autoTripProcessing", true);
                  const intervalId = setInterval(async () => {
                    const response = await handlePostApi("/routeOptimizer/getRouteAuto_v2_tripCreationStatusCheck", { _id: localStorage.getItem("id"), uniqId: Number(localStorage.getItem("autoTripUniqueId")) });
                    if (response?.success === true) {
                      clearInterval(intervalId); // Stop the interval
                      setFetchLoading(false);
                      localStorage.removeItem("autoTripProcessing");
                      localStorage.removeItem("autoTripUniqueId");
                      showNotification({ message: response.message, title: "Success!!", autohide: true, showInput: false });
                      setPageCount(1);
                      setPageLimit(10);
                      setDate("");
                      dispatch(
                        getTrips({
                          page: 1,
                          limit: 10,
                          searchType: "",
                          searchValue: "",
                          date: "",
                        })
                      );
                    }
                  }, 30000); // 30 seconds interval
                }else{
                  showNotification({ message: response.message, title: "Success!!", autohide: true, showInput: false });
                  setFetchLoading(false);
                }
                
              }else{
                setFetchLoading(false);
              }
              // if (response?.success === true) {
              //   setFetchLoading(false);
              //   showNotification({message: response.message, title: "Success!!", autohide: true, showInput: false});
              //   setPageCount(1);
              //   setPageLimit(10);
              //   setDate('');
              //   dispatch(
              //     dispatch(getTrips({
              //         page: 1,
              //         limit: 10,
              //         searchType: '',
              //         searchValue: '',
              //         date: ""
              //     }))
              //   );
              // }else{
              //   setFetchLoading(false);
              // }
          }
        });   
  }

  const handleViewTrip = async (data, index) => {
    // console.log('item', data)
    setLoadingViewTrip(true)
    const body = {
      date: formatDateTime(new Date()),
      searchType: "vehicle.driverDetails._id",
      searchValue: searchObj?.filterValue,
    };
    try {
      const result = await handlePostApi("/trip/getTrip", { ...body });
      if (result.success) {
        setLoadingViewTrip(false)
        history.push({
          pathname: "/dashboard/trip/preview",
          state: { isSaveTrip : false, clusterArr: result.data },
        });
        // setVehicleDetails(result.data.data); // Show popover after data is fetched
      }else{
        // history.push({
        //   pathname: "/dashboard/trip/preview",
        //   state: { isSaveTrip : false, clusterArr: DriverRes },
        // });
        showNotification({ message: result?.message || 'Something went wrong', type : 'error', title: "Error!!", autohide: true, showInput: false });
        setLoadingViewTrip(false)
      }
    } catch (error) {
      setLoadingViewTrip(false);
      console.error("Error fetching data:", error);
    }
    
  }


  useEffect(() => {
    const uniqueId = localStorage.getItem("autoTripUniqueId") || "";
    if (uniqueId !== "") {
      setFetchLoading(true);
      const intervalId = setInterval(async () => {
        const response = await handlePostApi("/routeOptimizer/getRouteAuto_v2_tripCreationStatusCheck", { _id: localStorage.getItem("id"), uniqId: Number(uniqueId) });
        if (response?.success === true) {
          clearInterval(intervalId); // Stop the interval
          setFetchLoading(false);
          localStorage.removeItem("autoTripUniqueId");
          localStorage.removeItem("autoTripProcessing");
          showNotification({ message: response.message, title: "Success!!", autohide: true, showInput: false });
          setPageCount(1);
          setPageLimit(10);
          setDate("");
          dispatch(
            getTrips({
              page: 1,
              limit: 10,
              searchType: "",
              searchValue: "",
              date: "",
            })
          );
        }
      }, 30000); // 30 seconds interval
    }
  }, []);



    // useEffect(() => {
    //     let data = {
    //         userId: localStorage.getItem('id'),
    //         email: localStorage.getItem('email'),
    //         name: localStorage.getItem('name'),
    //     }
    //     const socket = io.connect(socektDomain, { transports: ['websocket'], query: data })
    //     socket.on('connect', function () {
    //         console.log('connect', socket);
    //     });

    //     socket.on('data', function (msg) {
    //         if (msg.data.type == "trip") {
    //             console.log("msg", msg)
    //             //   dispatch(getTrips({
    //             //     page: pageCount,
    //             //     limit: count,
    //             //     searchType: '',
    //             //     searchValue: '',
    //             //     date: date
    //             // }))
    //         }
    //     });
    //     return () => {
    //         if (socket) {
    //             socket.disconnect()
    //         }
    //     }
    // }, [])

    return (
      <CustomPageLayout
        title="Trip Management"
        addTitleButton={localStorage.getItem("userType") === "distributor" ? "Create Trip Manually" : null}
        buttonChildern={
          localStorage.getItem("userType") === "distributor" && (
            <button
              className="myMapBtnGrey"
              disabled={fetchloading}
              onClick={() => {
                handleFetchAutoTrip();
              }}
            >
              {fetchloading ? (
                <span className="d-flex align-items-center">
                  <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>&nbsp; Creating...
                </span>
              ) : (
                "Add More Orders"
              )}
            </button>
          )
        }
        addHandleClick={() => {
          _handleClick("CreateTripModal");
        }}
      >
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-wrap">
            <div className="searcBox alignCen">
              <InputSearchDropDown
                type="text"
                name="searchValue"
                label="Filter Search"
                value={searchObj.searchValue}
                onChange={(e) => _handleChange(e)}
                placeholder="Search here..."
                style={{ zIndex: "10" }}
                onSubmit={() => triggerSearch("submit")}
                selectOptions={[
                  { label: "Trip Id", value: "tripId" },
                  { label: "Vehicle Number", value: "vehicle.vehicleNo" },
                ]}
                selectValue={searchObj.searchType}
                onSelectChange={(item) => {
                  setSearchObject({ ...searchObj, searchType: item.target.value, searchValue: "" });
                }}
                onClear={() => {
                  setSearchObject({ ...searchObj, searchValue: "" });
                  setPageCount(1);
                  setPageLimit(10);
                  triggerSearch("clear");
                }}
              />
            </div>
            {localStorage.getItem("userType") === 'distributor' && <div className="" style={{ width: "200px", marginLeft: "5px" }}>
              <DropDown
                label="Select Delivery Man"
                options={DriverListing?.length > 0 ? DriverListing.map((el) => ({ label: el.name, value: el._id })) : []}
                styles={colourStyles}
                height={"40px"}
                value={DriverListing?.length > 0 && searchObj?.filterValue != "" ? DriverListing.find((el) => el.value === searchObj.filterValue) : null}
                onChange={(value) => {
                  console.log(value)
                  setSearchObject({ ...searchObj, filterType: "vehicle.driverDetails._id", filterValue: value?.value || "" });
                  triggerSearch("submit", { ...searchObj, filterType: "vehicle.driverDetails._id", filterValue: value?.value || "" });
                }}
                isClearable={true}
              />
            </div>}
            <div className="searcBox alignCen">
              <InputSearch
                type="date"
                name="date"
                label="Trip Create Date"
                value={searchObj.date ? formatDate(searchObj.date) : null}
                onChange={(e) => _handleChange({ ...e, target: { value: e.target.value ? formatDateTime(e.target.value) : '', name: e.target.name } })}
                placeholder="Search here..."
                style={{ zIndex: "10" }}
                onSubmit={() => triggerSearch("submit")}
              />
            </div>
          </div>
          {localStorage.getItem("userType") === 'distributor' && <div className="searcBox alignCen">
            <button disabled={searchObj?.filterValue != "" ? false : true} className="myMapBtnGrey" style={{ marginTop: "29px" }} onClick={() => handleViewTrip()}>
              {loadingViewTrip ? (
                <span className="py-1 d-flex align-items-center">
                  <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>&nbsp; <span>Loading...</span>
                </span>
              ) : (
                "All Today Trips Map View"
              )}
            </button>
          </div>}
        </div>
        <div>
          {loader === false ? (
            listing && listing.length !== 0 ? (
              <div className="generic-table-wrapper mt20">
                <Table className="generic-table">
                  <thead className="bg-accent">
                    <tr>
                      <th>S. No</th>
                      <th>Trip ID</th>
                      <th>Date</th>
                      <th>Vehicle</th>
                      <th>Area Ids</th>
                      {/* <th>Departure Time</th>
                      <th>Arrival Time</th> */}
                      <th>Trip Creation Type</th>
                      <th>Estimated Duration</th>
                      <th>Trip Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-100">
                    {listing &&
                      listing.map((item, index) => {
                        return (
                          <>
                            <tr key={index}>
                              {/* (pageCount - 1) * pageLimit + index + 1 */}
                              <td style={{ color: "#007BC9" }}>{item.dayBasedSerialNumber ? item.dayBasedSerialNumber : "-"}</td>
                              <td>{item.tripId ? item.tripId : "-"}</td>
                              <td>{item.dateOfTrip ? moment(item.dateOfTrip).format("DD-MM-YYYY") : "-"}</td>
                              <td>
                                {item && item.vehicle && item.vehicle.vehicleNo ? item.vehicle.vehicleNo : "-"}
                                {user === "distributor" ? (
                                  <div className="tripButtons" style={{ paddingTop: "12px" }}>
                                    {item.tripStatus !== "unDelivered" && item.tripStatus !== "forceClosed" && item.tripStatus !== "in_transit" && item.tripStatus !== "completed" ? (
                                      "driverDetails" in item.vehicle ? (
                                        <button className="deAll" onClick={() => deallocateVehicle(item.tripId)}>
                                          De-allocate Delivery Man
                                          <i className="fa fa-info-circle circleInfo"></i>
                                          <div className="driverModal">
                                            <div className="driverR">
                                              <div className="d100">
                                                <div style={{ width: "30%", display: "flex", justifyContent: "flex-start" }}>
                                                  <span className="driverhead">Name :</span>
                                                </div>
                                                <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                                  <span className="driverValue">
                                                    {item && item.vehicle && item.vehicle.driverDetails && item.vehicle.driverDetails.name ? item.vehicle.driverDetails.name : "-"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="d100">
                                                <div style={{ width: "30%", display: "flex", justifyContent: "flex-start" }}>
                                                  <span className="driverhead"> Email :</span>
                                                </div>
                                                <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                                  <span className="driverValue">
                                                    {item && item.vehicle && item.vehicle.driverDetails && item.vehicle.driverDetails.name ? item.vehicle.driverDetails.email : "-"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="d100">
                                                <div style={{ width: "30%", display: "flex", justifyContent: "flex-start" }}>
                                                  <span className="driverhead"> Mobile :</span>
                                                </div>
                                                <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                                  <span className="driverValue">
                                                    {item && item.vehicle && item.vehicle.driverDetails && item.vehicle.driverDetails.name ? item.vehicle.driverDetails.mobile : "-"}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </button>
                                      ) : (
                                        <button
                                          className="allDriver"
                                          onClick={() => {
                                            _handleClick("AllocateDriverModal");
                                            sharefun(item);
                                          }}
                                        >
                                          Allocate Delivery Man
                                        </button>
                                      )
                                    ) : (
                                      <div className="customDriverDetails">
                                        <i className="fa fa-info-circle customeInfo"></i>
                                        <div className="driverModalCustom">
                                          <div className="driverR">
                                            <div className="d100">
                                              <div style={{ width: "30%", display: "flex", justifyContent: "flex-start" }}>
                                                <span className="driverhead">Name :</span>
                                              </div>
                                              <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                                <span className="driverValue">
                                                  {item && item.vehicle && item.vehicle.driverDetails && item.vehicle.driverDetails.name ? item.vehicle.driverDetails.name : "-"}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="d100">
                                              <div style={{ width: "30%", display: "flex", justifyContent: "flex-start" }}>
                                                <span className="driverhead"> Email :</span>
                                              </div>
                                              <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                                <span className="driverValue">
                                                  {item && item.vehicle && item.vehicle.driverDetails && item.vehicle.driverDetails.name ? item.vehicle.driverDetails.email : "-"}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="d100">
                                              <div style={{ width: "30%", display: "flex", justifyContent: "flex-start" }}>
                                                <span className="driverhead"> Mobile :</span>
                                              </div>
                                              <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                                <span className="driverValue">
                                                  {item && item.vehicle && item.vehicle.driverDetails && item.vehicle.driverDetails.name ? item.vehicle.driverDetails.mobile : "-"}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>{item.allAreaIds?.length > 0 ? item.allAreaIds.join(",").toString() : "-"}</td>
                              {/* <td>{item.tripDepartureTime ? moment(item.tripDepartureTime).format("DD-MM-YYYY hh:mm") : "-"}</td>
                              <td>{item.tripArrivalTime ? moment(item.tripArrivalTime).format("DD-MM-YYYY hh:mm") : "-"}</td> */}
                              <td>
                                {item.tripCreationType
                                  ? item.tripCreationType === "manualAutoTrip"
                                    ? "Unscheduled Optimised Trip"
                                    : item.tripCreationType === "cron job"
                                    ? "Scheduled Trip"
                                    : convertToTitleCase(item.tripCreationType)
                                  : "-"}
                              </td>
                              <td>
                                <>
                                  {item.eta
                                    ? Math.floor((item.eta / (60 * 60)) % 24) < 10
                                      ? "0" + Math.floor((item.eta / (60 * 60)) % 24) + " hours : "
                                      : Math.floor((item.eta / (60 * 60)) % 24) + " hours : "
                                    : "- "}

                                  {item.eta ? (Math.floor((item.eta / 60) % 60) < 10 ? "0" + Math.floor((item.eta / 60) % 60) + " mins" : Math.floor((item.eta / 60) % 60) + " mins") : "-"}
                                </>
                              </td>
                              <td>
                                <>
                                  {item.tripStatus && item.tripStatus === "initiated" ? <><span className="bdaSpanOrange">Initiated</span><br/><span>{item?.isSequenceBroken ? "(Sequence Broken)" : null}</span></> : ""}
                                  {item.tripStatus && item.tripStatus === "in_transit" ? <><span className="bdaSpanOrange">In-Transit</span><br/><span>{item?.isSequenceBroken ? "(Sequence Broken)" : null}</span></> : ""}
                                  {item.tripStatus && item.tripStatus === "completed" ? <><span className="bdaSpanOrange">Completed</span><br/><span>{item?.isSequenceBroken ? "(Sequence Broken)" : null}</span></> : ""}
                                  {item.tripStatus && item.tripStatus === "unDelivered" ? <><span className="bdaSpanRed">Not Delivered</span><br/><span>{item?.isSequenceBroken ? "(Sequence Broken)" : null}</span></> : ""}
                                  {item.tripStatus && item.tripStatus === "forceClosed" ? <><span className="bdaSpanRed">Force Closed</span><br/><span>{item?.isSequenceBroken ? "(Sequence Broken)" : null}</span></> : ""}
                                </>
                              </td>
                              <td>
                                <>
                                  {localStorage.getItem("userType") !== "driver" ? (
                                    item.tripStatus === "initiated" ? ( ""
                                      // <div className="tripIcElem">
                                      //   <i
                                      //     className="fa fa-pencil-square-o editIcon"
                                      //     onClick={() => {
                                      //       _handleClick("EditTripModal");
                                      //       shareData(item);
                                      //     }}
                                      //     style={{ marginRight: "10px" }}
                                      //   ></i>
                                      //   {/* <i className="fa fa-trash trashIcon" onClick={() => deleteTrip(item._id)}></i> */}
                                      // </div>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                  <span className="viewMap" onClick={() => openMap(item)}>
                                    View on Map
                                  </span>
                                </>
                              </td>
                            </tr>
                            <tr className="trBorder">
                              <td colSpan="12" style={{ padding: "0px" }}>
                                <div className="tripLocationListing">
                                  <div className=" carettDiv" style={{ position: "relative" }}>
                                    <i id={`slideLeft-${index}`} className="fa fa-caret-left carett" onClick={() => slide(index)}></i>
                                  </div>
                                  <div style={localStorage.getItem("userType") === "distributor" ? (item.tripStatus === "initiated" && moment(item.createdAt).isSame(moment(), "day") ? {'minWidth' : '84%'} : {'minWidth' : '94%'}) : {'minWidth' : '94%'}} className="wid95" id={`container-${index}`}>
                                    <div className="totalLocatuu">
                                      <span className="totalLocat"> Total Locations - {item && item.totalQuantity ? item.totalQuantity : 0}</span>
                                    </div>
                                    {item &&
                                      item.locations &&
                                      item.locations.length > 0 &&
                                      item.locations.map((data, ind) => {
                                        return ind !== 0 && !(data?.locationType === "source" || data?.locationType === "destination") ? (
                                          <div key={data.waypoint_index} className="tripRow">
                                            <div
                                              id={item && item.currentWaypoint === data.waypoint_index ? `scroll-${ind}` : null}
                                              className={item && item.currentWaypoint === ind && item.tripStatus !== "completed" ? "tripAddress tripAddressActive" : "tripAddress "}
                                            >
                                              {/* {data.isReached === true ?
                                                                                              <span className='reached'><b>Reached at {moment(data.time).format("HH:mm")}</b></span>
                                                                                              : ''} */}
                                              {data.isDelivered === true ? (
                                                <span className="reached">
                                                  <b>Arrived at {moment(data.time).format("DD-MM-YYYY hh:mm ")}</b>
                                                </span>
                                              ) : data.hasOwnProperty("isDelivered") && data.isDelivered === false ? (
                                                <span className="violated">Not Delivered</span>
                                              ) : (
                                                ""
                                              )}
                                              {data.isReached === true && data.isDelivered === true ?  ""  : data.locationDetails.orderType === 'emergency' ? (
                                                <span className="violated">
                                                <b>
                                                  New Order Added
                                                </b>
                                              </span>
                                              ) : ""}
                                              {data.isReached === true ? (
                                                <span className="slotTime">
                                                  <b>
                                                    Time Slot ({data.locationDetails && data.locationDetails.startSlotTime ? data.locationDetails.startSlotTime : "-"} -{" "}
                                                    {data.locationDetails && data.locationDetails.endSlotTime ? data.locationDetails.endSlotTime : "-"})
                                                  </b>
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              {data.isRescheduleForOtherDay === true || data.isRescheduleForToday === true ? (
                                                <span className="skipped">
                                                  <b>Skipped</b>
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              {data.isReached === true && data.isDelivered === true ? (
                                                data.deliveryViolation === true ? (
                                                  <span className="violated">
                                                    <b>Slot Violated</b>{" "}
                                                  </span>
                                                ) : (
                                                  <span className="timely">
                                                    <b>Delivered Timely</b>
                                                  </span>
                                                )
                                              ) : (
                                                ""
                                              )}
                                              <div>
                                                {/* <div className="pl-2 mb-1">
                                                  <span className="order-arrival-timing">Arrival Time-{moment(data.locationDetails.arrival).format("DD-MM-YYYY hh:mm")}</span>
                                                  <span className="order-arrival-timing">Departure Time- {moment(data.locationDepartureTime).format("DD-MM-YYYY hh:mm")}</span>
                                                </div> */}
                                                <div className="px-2">
                                                  <span className="bpclBlue">
                                                    {"locationDetails" in data
                                                      ? data.locationDetails && data.locationDetails.address
                                                        ? data.locationDetails.address
                                                        : data && data.origin && data.origin.address === ""
                                                        ? data.origin.address
                                                        : "-"
                                                      : ""}
                                                    <br />
                                                    <span style={item.currentWaypoint === ind ? { color: "#000" } : { color: "#000" }}>
                                                      {"locationDetails" in data ? (data.locationDetails && data.locationDetails.personName ? ` ${data.locationDetails.personName}` : "") : ""}{" "}
                                                    </span>
                                                    <span style={item.currentWaypoint === ind ? { color: "#000" } : { color: "#000" }}>
                                                      {"locationDetails" in data ? (data.locationDetails && data.locationDetails.mobile ? ` , ${data.locationDetails.mobile}` : "") : ""}
                                                    </span>
                                                    {data.isDelivered === true ? (
                                                      <p
                                                        onClick={() => {
                                                          _handleClick1("LocationModal");
                                                          shareData1(data);
                                                        }}
                                                        className={item.currentWaypoint === ind ? "activeLat" : "inactiveLat"}
                                                      >
                                                        <span className="deliveryPoint">{data && data.deliveryLocation ? "Delivered Point - " : ""} </span>
                                                        {data && data.deliveryLocation ? `${data.deliveryLocation.latitude} , ${data.deliveryLocation.longitude}` : ""}
                                                      </p>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {ind !== 0 && data.isDelivered === true ? (
                                                      <span>
                                                        <i
                                                          className={item.currentWaypoint === ind ? "fa fa-info-circle isDeIcWhite" : "fa fa-info-circle isDeIc"}
                                                          onClick={() => {
                                                            _handleClick1("DeliveryDetails");
                                                            shareDeliveryData(data, item);
                                                          }}
                                                        ></i>
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            {item.locations.filter((el) => el.hasOwnProperty("waypoint_index")).length - 1 !== ind ? (
                                              <div className="tripArrow">
                                                {item.currentWaypoint === data.waypoint_index ? (
                                                  <>
                                                    <i className="fa fa-arrow-right faRights "></i>
                                                  </>
                                                ) : data?.locationType === "source" || data?.locationType === "destination" ? (
                                                  ""
                                                ) : (
                                                  <i className="fa fa-arrow-right faRights "></i>
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        );
                                      })}
                                    {/* {item.tripStatus === "initiated" && (
                                      <div className="tripRow">
                                        <div
                                          className="tripAddress"
                                          onClick={() => {
                                            _handleClick("EditTripModal");
                                            shareData(item);
                                          }}
                                        >
                                          <div>
                                            <div>+</div>
                                            <div>Add More</div>
                                          </div>
                                        </div>
                                      </div>
                                    )} */}
                                  </div>
                                  {localStorage.getItem("userType") === "distributor" && moment(item.createdAt).isSame(moment(), "day") && item.tripStatus === "initiated" && <div
                                    className="addmore"
                                    onClick={() => {
                                      _handleClick("EditTripModal");
                                      shareData(item);
                                    }}
                                  >
                                    <div style={{fontSize : '25px'}}>+</div>
                                    <div>Add More <br/> Order</div>
                                  </div>}
                                  <div className="carettDiv">
                                    <i id={`slideRight-${index}`} className="fa fa-caret-right carett" onClick={() => slide(index)}></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <>
                <div className="noData">
                  <p>{message}</p>
                </div>
                <div className="noData" style={{ marginTop: "30px" }}>
                  <button className="myMapBtnGrey" onClick={() => window.location.reload()}>
                    Refresh Again
                  </button>
                </div>
              </>
            )
          ) : (
            <div className={loader === true ? "loaderDiv" : "loaderDivNone"}>
              <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
            </div>
          )}
        </div>
        <div>
          {loader === false && listing && listing?.length !== 0 ? (
            <div className="paginationDiv">
              <div className="entries">
                <span className="entrieSpan">Show Entries :</span>
                <Select menuPlacement="auto" value={pageLimit ? entries.filter(el => el.value == pageLimit) : null} onChange={(value) => handleChange(value)} options={entries} styles={colourStyles} />
              </div>
              <button disabled={pageCount === 1 ? true : false} className="paginationBtn" onClick={prevPage}>
                Previous
              </button>
              <button disabled={Math.floor((counts + pageLimit - 1) / pageLimit) === pageCount ? true : false} className="paginationBtn" onClick={nextPage}>
                Next
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {user == "distributor" ? (
          <>
            <CreateTrip closeModal={_closeModal} returnFun={returnFun} />
            <AllocateDriver sharedState={sharedState} closeModal={_closeModal} />
            <EditTrip sharedData={sharedData} paginationState={paginationState} closeModal={_closeModal} />
            <HandleInputOutput closeModal={_closeModal} />
            <Solutions closeModal={_closeModal} />
            <VehicleLoads closeModal={_closeModal} />
            <HandleOtp closeModal={_closeModal} otpData={otpData} />
          </>
        ) : (
          ""
        )}
        <DeliveryDetails closeModal={_closeModal} deliveryState={deliveryState} />
        <LocationModal closeModal={_closeModal} geo={geo} />
      </CustomPageLayout>
    );
}
export default TripMana;

export function convertToTitleCase(str) {
  return str.replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
            .replace(/^./, char => char.toUpperCase()); // Capitalize the first letter
}

const locationAreEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
  if (arr1?.length !== arr2?.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
}


const locationCount = (arr) => {
  let count = 0;
  arr.forEach((ele, index)=> {
    if(ele.locationType === 'waypoint') {
      count++;
    }else{
      if(ele.locationType !== 'source' && ele.locationType !== 'destination' && index != 0){
        count++;
      }
    }
  })

  return count;
}

function formatDateTime(inputDateTime) {
  return moment(inputDateTime)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 }) // Set time to 12:00:00
    .format("YYYY-MM-DD HH:mm:ss"); // Format as "YYYY-MM-DD HH:mm:ss"
}

function formatDate(dateTime) {
  // Split the date and time parts using a space as the separator
  const [date] = dateTime.split(' ');
  return date;
}