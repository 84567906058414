import React from 'react';
import { Router } from 'react-router-dom';
import history from './../services/history';
import CustomRoutes from './../routes';
import Tracking from '../views/Dashboard/NewTracking';
import CashMemo from '../views/Dashboard/CashMemo';
import CylinderMapping from '../views/Dashboard/CylinderMapping';

function App() {
  const query = new URLSearchParams(window.location.search);
  const urlKey = query.get('key') 
  const fileKey = query.get('fileKey')
  const cylinderKey = query.get('id');
  
if(urlKey){
  return(
    <Tracking/>
  )
}
if(fileKey){
  return(
    <CashMemo/>
  )
}
if(cylinderKey){
  return(
    <CylinderMapping/>
  )
}

  return (
    <Router history={history}>
      <CustomRoutes />
    </Router>
  );
}
export default App;