import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getUsers } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { CustomViewModal, Input, DropDown, showNotification, CustomTable } from "../../Utils/Inputs";
import { colourStyles, handlePostApi } from '../../../services/functions';

const timeHourSlots = [
  { label: '00', value: '0' },
  { label: '01', value: '1' },
  { label: '02', value: '2' },
  { label: '03', value: '3' },
  { label: '04', value: '4' },
  { label: '05', value: '5' },
  { label: '06', value: '6' },
  { label: '07', value: '7' },
  { label: '08', value: '8' },
  { label: '09', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
]

const timeSlots = [
  { label: "12:00 AM", value: "0" },
  { label: "01:00 AM", value: "1" },
  { label: "02:00 AM", value: "2" },
  { label: "03:00 AM", value: "3" },
  { label: "04:00 AM", value: "4" },
  { label: "05:00 AM", value: "5" },
  { label: "06:00 AM", value: "6" },
  { label: "07:00 AM", value: "7" },
  { label: "08:00 AM", value: "8" },
  { label: "09:00 AM", value: "9" },
  { label: "10:00 AM", value: "10" },
  { label: "11:00 AM", value: "11" },
  { label: "12:00 PM", value: "12" },
  { label: "01:00 PM", value: "13" },
  { label: "02:00 PM", value: "14" },
  { label: "03:00 PM", value: "15" },
  { label: "04:00 PM", value: "16" },
  { label: "05:00 PM", value: "17" },
  { label: "06:00 PM", value: "18" },
  { label: "07:00 PM", value: "19" },
  { label: "08:00 PM", value: "20" },
  { label: "09:00 PM", value: "21" },
  { label: "10:00 PM", value: "22" },
  { label: "11:00 PM", value: "23" },
  { label: "12:59 PM", value: "24" },
];


const defaultDriverPayload = {
  name: "",
  email: "",
  mobile: "",
  status: false,
  userType: "driver",
  maxTripDuration: "",
  earliestTripTime: "",
  latestTripTime: "",
  waitingTime: "",
  driverRestTime: "",
  areaId: [],
  secondaryAreaId: [],
  othersAreaId: [],
  eligibleForVehicle: "",
  time: "",
};
  
  const defaultDriverError = {
    maxTripDuration: "",
    earliestTripTime: "",
    latestTripTime: "",
    waitingTime: "",
    driverRestTime: "",
  };

const TripSettingsModal = ({ show, onClose, sharedData }) => {
    const dispatch = useDispatch()
    const driverlisting = useSelector((state) => state.getUserRed.usersList)

    const [driverPayload, setDriverPayload] = useState(defaultDriverPayload);
    const [error, setError] = useState(defaultDriverError);

    const handleChange = (e) => {
        setDriverPayload((prev) => {
          const updatedPayload = { ...prev, [e.target.name]: e.target.value };
          // Return the updated payload without changes to capacityInKg
          return updatedPayload;
        });
      };
  
      const handleDropChange = (value, name) => {
          // console.log(value, name);
          setDriverPayload((prev) => ({ ...prev, [name]: value.value }));
      };

    const handleValidationAddDriver = (driverPayload) => {
        let errors = {};
        if (!driverPayload.maxTripDuration) {
          errors.maxTripDuration = "Please enter max trip duration";
        }
        if (!driverPayload.earliestTripTime) {
          errors.earliestTripTime = "Please enter earliest trip time";
        }
        if (!driverPayload.latestTripTime) {
          errors.latestTripTime = "Please enter latest trip time";
        }
        if(!driverPayload.waitingTime) {
          errors.waitingTime = "Please enter waiting Time";
        }
        if(!driverPayload.driverRestTime) {
          errors.driverRestTime = "Please enter driver Rest Time";
        }
        setError(errors);
  
        return errors;
      };

    const handleEditDriver = async () => {
        const payload = {_id : sharedData.id , updateDetails : {...driverPayload}}
        const errors = handleValidationAddDriver({...driverPayload});
        if (Object.keys(errors).length > 0) {
        //   console.error("Validation errors:", errors);
          return;
        }
        // console.log("edit driver", driverPayload);
        try {
            const result = await handlePostApi("/user/editUser", { ...payload });
            // console.log("api result====>>>>", result);
            if (result.success) {
              showNotification({ message: result.message, title: "Success!!", autohide: true, showInput: false });
              dispatch(
                getUsers({
                  page: sharedData.pageCount,
                  limit: sharedData.pageLimit,
                  searchType: "",
                  searchValue: "",
                  userType: "driver",
                  type: "driver",
                })
              );
              setError(defaultDriverError);
              setDriverPayload(defaultDriverPayload);
              onClose();
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
    }

    const tableHeader = [
        "Max Trip Duration",
        "Earliest Trip Time",
        "Latest Trip Time",
        "Waiting Time",
        "Delivery Man Rest Time"
    ];

    const tableRows =
      (driverlisting && sharedData.id &&
        driverlisting?.length > 0 &&
        driverlisting
          .filter((item) => item._id === sharedData.id)
          .map((driverData, index) => {
            let items = [];
            items.push(
              <>{driverData.settings ? driverData.settings.maxTripDuration : "-"}</>,
              <>{driverData.settings ? driverData.settings.earliestTripTime : "-"}</>,
              <>{driverData.settings ? driverData.settings.latestTripTime : "-"}</>,
              <>{driverData.settings ? driverData.settings.waitingTime : "-"}</>,
              <>{driverData.settings ? driverData.settings.driverRestTime : "-"}</>
            );
            return items;
          })) ||
      [];

    useEffect(()=> {
      // console.log("sharedData", sharedData);
        if(sharedData.id){
          let temp = { ...defaultDriverPayload };
          let selectedDriver = driverlisting && driverlisting.length > 0 && driverlisting.find((item) => item._id === sharedData.id);
          if (selectedDriver) {
            temp.name = selectedDriver?.name;
            temp.email = selectedDriver?.email;
            temp.mobile = selectedDriver?.mobile;
            temp.userType = "driver";
            temp.maxTripDuration = selectedDriver.settings ? selectedDriver.settings?.maxTripDuration : "";
            temp.earliestTripTime = selectedDriver.settings ? selectedDriver.settings?.earliestTripTime : "";
            temp.latestTripTime = selectedDriver.settings ? selectedDriver.settings?.latestTripTime : "";
            temp.waitingTime = selectedDriver.settings ? selectedDriver.settings?.waitingTime : "";
            temp.driverRestTime = selectedDriver.settings ? selectedDriver.settings?.driverRestTime : "";
            temp.areaId = selectedDriver.settings && selectedDriver.settings?.areaId ? selectedDriver.settings.areaId : [];
            temp.secondaryAreaId = selectedDriver.settings && selectedDriver.settings?.secondaryAreaId ? selectedDriver.settings.secondaryAreaId : [];
            temp.othersAreaId = selectedDriver.settings && selectedDriver.settings?.othersAreaId ? selectedDriver.settings.othersAreaId : [];
            temp.eligibleForVehicle = selectedDriver.settings ? selectedDriver.settings.eligibleForVehicle : "";
          }
          setDriverPayload(temp);
        }else{
          setDriverPayload(defaultDriverPayload);
        }

    },[sharedData])

    return (
      <CustomViewModal
        heading={"Delivery Man Settings"}
        show={show}
        onClose={() => {
          onClose();
        }}
        size={"lg"}
      >
        <div>
          <CustomTable header={tableHeader} rows={tableRows ? tableRows : []} />
        </div>
        <Container fluid className="">
          <Row className="addBg p-2 mt-2">
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Max Trip Duration :{" "}
                </span>
                <div style={{ width: "200px" }}>
                  <DropDown
                    onChange={(value) => handleDropChange(value, "maxTripDuration")}
                    options={timeHourSlots}
                    styles={colourStyles}
                    error={error.maxTripDuration}
                    value={timeHourSlots.find((el) => el.value === driverPayload.maxTripDuration)}
                  />
                </div>
              </Row>
            </Col>
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Earliest Start Time :{" "}
                </span>
                <div style={{ width: "200px" }}>
                  <DropDown
                    onChange={(value) => handleDropChange(value, "earliestTripTime")}
                    options={timeSlots}
                    styles={colourStyles}
                    error={error.earliestTripTime}
                    value={timeSlots.find((el) => el.value === driverPayload.earliestTripTime)}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="addBg p-2 mt-2">
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Latest Trip End Time :{" "}
                </span>
                <div style={{ width: "200px" }}>
                  <DropDown
                    onChange={(value) => handleDropChange(value, "latestTripTime")}
                    options={timeSlots}
                    styles={colourStyles}
                    error={error.latestTripTime}
                    value={timeSlots.find((el) => el.value === driverPayload.latestTripTime)}
                  />
                </div>
              </Row>
            </Col>
            <Col className="">
            </Col>
          </Row>
          <Row className="addBg p-2 mt-2">
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Waiting Time (mins) :{" "}
                </span>
                <Input error={error.waitingTime} type="number" name="waitingTime" value={driverPayload.waitingTime} placeholder="Enter Waiting Time" onChange={(e) => handleChange(e)} />
              </Row>
            </Col>
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                Delivery Man Rest Time :{" "}
                </span>
                <Input error={error.driverRestTime} type="number" name="driverRestTime" value={driverPayload.driverRestTime} placeholder="Enter Rest Time" onChange={(e) => handleChange(e)} />
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 p-2 d-flex justify-content-around align-items-center">
            <button className="cBtn" onClick={() => handleEditDriver()}>
              Edit Settings
            </button>
          </Row>
        </Container>
      </CustomViewModal>    
    );
}

export default TripSettingsModal;