import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Sidebar from "../../views/Dashboard/Sidebar";
import Header from "../../views/Dashboard/Header";
import CustomBreadcrumb from "../../views/Dashboard/CustomBreadcrumb";
import './../../static/css/dashboard.css';
import logo from './../../static/images/logo3.png';
import history from './../../services/history';
import jwt_decode from "jwt-decode";

import AddressManag from '../../views/AddressManagement/AddressMana';
import Request from '../../views/Request/Request';
import VehicleSettings from '../../views/Settings/VehicleSetting/VehicleSettings';
import TripMana from '../../views/TripManagement/TripMana';
import TripMap from '../../views/Dashboard/TripMap';
import ProductSettings from '../../views/Settings/ProductSetting/ProductSettings';
import Preview from '../../views/Dashboard/Preview';
import Tracking from '../../views/Dashboard/Tracking';
import SlotSettings from '../../views/Dashboard/SlotSettings';
import LoadOptimizer from '../../views/Dashboard/LoadOptimizer';
import EstimatedTripDuration from '../../views/Dashboard/EstimatedTripDuration';
import UserManagement from '../../views/Dashboard/UserManagement';
import CustomerData from '../../views/Dashboard/CustomerData';
import DriverSettings from '../../views/Settings/DeliveryManSetting/DriverSettings';
import Profile from '../../views/Settings/Profile/Profile';
import CashMemo from '../../views/Dashboard/CashMemo';
import AreaManagement from '../../views/AreaManagement/AreaManagement';
import CustomerManagement from '../../views/ConsumerManagement/CustomerManagement';
import CylinderMapping from '../../views/Dashboard/CylinderMapping';
import CashMemoC from '../../views/CashMemo/CashMemoC';
import PlantDashboard from '../../views/Dashboard/PlantDashboard';
import Analytics from '../../views/Dashboard/Analytics';
import Footer from '../../views/Landing/Footer';
import TripMapping from '../../views/TripManagement/TripMapping';
import TripMapPreview from '../../views/TripManagement/TripMapPreview';

const Dashboard = (props) => {
  const userType = localStorage.getItem("userType") || "";
  const location = useLocation();
  const pathname = location.pathname;
  // console.log(location.pathname);
  const { permissions } = jwt_decode(localStorage.getItem("secretkey"))?.token || {};
  const routes = [
    {
      path: `${props.match.url}`,
      exact: true,
      component: userType !== "admin" ? (userType === "plant" ? Analytics : TripMana) : UserManagement,
    },
    {
      path: `${props.match.url}/trip-management`,
      component: PlantDashboard,
      permission: userType === "plant",
    },
    {
      path: `${props.match.url}/analytics`,
      component: Analytics,
      permission: userType !== 'plant',
    },
    {
      path: `${props.match.url}/address-management`,
      component: AddressManag,
      permission: permissions?.addresssManagement,
    },
    {
      path: `${props.match.url}/request`,
      component: Request,
      permission: permissions?.request,
    },
    {
      path: `${props.match.url}/trip-map`,
      component: TripMapping,
    },
    {
      path: `${props.match.url}/trip/preview`,
      component: TripMapPreview,
      permission: permissions?.tripPreview,
    },
    {
      path: `${props.match.url}/settings`,
      exact: true,
      component: VehicleSettings,
      permission: permissions?.setting,
    },
    {
      path: `${props.match.url}/settings/vehicle-settings`,
      exact: true,
      component: VehicleSettings,
      permission: permissions?.vehicleSettings,
    },
    {
      path: `${props.match.url}/settings/product-settings`,
      exact: true,
      component: ProductSettings,
      permission: permissions?.productsettings,
    },
    {
      path: `${props.match.url}/settings/slot-settings`,
      exact: true,
      component: SlotSettings,
      permission: permissions?.slotSettings,
    },
    {
      path: `${props.match.url}/settings/estimated-trip-duration`,
      exact: true,
      component: EstimatedTripDuration,
      permission: permissions?.estimatedTimeDuration,
    },
    {
      path: `${props.match.url}/settings/delivery-man-settings`,
      exact: true,
      component: DriverSettings,
      permission: permissions?.driverSettings,
    },
    {
      path: `${props.match.url}/area-management`,
      component: AreaManagement,
      permission: permissions?.areaManagement,
    },
    {
      path: `${props.match.url}/consumer-management`,
      component: CustomerManagement,
      permission: permissions?.consumerManagement,
    },
    {
      path: `${props.match.url}/cash-memo`,
      component: CashMemoC,
    },
    {
      path: `${props.match.url}/tracking`,
      component: Tracking,
    },
    {
      path: `${props.match.url}/cashmemo`,
      component: CashMemo,
    },
    {
      path: `${props.match.url}/load-optimizer`,
      component: LoadOptimizer,
    },
    {
      path: `${props.match.url}/customer`,
      component: CustomerData,
    },
    {
      path: `${props.match.url}/user-profile`,
      component: Profile,
    },
    {
      path: `${props.match.url}/v`,
      component: CylinderMapping,
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem('secretkey');
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    history.push("/");
  };

  useEffect(() => {
    const handleDropdownToggle = (e) => {
      e.stopPropagation();
      document.querySelector(".btn-group").classList.toggle('open');
    };

    const dropdowns = document.querySelectorAll("[data-toggle='dropdown']");
    dropdowns.forEach(dropdown => dropdown.addEventListener("click", handleDropdownToggle));

    const closeDropdowns = () => {
      document.querySelectorAll(".btn-group,.media").forEach(el => el.classList.remove('open'));
    };

    document.addEventListener("click", closeDropdowns);

    const menuToggle = document.getElementById("menuToggle");
    const handleMenuToggle = () => {
      document.querySelector(".sidebar").classList.toggle('open');
    };
    menuToggle.addEventListener("click", handleMenuToggle);

    const myBar = document.getElementById("myBar");
    const handleMyBarClick = () => {
      const sidebar = document.querySelector(".sidebar.open");
      if (sidebar) {
        sidebar.classList.toggle('mobile');
      }
    };
    myBar.addEventListener("click", handleMyBarClick);

    // Set height of custom modal overlay after 1 second
    const timeoutId = setTimeout(() => {
      const overlay = document.querySelector(".custom-modal-overlay");
      if (overlay) {
        overlay.style.height = "100vh";
      }
    }, 1000);

    return () => {
      dropdowns.forEach(dropdown => dropdown.removeEventListener("click", handleDropdownToggle));
      document.removeEventListener("click", closeDropdowns);
      menuToggle.removeEventListener("click", handleMenuToggle);
      myBar.removeEventListener("click", handleMyBarClick);
      clearTimeout(timeoutId); // Clear the timeout on cleanup
    };
  }, []);
  return (
    <>
      {pathname !== '/dashboard/trip/mobile-preview' && <Header handleClick={handleLogout} image={logo} />}
      <div className="main-content">
        {pathname !== '/dashboard/trip/mobile-preview' && <Sidebar image={logo} {...props} />}
        <div className="content-body">
          {pathname !== '/dashboard/trip/mobile-preview' && <CustomBreadcrumb {...props} />}
          <Switch>
            {routes.map(({ path, exact, component: Component, permission }, index) => 
              permission === undefined || permission ? (
                <Route key={index} path={path} exact={exact} render={(routeProps) => <Component {...routeProps} />} />
              ) : null
            )}
            <Redirect to='/dashboard' />
          </Switch>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;