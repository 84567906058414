import React from "react";
import { CustomViewModal } from "../../Utils/Inputs";
import { MarkerPreviewWithPolyGon } from "../../Utils/MapPreview";

const ProfileLocation = ({ show, onClose, sharedData }) => {

  const lat = sharedData?.geo?.lat || 0; // Default to 0 to prevent issues
  const lng = sharedData?.geo?.lng || 0;

  const center = [lng,lat]

  return (
    <CustomViewModal heading="Location" show={show} onClose={onClose} size="lg">
      <div style={{ maxHeight: "70vh", height: "70vh" }}>
        <MarkerPreviewWithPolyGon defaultCenter={center} isPolyGon={true} isCircleShape={true} />
      </div>
    </CustomViewModal>
  );
};

export default ProfileLocation;