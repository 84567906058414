export const BDA_LISTING = 'BDA_LISTING'
export const HIDE_SINGLE_DRIVER = 'HIDE_SINGLE_DRIVER'   
export const GET_ORDERS = 'GET_ORDERS' 
export const OPTI_ROUTE = 'OPTI_ROUTE' 
export const GET_VEHICLES = 'GET_VEHICLES'
export const CREATE_OPTI_ROUTE = 'CREATE_OPTI_ROUTE'
export const ADDRESS_LISTING = 'ADDRESS_LISTING'
export const SHOW_ADD_LOADER = 'SHOW_ADD_LOADER'
export const HIDE_ADD_LOADER = 'HIDE_ADD_LOADER'
export const ADDRESS_MSSG = 'ADDRESS_MSSG'
export const ADDRESS_COUNT = 'ADDRESS_COUNT'
export const REQUEST_LIST = 'REQUEST_LIST'
export const REQUEST_MSSG = 'REQUEST_MSSG'
export const REQUEST_COUNT = 'REQUEST_COUNT'
export const SHOW_REQ_LOADER = 'SHOW_REQ_LOADER'
export const HIDE_REQ_LOADER = 'HIDE_REQ_LOADER'
export const VEHICLES_LIST = 'VEHICLES_LIST'
export const SHOW_VEH_LOADER = 'SHOW_VEH_LOADER'
export const HIDE_VEH_LOADER = 'HIDE_VEH_LOADER'
export const VEH_MSSG = 'VEH_MSSG'
export const VEH_COUNT = 'VEH_COUNT'
export const SETTINGS = 'SETTINGS' 
export const SETTINGS_SHOW = 'SETTINGS_SHOW' 
export const SETTINGS_HIDE = 'SETTINGS_HIDE' 
export const SETTINGS_MSSG = 'SETTINGS_MSSG' 
export const TRIP_LISTING = 'TRIP_LISTING' 
export const TRIP_MSSG = 'TRIP_MSSG' 
export const TRIP_COUNT = 'TRIP_COUNT'
export const DRIVER_LISTING = 'DRIVER_LISTING'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const CHANGE_VEHICLE = 'CHANGE_VEHICLE'
export const VEHICLE_LOADER_TRUE = 'VEHICLE_LOADER_TRUE' 
export const VEHICLE_LOADER_FALSE = 'VEHICLE_LOADER_FALSE' 
export const RESET_CLUSTER= 'RESET_CLUSTER';
export const SLOT_LISTING = 'SLOT_LISTING'
export const SHOW_SLOT_LOADER = 'SHOW_SLOT_LOADER'
export const HIDE_SLOT_LOADER = 'HIDE_SLOT_LOADER'
export const SLOT_MESSAGE = 'SLOT_MESSAGE'
export const SLOT_COUNT = 'SLOT_COUNT'
export const CONSTRAINTS = 'CONSTRAINTS'
export const CONSTRAINTS_OUTPUT = 'CONSTRAINTS_OUTPUT'
export const GET_SOLUTIONS = 'GET_SOLUTIONS'
export const PLOT_BOXES = 'PLOT_BOXES'   
export const SOL_SHOW = 'SOL_SHOW' 
export const SOL_HIDE = 'SOL_HIDE' 
export const POINTS_SHOW = 'POINTS_SHOW' 
export const POINTS_HIDE = 'POINTS_HIDE' 
export const EMPTY_PLOT = 'EMPTY_PLOT'
export const USERS = 'USERS'
export const USERS_SHOW = 'USERS_SHOW'
export const USERS_HIDE = 'USERS_HIDE'
export const USERS_MSSG = 'USERS_MSSG'
export const USERS_COUNT = 'USERS_COUNT'   
export const AREA_LIST = 'AREA_LIST'
export const AREA_SHOW = 'AREA_SHOW'
export const AREA_HIDE = 'AREA_HIDE'
export const AREA_MSSG = 'AREA_MSSG'
export const CUSTOMER_LIST = 'CUSTOMER_LIST'
export const CUST_SHOW = 'CUST_SHOW'
export const CUST_HIDE = 'CUST_HIDE'
export const CUST_MSSG = 'CUST_MSSG'
export const CUST_COUNT = 'CUST_COUNT'
export const TRIP_LIST = 'TRIP_LIST'
export const TRIP_SHOW = 'TRIP_SHOW'
export const TRIP_HIDE = 'TRIP_HIDE'
export const TRIP_MESSG = 'TRIP_MESSG'
export const ROUTE_CHANGE = 'ROUTE_CHANGE'
export const ROUTE_SHOW = 'ROUTE_SHOW'
export const ROUTE_HIDE = 'ROUTE_HIDE'
export const ROUTE_MSSG = 'ROUTE_MSSG'
export const CYLINDER_DATA = 'CYLINDER_DATA'
export const CYLINDER_SHOW = 'CYLINDER_SHOW'
export const CYLINDER_HIDE = 'CYLINDER_HIDE'
export const CYLINDER_MSSG = 'CYLINDER_MSSG' 
export const CASH_COUNT = 'CASH_COUNT' 
export const CASH_LIST = 'CASH_LIST'
export const CASH_SHOW = 'CASH_SHOW'
export const CASH_HIDE = 'CASH_HIDE'
export const CASH_MESSAGE = 'CASH_MESSAGE'

export const DIST_ADMIN_LISTING = 'DIST_ADMIN_LISTING' 
export const DIST_ADMIN_LOADER = 'DIST_ADMIN_LOADER'
export const DIST_HIDE_ADMIN_LOADER = 'DIST_HIDE_ADMIN_LOADER'
export const DIST_MSSG = 'DIST_MSSG'
export const DIST_COUNT = 'DIST_COUNT'

export const FEEDBACK_DATA = 'FEEDBACK_DATA'
export const FEEDBACK_SHOW = 'FEEDBACK_SHOW'
export const FEEDBACK_HIDE = 'FEEDBACK_HIDE'
export const FEEDBACK_MSSG = 'FEEDBACK_MSSG' 

export const DISTRIBUTORACTIVITY_DATA = 'DISTRIBUTORACTIVITY_DATA'
export const DISTRIBUTORACTIVITY_SHOW = 'DISTRIBUTORACTIVITY_SHOW'
export const DISTRIBUTORACTIVITY_HIDE = 'DISTRIBUTORACTIVITY_HIDE'
export const DISTRIBUTORACTIVITY_MSSG = 'DISTRIBUTORACTIVITY_MSSG'
export const DISTRIBUTORACTIVITY_COUNT = 'DISTRIBUTORACTIVITY_COUNT'


export const GET_SINGLE_MAP_ROUTE = 'GET_SINGLE_MAP_ROUTE'
export const GET_MULTI_MAP_ROUTE = 'GET_MULTI_MAP_ROUTE'

