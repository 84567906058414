/* eslint-disable */
import React, { useState, useEffect, useRef  } from 'react';
import axios from 'axios'
import rectangle from './../../static/images/icon/sidebar/updated/rectangle.png'
import delivery from './../../static/images/icon/sidebar/updated/delivery-man.png'
import truck from './../../static/images/icon/sidebar/updated/Truck.gif'
import pfs from './../../static/images/icon/sidebar/updated/pfaAnimation.gif'
import beep from './../../static/images/icon/sidebar/updated/jingle.mpeg'
import moment from 'moment';
import { RoutePreviewDragable } from '../Utils/MapPreview';
import { url } from './../../services/requests';
import { showNotification } from '../Utils/Inputs';

const Tracking = ({assessKey}) => {
    let audio = new Audio(beep);
    const newlocationRef = useRef([]);
    const [trackData, setTrackData] = useState({})
    const [delivery_Instructions, setDeliveryInst] = useState(false)
    const [otp_modal, setotpmodal] = useState(false)
    const [auth, setAuth] = useState(false)
    const [loader, setLoader] = useState(false)
    
    const [newLatLng, setNewLatLng] = useState([])
    const [routeCoordinate, setRouteCoordinate] = useState([]);
    const [showmobileDetail, setShowmobileDetail] = useState(false)
    const [realTimeLocation, setRealTimeLocation] = useState({})
    const [mssg, setMssg] = useState('')
    const [base64, setBase64] = useState({
        basee: '',
        mssg: ''
    })
    const [otpValues, setOtpValues] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: ""
    });
    const [moveMarker, setMoveMarker] = useState(true)
    const [verifyStatus, setVerifyStatus] = useState(false)
    const [verify, setVerify] = useState({
        otp: '',
        tripId: '',
        accessKey: ''
    })
    const [addressState, setChangeAddress] = useState({
        otp: '',
        tripId: '',
        requestId: '',
        accessKey: '',
        endLocation: [],
        remarks: ''
    })
    const [delivery, setDelivery] = useState('')
    const [top_modal, set_top_modal] = useState(false)
    const [obj, setObj] = useState({})

    const closeotp_modal = () => {
        setotpmodal(!otp_modal)
    }
    const close_top_modal = () => {
        set_top_modal(!top_modal)
    }
    function handle_next_input(event) {
        var otp_inputs = document.querySelectorAll(".otp__digit")
        var mykey = "0123456789".split("")
        otp_inputs.forEach((_) => {
            _.addEventListener("keyup", handle_next_input)
        })

        let current = event.target
        let index = parseInt(current.classList[1].split("__")[2])
        current.value = event.key

        if (event.keyCode == 8 && index > 1) {
            current.previousElementSibling.focus()
        }
        if (index < 6 && mykey.indexOf("" + event.key + "") != -1) {
            var next = current.nextElementSibling;
            next.focus()
        }
        var _finalKey = ""
        for (let { value } of otp_inputs) {
            _finalKey += value
        }
        var splitOtp = _finalKey.split("")
        let temp = { ...otpValues }
        temp.otp1 = splitOtp[0]
        temp.otp2 = splitOtp[1]
        temp.otp3 = splitOtp[2]
        temp.otp4 = splitOtp[3]
        temp.otp5 = splitOtp[4]
        temp.otp6 = splitOtp[5]
        setOtpValues(temp)
    }
    const [schema, setSchema] = useState({
        eta: '',
        address: '',
        mobile: '',
        name: '',
        vehicleNumber: '',
        weight: '',
        deliveryTime: ''
    })

    /*=====Here save otp data=========*/
    // const openDeliveryModal = () => {
    //     let temp = { ...verify }
    //     let combineOtp = `${otpValues.otp1}` + `${otpValues.otp2}` + `${otpValues.otp3}` + `${otpValues.otp4}` + `${otpValues.otp5}` + `${otpValues.otp6}`
    //     temp.tripId = obj.tripId
    //     temp.otp = Number(combineOtp)
    //     temp.accessKey = obj.accessKey
    //     axios.post(`${url}/trip/checkOtp`, { ...temp })
    //         .then(response => {
    //             let status = response.data.success
    //             let mssg = response.data.message
    //             if (status) {
    //                 setMoveMarker(true)
    //                 setVerifyStatus(true)
    //                 setDeliveryInst(!delivery_Instructions)
    //             }
    //             else {
    //                 setVerifyStatus(false)
    //                 setMoveMarker(false)
    //                 // setOtpValues({
    //                 //     otp1: "",
    //                 //     otp2: "",
    //                 //     otp3: "", 
    //                 //     otp4: "",
    //                 //     otp5: "",
    //                 //     otp6: ""
    //                 // })
    //                 window.$.fn.show_notification({ message: `${mssg}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
    //                     if (result.type === 'confirm') {
    //                     }
    //                 })
    //             }
    //         })
    //         .catch(error => {
    //             console.log('error', error);
    //         })
    // }
    const save = () => {
        setDeliveryInst(false)
        setVerifyStatus(true)
        // setotpmodal(!otp_modal)
    }
    const finalSave = () => {
        let temp = { ...addressState }
        // temp.otp = `${otpValues.otp1}` + `${otpValues.otp2}` + `${otpValues.otp3}` + `${otpValues.otp4}` + `${otpValues.otp5}` + `${otpValues.otp6}`
        temp.tripId = obj.tripId
        temp.requestId = obj.requestId ? obj.requestId  : obj.request_Id
        temp.accessKey = obj.accessKey
        temp.endLocation = newlocationRef.current.length > 0  ? newlocationRef.current  : obj.endLocation
        temp.remarks = delivery
        // setotpmodal(false)

        // console.log('temp=========>>>>>', temp)   
        axios.post(`${url}/trip/changeAddress`, { ...temp })
            .then(response => {
                let status = response.data.success
                if (status === true) {
                  showNotification({ message: 'Address updated', title: "Success!!", type: "success", autohide: true, showInput: false });
                    window.location.reload()
                }else{
                  showNotification({ message: 'Instruction not updated', title: "Error!!", type: "error", autohide: true, showInput: false });
                }
                // window.location.reload()
            })
            .catch(error => {
              showNotification({ message: 'Instruction not updated', title: "Error!!", type: "error", autohide: true, showInput: false });
                console.log('error', error);
            })
    }
    /*=====Here open otp model =========*/
    // const openOTPModal = () => {
    //     setotpmodal(!otp_modal)
    // }
    const closeDelModal = () => {
        setDeliveryInst(!delivery_Instructions)
        // setotpmodal(!otp_modal)
    }
    useEffect(() => {
        // console.log('tracking====>', obj)
        setLoader(true)
        const query = new URLSearchParams(window.location.search);
        const getToken = query.get('key')
        let finalObj = { accessKey: getToken || assessKey }
        axios.post(`${url}/auth/checkAccessKey`, { ...finalObj })
            .then(response => {
                let temp = { ...schema }
                let status = response && response.data ? response.data?.isRescheduled == true ? false : (response.data.success) : ''
                // let status = false;
                let message = response && response.data ? response.data.message : ''
                // let message = "Session Expired."
                let trackingData = response && response.data ? response.data.data : {}
                // let trackingData = resfalse.data
                let cashmemo = response && response.data ? response.data.data.cashmemo : ''
                // let cashmemo = ''
                setObj(trackingData)
                temp.address = trackingData && trackingData.destinationAddress ? trackingData.destinationAddress : ''
                temp.mobile = trackingData && trackingData.driverDetails ? trackingData.driverDetails.mobile : ''
                temp.name = trackingData && trackingData.driverDetails ? trackingData.driverDetails.name : ''
                temp.eta = trackingData && trackingData.eta ? trackingData.eta : '-'
                temp.vehicleNumber = trackingData && trackingData.vehicleNumber ? trackingData.vehicleNumber : '-'
                temp.weight = trackingData && trackingData.weight ? trackingData.weight : '-'
                temp.deliveryTime = trackingData && trackingData.deliveryTime ? trackingData.deliveryTime : '-'
                setSchema(temp)
                let vehNum = trackingData && trackingData.vehicleNumber ? trackingData.vehicleNumber : '-'
                if (status === true) {
                    // console.log('true runn===>>>')
                    setLoader(false)
                    setAuth(true)
                    axios.post(`${url}/track/getLocation`, { vehicleNumber: vehNum })
                        .then(response => {
                            let vehLat = response.data.data.latitude
                            let vehLng = response.data.data.longitude
                            setRealTimeLocation({latitude: vehLat, longitude: vehLng })

                        })
                        .catch(error => {
                            console.log('error', error);
                        })
                    setInterval(() => {
                        axios.post(`${url}/track/getLocation`, { vehicleNumber: vehNum })
                            .then(response => {
                                let vehLat = response.data.data.latitude
                                let vehLng = response.data.data.longitude
                                setRealTimeLocation({latitude: vehLat, longitude: vehLng })
                            })
                            .catch(error => {
                                console.log('error', error);
                            })
                    }, 30000)
                    setTrackData(trackingData)
                    const routeCoordinat = [
                        {location : trackingData.startLocation},
                        {location : trackingData.endLocation},
                    ]
                    setRouteCoordinate(routeCoordinat);
                    setChangeAddress((prev) => ({
                        ...prev,
                        endLocation: trackingData.destinationAddress,
                      }));
                      


                }
                else {
                    if (message === "Session Expired.") {
                        setLoader(false)
                        setAuth(false)
                        setMoveMarker(false)
                        setMssg('Your Cylinder has been Delivered..')
                        let data = { fileName: cashmemo }
                        axios.post(`${url}/general/getImageToBlob`, { ...data })
                            .then(response => {
                                let base = response && response.data && response.data.data ? response.data.data : ''
                                let message = response && response.data && response.data.message ? response.data.message : ''
                                let blob = b64toBlob(base, 'application/pdf');
                                let ur = URL.createObjectURL(blob);
                                let temp = { ...base64 }
                                temp.basee = ur
                                temp.mssg = message
                                setBase64(temp)

                            })
                            .catch(error => {
                                console.log('error', error);
                            })
                    }else if(message === "Order is rescheduled"){
                        setLoader(false)
                        setAuth(false)
                        setMssg('Your order is rescheduled successfully..')
                    }
                    else {
                        setLoader(false)
                        setAuth(false)
                        setMssg('No Data found..')
                    }
                }

            })
            .catch(error => {
                setLoader(false)
                setAuth(false)
                setMssg('No Data found..')
                console.log('error', error);
            })
    }, [moveMarker, assessKey])


    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const downloadCash = () => {
        if (base64.basee !== '' || base64.basee !== undefined) {
            window.open(base64.basee, "_blank")
        }
        else {
            window.$.fn.show_notification({ message: `${base64.mssg}`, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    useEffect(() => {
        $(document.body).one('click', function (e) {
            audio.play()
        });
    }, [])

    return (
      <>
        <div className="mapOuter">
          {loader === false ? (
            auth ? (
              <>
                <RoutePreviewDragable
                  realTimeLocation={realTimeLocation}
                  routeCoordinates={routeCoordinate}
                  isDragable={verifyStatus}
                  newMarkerDetail={(location) => {
                    console.log("location", location);
                    newlocationRef.current = location;
                    // setRouteCoordinate((prevState) => [
                    //     ...prevState.slice(0, prevState.length - 1), // Keep all but the last element
                    //     { location: location }, // Replace last element with the new location
                    //   ]);
                  }}
                />
                <>
                  <div className="deliverDetails">
                    <div className="ddcAbs">
                      <span className="ddcAbsSpan">Drag and Drop your Location to change within 500 m .</span>
                    </div>
                    <div className="ddc">
                      <span className="ddcSpan">Estimated Time of Arrival</span>
                      <span className="ddcVal">
                        {schema.eta !== "-" ? (
                          <>
                            {schema.eta
                              ? Math.floor((schema.eta / (60 * 60)) % 24) < 10
                                ? "0" + Math.floor((schema.eta / (60 * 60)) % 24) + " hours : "
                                : Math.floor((schema.eta / (60 * 60)) % 24) + " hours : "
                              : "- "}
                            {schema.eta ? (Math.floor((schema.eta / 60) % 60) < 10 ? "0" + Math.floor((schema.eta / 60) % 60) + " mins" : Math.floor((schema.eta / 60) % 60) + " mins") : "-"}
                          </>
                        ) : (
                          <span>Few Minutes</span>
                        )}
                      </span>
                    </div>
                    <div className="ddc">
                      <span className="ddcSpan">Address</span>
                      <span className="ddcVal ddcEllip">{schema.address}</span>
                    </div>
                    <div className="ddc">
                      <span className="ddcSpan">Rider Details / Vehicle Number</span>
                      <span className="ddcVal">
                        {schema.name} , {schema.mobile}
                        <a href={`tel:${schema.mobile}`}>
                          <i className="fa fa-phone faPhone"></i>
                        </a>
                      </span>
                      <span className="ddcVal">{schema.vehicleNumber} </span>
                    </div>
                    <div className="ddc">
                      {mssg !== "Your Cylinder has been Delivered.." ? <span className="ddcSpan">Add Delivery Instructions and Drag Marker</span> : ""}
                      {mssg !== "Your Cylinder has been Delivered.." ? (
                        verifyStatus === true ? (
                          <button className="ddcBtn" style={{ background: "green" }} onClick={() => finalSave()}>
                            {" "}
                            Save
                          </button>
                        ) : (
                          <button className="ddcBtn" onClick={() => setDeliveryInst(!delivery_Instructions)}>
                            Edit
                          </button>
                        )
                      ) : (
                        // <button className='ddcBtn' onClick={() => openOTPModal()} >Enter OTP</button>
                        <span style={{ color: "green", fontWeight: "900" }}>Delivered</span>
                      )}
                    </div>
                  </div>
                  <div className="deliverDetailsMobile">
                    <div className="ddcAbs">
                      <span className="ddcAbsSpan">Drag and Drop your Location to change within 500 m .</span>
                    </div>
                    <div className="dd50">
                      <div className="ddc">
                        <span className="ddcSpan">Estimated Time of Arrival</span>
                        <span className="ddcVal">
                          {schema.eta !== "-" ? (
                            <>
                              {schema.eta
                                ? Math.floor((schema.eta / (60 * 60)) % 24) < 10
                                  ? "0" + Math.floor((schema.eta / (60 * 60)) % 24) + " hours : "
                                  : Math.floor((schema.eta / (60 * 60)) % 24) + " hours : "
                                : "- "}
                              {schema.eta ? (Math.floor((schema.eta / 60) % 60) < 10 ? "0" + Math.floor((schema.eta / 60) % 60) + " mins" : Math.floor((schema.eta / 60) % 60) + " mins") : "-"}
                            </>
                          ) : (
                            <span>Few Minutes</span>
                          )}
                        </span>
                      </div>
                      <div className="ddc">
                        <span className="ddcSpan">
                          <b>Address</b>
                        </span>
                        <span className="ddcVal ddcEllip">{schema.address}</span>
                      </div>
                    </div>

                    <div className="dd50">
                      <div className="ddc">
                        <span className="ddcSpan">Rider Details / Vehicle Number</span>
                        <span className="ddcVal">
                          {schema.name} , {schema.mobile}
                          <a href={`tel:${schema.mobile}`}>
                            <i className="fa fa-phone faPhone"></i>
                          </a>
                        </span>
                        <span className="ddcVal">{schema.vehicleNumber}</span>
                      </div>
                      <div className="ddc">
                        {mssg !== "Your Cylinder has been Delivered.." ? <span className="ddcSpan">Add Delivery Instructions and Drag Marker</span> : ""}
                        {mssg !== "Your Cylinder has been Delivered.." ? (
                          verifyStatus === true ? (
                            <button className="ddcBtn" style={{ background: "green" }} onClick={() => finalSave()}>
                              {" "}
                              Save
                            </button>
                          ) : (
                            <button className="ddcBtn" onClick={() => setDeliveryInst(!delivery_Instructions)}>
                              Edit
                            </button>
                          )
                        ) : (
                          // <button className='ddcBtn' onClick={() => openOTPModal()} >Enter OTP to edit</button>
                          <span style={{ color: "green", fontWeight: "900" }}>Delivered</span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
                {otp_modal && (
                  <div className="recover-moadal">
                    <div className="recover-overlay"></div>
                    <div className="customModasl">
                      <i className="fa fa-close ddrclosea" onClick={() => closeotp_modal()}></i>
                      <div className="orangeHeader"></div>
                      <div className="customReactAddress">
                        <span>Enter OTP </span>
                      </div>
                      <div className="otpfieldEelm">
                        <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__1" />
                        <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__2" />
                        <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__3" />
                        <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__4" />
                        <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__5" />
                        <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__6" />
                      </div>
                      <div className="customReactAddress2" style={{ height: "90px" }}>
                        <button className="CRB" onClick={() => openDeliveryModal()}>
                          Verify
                        </button>
                      </div>
                      <div className="orangeHeaderS"></div>
                    </div>
                  </div>
                )}
                {delivery_Instructions && (
                  <div className="recover-moadal">
                    {/* <div className='recover-overlay' ></div> */}
                    <div className="customModasl">
                      <i className="fa fa-close ddrclosea" onClick={() => closeDelModal()}></i>
                      <div className="orangeHeader"></div>
                      <div className="customReactAddress">
                        <span>Delivery Instructions</span>
                      </div>
                      <div className="customReactAddress1">
                        <div className="cRAdd70" style={{ marginBottom: "7px" }}>
                          {/* <span>1.</span> */}
                          <input type="text" onChange={(e) => setDelivery(e.target.value)} placeholder="Enter Delivery Instructions..." />
                        </div>
                        {/* <div className='cRAdd70'>
                                                <span>2.</span>
                                                <input type='text' placeholder='Enter Delivery Instructions...' />
                                            </div> */}
                      </div>
                      <div className="customReactAddress2">
                        <button className="CRB" onClick={save}>
                          Save
                        </button>
                      </div>
                      <div className="orangeHeaderS"></div>
                    </div>
                  </div>
                )}
                {top_modal && (
                  <div className="recover-moadal">
                    <div className="customRMssg">
                      {/* <i className="fa fa-close top_modal_close" onClick={() => close_top_modal()}></i> */}
                      <span>Marker cannot be dragged more than 500 m from Delivery Location.</span>
                    </div>
                  </div>
                )}
              </>
            ) : mssg === "Your Cylinder has been Delivered.." ? (
              <div className="nodatafound">
                <span className="hiddenOnMobile" style={{ fontWeight: "900" }}>
                  {mssg}
                </span>
                <>
                  <div className="image-container">
                    <div className="cover-top"></div>
                    <div className="Delivered-msg">Your Cylinder has been Delivered..</div>
                    <div className="Delivered-bttn">
                      <button onClick={() => setShowmobileDetail(!showmobileDetail)}>{!showmobileDetail ? "Show Details" : "Hide Details"}</button>
                    </div>
                    <img src={pfs} className="pfsImg" />
                    <div className="cover-bottom"></div>
                  </div>

                  <>
                    <div className={`deliverDetailsTop ${showmobileDetail ? "" : "hiddenOnMobileTop"}`}>
                      <div className="ddc ddcLeft" style={{ width: "50%" }}>
                        <span className="ddcSpan ddcPadd">Address</span>
                        <span className="ddcVal ddcEllip ddcPadd">{schema.address}</span>
                      </div>
                      <div className="ddc" style={{ width: "50%", border: "none" }}>
                        <span className="ddcSpan ">Rider Details</span>
                        <span className="ddcVal">
                          {schema.name} , {schema.mobile}
                          <a href={`tel:${schema.mobile}`}>
                            <i className="fa fa-phone faPhone ddcPadd"></i>
                          </a>
                          &nbsp; &nbsp; &nbsp;
                          {(obj.requestId || obj.request_Id) && obj.tripId ? (
                            <button
                              className="feedback-btn"
                              onClick={() =>
                                window.open(`${process.env.REACT_APP_URL}feedback/${obj.requestId ? obj.requestId : obj.request_Id}_${obj.tripId || "-"}`, "_blank", "noopener,noreferrer")
                              }
                            >
                              {"Give us feedback"}
                            </button>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="deliverDetails">
                      <div className="ddc">
                        <span className="ddcSpan">Vehicle Number , Cylinder Weight</span>
                        <span className="ddcVal ddcEllip">
                          {schema && schema.vehicleNumber ? schema.vehicleNumber : "-"}, {schema && schema.weight ? schema.weight + " kg" : "-"}{" "}
                        </span>
                      </div>
                      <div className="ddc">
                        {mssg !== "Your Cylinder has been Delivered.." ? <span className="ddcSpan">Add Delivery Instructions and Drag Marker</span> : ""}
                        {mssg !== "Your Cylinder has been Delivered.." ? (
                          verifyStatus === true ? (
                            <button className="ddcBtn" style={{ background: "green" }} onClick={() => finalSave()}>
                              {" "}
                              Save
                            </button>
                          ) : (
                            <button className="ddcBtn" onClick={() => openOTPModal()}>
                              Enter OTP
                            </button>
                          )
                        ) : (
                          <>
                            <span style={{ color: "green", fontWeight: "900" }}>
                              Delivered{" "}
                              <span style={{ color: "#000", fontWeight: "400" }}>
                                {" "}
                                at <span style={{ color: "green", fontWeight: "900" }}>{schema && schema.deliveryTime ? moment(schema.deliveryTime).format("h:mm a") : "-"}</span>{" "}
                              </span>
                              <button className="allButtons cashMmeoBtn " onClick={() => downloadCash()}>
                                Download Cash Memo
                              </button>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="deliverDetailsMobile" style={{ display: showmobileDetail ? "block" : "none" }}>
                      <div className="dd50">
                        <div className="ddc">
                          <span className="ddcSpan">
                            <b>Vehicle Number</b>
                          </span>
                          <span className="ddcVal ddcEllip">{schema.vehicleNumber}</span>
                        </div>
                        <div className="ddc">
                          <span className="ddcSpan">Cylinder Weight</span>
                          <span className="ddcVal">{schema && schema.weight ? schema.weight + " kg" : "-"}</span>
                        </div>
                      </div>

                      <div className="dd50">
                        <div className="ddc">
                          <span style={{ color: "green", fontWeight: "900" }}>
                            Delivered{" "}
                            <span style={{ color: "#000", fontWeight: "400" }}>
                              {" "}
                              at <span style={{ color: "green", fontWeight: "900" }}>{schema && schema.deliveryTime ? moment(schema.deliveryTime).format("h:mm a") : "-"}</span>
                            </span>
                          </span>
                        </div>
                        <div className="ddc">
                          {mssg !== "Your Cylinder has been Delivered.." ? <span className="ddcSpan">Add Delivery Instructions and Drag Marker</span> : ""}
                          {mssg !== "Your Cylinder has been Delivered.." ? (
                            verifyStatus === true ? (
                              <button className="ddcBtn" style={{ background: "green" }} onClick={() => finalSave()}>
                                {" "}
                                Save
                              </button>
                            ) : (
                              <button className="ddcBtn" onClick={() => openOTPModal()}>
                                Enter OTP to edit
                              </button>
                            )
                          ) : (
                            <button className="allButtons cashMmeoBtn cashMmeoBtnLeft" onClick={() => downloadCash()}>
                              Download Cash Memo
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </>
              </div>
            ) : mssg === "Your order is rescheduled successfully.." ? (
              <div className="nodatafound">
                <div className="feedback-Thankyou-form-container" style={{ background: "#0000ff14", border: "2px solid #007bc9" }}>
                  <CheckBadge />
                  <br />
                  <span style={{ fontSize: "1.5rem", fontWeight: "900", color: "#007bc9" }}>{mssg}</span>
                </div>
              </div>
            ) : (
              <div className="nodatafound">
                <span className="hiddenOnMobile" style={{ fontWeight: "900" }}>
                  {mssg}
                </span>
              </div>
            )
          ) : (
            // <div className="nodatafound">
            //   <span className="hiddenOnMobile" style={{ fontWeight: "900" }}>
            //     {mssg}
            //   </span>
            //   {mssg === "Your Cylinder has been Delivered." ? (
            //     <>
            //       <div className="image-container">
            //           <div className='cover-top'></div>
            //           <div className='Delivered-msg'>Your Cylinder has been Delivered..</div>
            //           <div className='Delivered-bttn'><button onClick={()=>  setShowmobileDetail(!showmobileDetail)}>{!showmobileDetail ? 'Show Details' : 'Hide Details'}</button></div>
            //         <img src={pfs} className="pfsImg" />
            //         <div className='cover-bottom'></div>
            //       </div>

            //       <>
            //         <div className={`deliverDetailsTop ${showmobileDetail ? "" : "hiddenOnMobileTop" }`}>
            //           <div className="ddc ddcLeft" style={{ width: "50%" }}>
            //             <span className="ddcSpan ddcPadd">Address</span>
            //             <span className="ddcVal ddcEllip ddcPadd">{schema.address}</span>
            //           </div>
            //           <div className="ddc" style={{ width: "50%", border: "none" }}>
            //             <span className="ddcSpan ">Rider Details</span>
            //             <span className="ddcVal">
            //               {schema.name} , {schema.mobile}
            //               <a href={`tel:${schema.mobile}`}>
            //                 <i className="fa fa-phone faPhone ddcPadd"></i>
            //               </a>
            //               &nbsp; &nbsp; &nbsp;
            //               {(obj.requestId || obj.request_Id) && obj.tripId ? <button className='feedback-btn' onClick={()=> window.open(`${process.env.REACT_APP_URL}feedback/${obj.requestId ? obj.requestId  : obj.request_Id}_${obj.tripId || '-'}`, "_blank", "noopener,noreferrer")}>{'Give us feedback'}</button> : ""}
            //             </span>
            //           </div>
            //         </div>
            //         <div className="deliverDetails">
            //           <div className="ddc">
            //             <span className="ddcSpan">Vehicle Number , Cylinder Weight</span>
            //             <span className="ddcVal ddcEllip">
            //               {schema && schema.vehicleNumber ? schema.vehicleNumber : "-"}, {schema && schema.weight ? schema.weight + " kg" : "-"}{" "}
            //             </span>
            //           </div>
            //           <div className="ddc">
            //             {mssg !== "Your Cylinder has been Delivered.." ? <span className="ddcSpan">Add Delivery Instructions and Drag Marker</span> : ""}
            //             {mssg !== "Your Cylinder has been Delivered.." ? (
            //               verifyStatus === true ? (
            //                 <button className="ddcBtn" style={{ background: "green" }} onClick={() => finalSave()}>
            //                   {" "}
            //                   Save
            //                 </button>
            //               ) : (
            //                 <button className="ddcBtn" onClick={() => openOTPModal()}>
            //                   Enter OTP
            //                 </button>
            //               )
            //             ) : (
            //               <>
            //                 <span style={{ color: "green", fontWeight: "900" }}>
            //                   Delivered{" "}
            //                   <span style={{ color: "#000", fontWeight: "400" }}>
            //                     {" "}
            //                     at <span style={{ color: "green", fontWeight: "900" }}>{schema && schema.deliveryTime ? moment(schema.deliveryTime).format("h:mm a") : "-"}</span>{" "}
            //                   </span>
            //                   <button className="allButtons cashMmeoBtn " onClick={() => downloadCash()}>
            //                     Download Cash Memo
            //                   </button>
            //                 </span>
            //               </>
            //             )}
            //           </div>
            //         </div>
            //         <div className="deliverDetailsMobile" style={{ display: showmobileDetail ? "block" : "none" }}>
            //           <div className="dd50">
            //             <div className="ddc">
            //               <span className="ddcSpan">
            //                 <b>Vehicle Number</b>
            //               </span>
            //               <span className="ddcVal ddcEllip">{schema.vehicleNumber}</span>
            //             </div>
            //             <div className="ddc">
            //               <span className="ddcSpan">Cylinder Weight</span>
            //               <span className="ddcVal">{schema && schema.weight ? schema.weight + " kg" : "-"}</span>
            //             </div>
            //           </div>

            //           <div className="dd50">
            //             <div className="ddc">
            //               <span style={{ color: "green", fontWeight: "900" }}>
            //                 Delivered{" "}
            //                 <span style={{ color: "#000", fontWeight: "400" }}>
            //                   {" "}
            //                   at <span style={{ color: "green", fontWeight: "900" }}>{schema && schema.deliveryTime ? moment(schema.deliveryTime).format("h:mm a") : "-"}</span>
            //                 </span>
            //               </span>
            //             </div>
            //             <div className="ddc">
            //               {mssg !== "Your Cylinder has been Delivered.." ? <span className="ddcSpan">Add Delivery Instructions and Drag Marker</span> : ""}
            //               {mssg !== "Your Cylinder has been Delivered.." ? (
            //                 verifyStatus === true ? (
            //                   <button className="ddcBtn" style={{ background: "green" }} onClick={() => finalSave()}>
            //                     {" "}
            //                     Save
            //                   </button>
            //                 ) : (
            //                   <button className="ddcBtn" onClick={() => openOTPModal()}>
            //                     Enter OTP to edit
            //                   </button>
            //                 )
            //               ) : (
            //                 <button className="allButtons cashMmeoBtn cashMmeoBtnLeft" onClick={() => downloadCash()}>
            //                   Download Cash Memo
            //                 </button>
            //               )}
            //             </div>
            //           </div>
            //         </div>
            //       </>
            //     </>
            //   ) : mssg === "Your Cylinder has been Delivered.." ? <div>

            //   </div> : (
            //     ""
            //   )}
            // </div>
            <div className={loader === true ? "loaderDiv" : "loaderDivNone"}>
              <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
            </div>
          )}
        </div>
      </>
    );
};

export default Tracking;




const resTrue = {
    "data": {
        "_id": "67a45a26b8e90754712949bc",
        "accessKey": "1uqb5489",
        "vehicleNumber": "UP32DU7236",
        "startLocation": [
            80.8874132,
            26.8463085
        ],
        "endLocation": [
            80.8756231,
            26.842555
        ],
        "status": true,
        "email": "0",
        "tripId": "TR7832",
        "mobile": "7524817181",
        "otpVerified": false,
        "originAddress": "356/SP-221 0 ,GROUND FLOOR SONAPURAM ,LUCKNOW Uttar Pradesh ,ROAD , PIN-226017",
        "destinationAddress": "F 3264 ,RAJA JI PURAM ,LUCKNOW , , PIN-0",
        "eta": 1034,
        "driverDetails": {
            "_id": "67552d2f074abcdd12fa0d14",
            "email": "avadesh19492@gmail.com",
            "mobile": "9473872788",
            "name": "AVADESH KUMAR"
        },
        "createdAt": "2025-02-06T06:43:50.100Z",
        "updatedAt": "2025-02-06T07:21:07.919Z",
        "__v": 0
    },
    "success": true
}


const resfalse = {
    "data": {
        "_id": "679c7a47fb011ab90aca1ad3",
        "accessKey": "stxa4840",
        "vehicleNumber": "UP16DK1001",
        "startLocation": [
            72.8399163,
            18.9365751
        ],
        "endLocation": [
            80.91436196118593,
            26.799762130113248
        ],
        "status": false,
        "email": "0",
        "tripId": "TR1181",
        "mobile": "9026768653",
        "otpVerified": false,
        "originAddress": "MAMTA GAS SERVICE ADDRESS (Lucknow)",
        "destinationAddress": "E/376 SECT. I LDA COLONY LUCKNOW 0",
        "eta": 1070,
        "driverDetails": {
            "_id": "66b22ca6e45ad87258d16de7",
            "email": "test@gmail.com",
            "mobile": "9455125992",
            "name": "Arvind Kumar"
        },
        "createdAt": "2025-01-31T07:22:47.040Z",
        "updatedAt": "2025-01-31T07:34:20.238Z",
        "__v": 0,
        "deliveryTime": "2025-01-31T07:34:20.211Z",
        "weight": 14.2
    },
    "message": "Session Expired.",
    "success": false
}


const realTime = {
    "data": {
        "latitude": "26.84651",
        "longitude": "80.8700586"
    },
    "success": true
}

const CheckBadge = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 256 256">
          <path fill="green" d="M225.86 102.82c-3.77-3.94-7.67-8-9.14-11.57c-1.36-3.27-1.44-8.69-1.52-13.94c-.15-9.76-.31-20.82-8-28.51s-18.75-7.85-28.51-8c-5.25-.08-10.67-.16-13.94-1.52c-3.56-1.47-7.63-5.37-11.57-9.14C146.28 23.51 138.44 16 128 16s-18.27 7.51-25.18 14.14c-3.94 3.77-8 7.67-11.57 9.14c-3.25 1.36-8.69 1.44-13.94 1.52c-9.76.15-20.82.31-28.51 8s-7.8 18.75-8 28.51c-.08 5.25-.16 10.67-1.52 13.94c-1.47 3.56-5.37 7.63-9.14 11.57C23.51 109.72 16 117.56 16 128s7.51 18.27 14.14 25.18c3.77 3.94 7.67 8 9.14 11.57c1.36 3.27 1.44 8.69 1.52 13.94c.15 9.76.31 20.82 8 28.51s18.75 7.85 28.51 8c5.25.08 10.67.16 13.94 1.52c3.56 1.47 7.63 5.37 11.57 9.14c6.9 6.63 14.74 14.14 25.18 14.14s18.27-7.51 25.18-14.14c3.94-3.77 8-7.67 11.57-9.14c3.27-1.36 8.69-1.44 13.94-1.52c9.76-.15 20.82-.31 28.51-8s7.85-18.75 8-28.51c.08-5.25.16-10.67 1.52-13.94c1.47-3.56 5.37-7.63 9.14-11.57c6.63-6.9 14.14-14.74 14.14-25.18s-7.51-18.27-14.14-25.18m-52.2 6.84l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 11.32" />
      </svg>
  )
}














